import React, { useEffect, useState } from "react";
import { useFolderList } from "../application/Hooks/useFolderList";
import useAddRetentionSchedule from "../application/Hooks/useAddRetentionSchedule";
import CustomAlertV2 from "../components/CustomAlertV2";
import useGetRetentionScheduleList from "../application/Hooks/useGetRetentionScheduleList";
import useUpdateRetentionSchedule from "../application/Hooks/useUpdateRetentionSchedule";

const AddRetentionSchedule = ({ companyId, onSuccess }) => {
  const [selectedFolder, setSelectedFolder] = useState("\\");
  const [errorMessage, setErrorMessage] = useState(null);
  const { getFolderList, folderList, loading, error } =
    useFolderList(companyId);
  const {
    status,
    addRetentionSchedule,
    error: error1,
    setError,
  } = useAddRetentionSchedule();
  const {
    retentionScheduleList,
    loading: loading1,
    error: error2,
    refetch,
  } = useGetRetentionScheduleList(companyId);

  const [retentionSchedule, setRetentionSchedule] = useState({
    companyId: companyId,
    folder: "\\",
    retentionDate: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleFolderChange = (e) => {
    debugger;
    setSelectedFolder(e.target.value);
    setRetentionSchedule((prev) => ({ ...prev, folder: e.target.value }));
  };

  const handleSubmit = async (e) => {
    debugger;
    console.log("Add  Retention Schedule...");
    e.preventDefault();
    if (!retentionSchedule.retentionDate) {
      setSuccessMessage("Date is required.");
      return;
    }

    setIsLoading(true);

    try {
      await addRetentionSchedule(retentionSchedule);
    } catch (err) {
      console.log("Error adding retention schedule:", err);
    }
  };
  useEffect(() => {
    debugger;
    if (status === 200) {
      setSuccessMessage("Retention schedule added successfully!");
      setIsLoading(false); // Hide loader and enable button
      setRetentionSchedule({
        companyId: companyId,
        folder: "\\", // Reset to default folder value
        retentionDate: null, // Clear date
      }); // Reset form state
      refetch();
    }
  }, [status, companyId, refetch]);

  const handleConfirm = async () => {
    debugger;
    onSuccess();
  };

  const [schedules, setSchedules] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [tempRetentionDate, setTempRetentionDate] = useState("");
  const {
    status: status1,
    updateRetentionSchedule,
    error1: error3,
    setError: setError1,
  } = useUpdateRetentionSchedule();

  // Update schedules when retentionScheduleList changes
  useEffect(() => {
    debugger;
    if (
      retentionScheduleList &&
      Array.isArray(retentionScheduleList.retentionSchedules) &&
      retentionScheduleList.retentionSchedules.length > 0
    ) {
      setSchedules(retentionScheduleList.retentionSchedules);
    } else setSchedules([]);
  }, [retentionScheduleList]);

  const handleEdit = (id, retentionDate) => {
    setEditingRow(id);
    setTempRetentionDate(retentionDate);
  };

  const handleCancel = () => {
    setEditingRow(null);
    setTempRetentionDate("");
  };

  const handleSave = async () => {
    debugger;
    // Update the backend if needed here

    // Construct the request payload as a plain object
    const retentionScheduleRequest = {
      id: editingRow,
      retentionDate: tempRetentionDate,
    };
    // Call the update API
    await updateRetentionSchedule(retentionScheduleRequest);

    // Update the state locally
    setSchedules((prev) =>
      prev.map((schedule) =>
        schedule.id === editingRow
          ? { ...schedule, retentionDate: tempRetentionDate }
          : schedule
      )
    );
    setEditingRow(null);
    setTempRetentionDate("");
    if (onSuccess) onSuccess();
  };

  const handleDateChange = (e) => {
    setTempRetentionDate(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    if (error1 !== "" && error1 != null) {
      setErrorMessage(error1);
      setError(null);
      setIsLoading(false); // Hide loader and enable button
    }
  }, [error1, setError]);
  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <h2>Please select Folder and its corresponding retention date.</h2>
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <label>Select Folder</label>
              <select
                class="form-select"
                required=""
                value={selectedFolder}
                onChange={handleFolderChange}
              >
                <option value="\">\</option>
                {folderList &&
                  folderList.length > 0 &&
                  folderList.map((folder) => (
                    <option key={folder} value={folder}>
                      {folder}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group">
              <label>Date</label>
              <input
                type="date"
                id="retentionDate"
                name="retentionDate"
                class="form-control"
                onChange={(e) =>
                  setRetentionSchedule({
                    ...retentionSchedule,
                    retentionDate: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <button
              type="submit"
              class="btn saveBtn mt-3 mb-3"
              disabled={isLoading}
            >
              Save
            </button>
          </div>
          {successMessage && (
            <CustomAlertV2
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
              onConfirm={handleConfirm}
              isCancelButton={false}
            />
          )}
          {errorMessage && (
            <CustomAlertV2
              message={errorMessage}
              onClose={() => setErrorMessage(null)}
              onConfirm={null}
              isCancelButton={false}
            />
          )}
        </div>
      </form>
      <div className="row">
        <div className="col-md-12">
          <div className="tab_wrp">
            <h2>Retention Schedule Details</h2>
            {/* {loading && <p>Loading...</p>}
          {error && <p className="text-danger">Error: {error}</p>} */}
            <div className="tableScroll">
              <table className="table table-bordered blueTbl">
                <thead>
                  <tr>
                    <th>Folder Name</th>
                    <th>Retention Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {schedules && schedules.length > 0 ? (
                    schedules.map((schedule) => (
                      <tr key={schedule.id}>
                        <td>{schedule.folder}</td>
                        <td>
                          {editingRow === schedule.id ? (
                            <input
                              type="date"
                              value={tempRetentionDate}
                              onChange={handleDateChange}
                              className="form-control"
                            />
                          ) : schedule.retentionDate ? (
                            formatDate(schedule.retentionDate)
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {editingRow === schedule.id ? (
                            <>
                              <button
                                type="button"
                                className="btn saveBtn"
                                onClick={handleSave}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn cancelBtn"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              className="btn editBtn"
                              onClick={() =>
                                handleEdit(schedule.id, schedule.retentionDate)
                              }
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Edit
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddRetentionSchedule;
