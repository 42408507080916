import { useState } from 'react';
import { ApiDocumentRepository } from '../../infrastructure/repositories/ApiDocumentRepository';
import useApiClient from "../../infrastructure/apiClient";
import { UpdateDocument } from '../UseCase/UpdateDocument';

export const useUpdateDocument = () => {
    const [updateDocumentRes, setupdateDocumentRes] = useState(null);
    const [error, setError] = useState(null);
    const apiClient = useApiClient();

    const UpdateDoc = async (UpdateDocRequest) => {
        const documentRepository = new ApiDocumentRepository(apiClient);
        const updateFileUseCase = new UpdateDocument(documentRepository);
    
        try {
          console.log('I am in useUpdateDocument hook');
          const result = await updateFileUseCase.execute(UpdateDocRequest);
          setupdateDocumentRes(result);
          console.log('I am in useUpdateDocument hook response : ' + result);
        } catch (err) {
          setError(err.message);
        }
      };
    
      return { updateDocumentRes, UpdateDoc, error, setupdateDocumentRes,setError };

};
export default useUpdateDocument;