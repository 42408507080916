import axios from "axios";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//console.log("API URL:", API_BASE_URL);

const apiClient = axios.create({
  baseURL: API_BASE_URL + "api", // Replace with your API base URL
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios Interceptor to add the token in Authorization header dynamically
const useApiClient = () => {
  const { accessToken } = useOidcAccessToken();

  // Set up a request interceptor
  apiClient.interceptors.request.use(
    (config) => {
      if (accessToken) {
        console.log('access token', accessToken);
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      // Handle error
      return Promise.reject(error);
    }
  );

  return apiClient;
};

export default useApiClient;
