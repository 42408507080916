export class IndexFieldList {
    constructor(indexFieldList) {
      // Initialize an empty array to hold index field objects
      this.indexFields = [];
      console.log('Entities - IndexFieldList ', indexFieldList);
      
      // Iterate over the indexFieldList array and push each item into indexFields
      indexFieldList.forEach(indexField => {
        this.indexFields.push({
          id: indexField.id,                         
          companyId: indexField.companyId,            
          columnName: indexField.columnName,          
          columnDataType: indexField.columnDataType,  
          columnLength: indexField.columnLength,      
          isRequired: indexField.isRequired,         
          defaultValue: indexField.defaultValue      
        });
      });
      
      console.log('Entities - indexFields ', this.indexFields);
    }
  }