import { useEffect, useState } from 'react';
import { ApiPendingRequestRepository } from '../../infrastructure/repositories/ApiPendingRequestRepository';
import { GetPendingRequestList } from '../UseCase/GetPendingRequestList';
import useApiClient from '../../infrastructure/apiClient';

export const useGetPendingRequestList = (popupData) => {
  const[pendingRequestResponse, setPendingRequestResponse]=useState([]);
  const [error1, setError] = useState(null);
  const apiClient = useApiClient();

  const getPendingRequestList = async (popupData) => {
    const pendingRequestRepository = new ApiPendingRequestRepository(apiClient);
    const getPendingRequestUseCase = new GetPendingRequestList(pendingRequestRepository);

    try {
      const result = await getPendingRequestUseCase.execute(popupData);
      setPendingRequestResponse(result);
      console.log('GetPendingRequestList response :'+ JSON.stringify(result));
    } catch (err) {
       console.log('GetPendingRequestList response err :'+ err.message);
      setError(err.message);
    }
  };
  useEffect(() => { 
          getPendingRequestList(popupData);    
  },[]);
  
  return {pendingRequestResponse, getPendingRequestList, setPendingRequestResponse, error1,setError };
};
export default useGetPendingRequestList;