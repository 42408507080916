// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import { SaveUser } from '../UseCase/SaveUser';
import useApiClient from '../../infrastructure/apiClient';
import { AuditLogRepository } from '../../domain/repositories/AuditLogRepository';
import { GetAuditLogs } from '../UseCase/GetAuditLogs';
import { ApiAuditLogRepository } from '../../infrastructure/repositories/ApiAuditLogRepository';

export const useGetAuditLog = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const getAuditLogs = async (auditLogRequest,operation,userName) => {
    const auditLogRepository = new ApiAuditLogRepository(apiClient);
    const getAuditLogUseCase = new GetAuditLogs(auditLogRepository);

    try {
      console.log('I am in getAuditLogUseCase hook');      
      auditLogRequest.operation = operation;
      auditLogRequest.userName = userName;
      const result = await getAuditLogUseCase.execute(auditLogRequest);
      if(result.status === 200){
      setAuditLogs(result.data);
      }
      else
        setAuditLogs([]);
    } catch (err) {
      setError(err.message);
    }
  };

  return { getAuditLogs, auditLogs, error };
};
export default useGetAuditLog;
