
import { useState } from 'react';
import { ApiCompanyRepository } from '../../infrastructure/repositories/ApiCompanyRepository';
import useApiClient from '../../infrastructure/apiClient';
import { UpdateCompanyStatus } from '../UseCase/UpdateCompanyStatus';

export const useUpdateCompanyStatus = () => {
  const [companyId, setCompanyId] = useState(null);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const updateCompanyStatus = async (UpdateCompanyStatusRequest) => {
    console.log('tick tick');
    const companyRepository = new ApiCompanyRepository(apiClient);
    const useUpdateCompanyStatus = new UpdateCompanyStatus(companyRepository);

    try {
      const result = await useUpdateCompanyStatus.execute(UpdateCompanyStatusRequest);
      setCompanyId(result);
    } catch (err) {
      setError(err.message);
    }
  };

  return { companyId, updateCompanyStatus, error};
};
export default useUpdateCompanyStatus;
