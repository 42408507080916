export class CompanyData {
  constructor(CompanyData) {
    console.log("CompanyData - Constructor", CompanyData);
    this.compnayId = CompanyData.compnayId;
    this.name = CompanyData.name;
    this.phoneNumber = CompanyData.phoneNumber;
    this.storage = CompanyData.storage;
    this.logoPath = CompanyData.logoPath;
    this.status = CompanyData.status;
  }
}
