export class RetentionScheduleRepository {
    addRetentionSchedule(RetentionScheduleRequest) {
        throw new Error('addRetentionSchedule method not implemented');
      }  
      updateRetentionSchedule(UpdateRetentionScheduleRequest){
        throw new Error('UpdateRetentionScheduleRequest method not implemented');
      }  
      getRetentionScheduleList(companyId) {
        throw new Error('getRetentionScheduleList method not implemented');
      }
      async getFolderList(companyId) {
        throw new Error('getFolderList method not implemented');
      }
      async getDeletedFiles(companyId) {
        throw new Error('getDeletedFiles method not implemented');
      }
}