import React from "react";
import { useState, useEffect } from "react";
import { useAddUser } from "../application/Hooks/useAddUser";
import CustomAlertV2 from "../components/CustomAlertV2";

const AddUser = ({ companyId, onSuccess }) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    userEmail: "",
    phoneNumber: "",
    role: "",
    companyId: companyId,
    password: "Asdf@1234",
  });

  const [role, setRole] = useState("Select");
  const { userId, addUser, error, loading } = useAddUser();
  const [successMessage, setSuccessMessage] = useState(null);

  const handleRoleSelect = (e) => {
    setRole(e.target.value);
    setUser({ ...user, role: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    user.companyId = companyId;
    if (
      companyId !== 0 &&
      companyId !== null &&
      !isNaN(companyId) &&
      companyId !== undefined
    ) {
      await addUser(user);
    } else {
      alert("Please select a company");
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, phoneNumber: e.target.value });
  };

  // Custom validation message
  const handleInvalid = (e) => {
    e.target.setCustomValidity(
      "Please enter a valid phone number in the format: +[Country Code][10-digit Number]. For example: +919876543210."
    );
  };

  // Reset custom validation message when input changes
  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  const handleConfirm = async () => {
    debugger;
    onSuccess();
  };

  useEffect(() => {
    if (userId !== null && userId.status === 200) {
      if (userId.data.statusCode === 0) {
        setSuccessMessage("User created successfully");
      } else {
        if (userId.data.result === "DuplicateUserName") {
          setSuccessMessage("Email address already exists");
        }
      }
    } else {
      console.log("Log response", userId);
    }
  }, [userId, onSuccess]);
  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="row">
        <div className="col-md-12">
          <div className="pull-left Txt_bold mb-3">Add User</div>
          <div className="pull-right"></div>
        </div>
        <div className="col-md-12">
          <div className="grayBox">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      id=""
                      className="form-control"
                      required={true}
                      minLength={2}
                      maxLength={100}
                      onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      required={true}
                      minLength={2}
                      maxLength={100}
                      onChange={(e) =>
                        setUser({ ...user, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Email Address</label>
                    <input
                      type="email"
                      required={true}
                      className="form-control"
                      onChange={(e) =>
                        setUser({ ...user, userEmail: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label> Phone Number</label>
                    <input
                      type="text"
                      onInvalid={handleInvalid} // Set custom message on invalid input
                      onInput={handleInput} // Reset message on valid input
                      className="form-control"
                      required={true}
                      pattern="\+[0-9]{1,3}[0-9]{10}" // Pattern for country code (1-3 digits) followed by a 10-digit number
                      maxLength="15"
                      title="Please enter a valid phone number in the format: +[Country Code][10-digit Number]."
                      onChange={(e) => {
                        setUser({ ...user, phoneNumber: e.target.value });
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Role</label>
                    <select
                      className="form-select"
                      onChange={handleRoleSelect}
                      value={role}
                      required={true}
                    >
                      <option value="">Select</option>
                      <option value="Admin">Admin</option>
                      <option value="User">User</option>
                      <option value="User (View only)">User (View only)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" disabled={loading} className="btn saveBtn mt-3">
            Save User
          </button>
        </div>
        {successMessage && (
          <CustomAlertV2
            message={successMessage}
            onClose={() => setSuccessMessage(null)}
            onConfirm={handleConfirm}
            isCancelButton={false}
          />
        )}
      </div>
    </form>
  );
};

export default AddUser;
