export class UserRespository{
    addUser(userInfo) {
        throw new Error('addUser method not implemented');
      }
      getUserList(companyId) {
        throw new Error('getUserList method not implemented');
      }
      saveUser(userInfo){
        throw new Error('saveUser method not implemented');
      }
      deleteUsers(users){
        throw new Error('deleteUsers method not implemented');
      }
      getUser(users){
        throw new Error('getUser method not implemented');
      }
      updateUser(user){
        throw new Error('updateUser method not implemented');
      }
      GetUserDetails(UserEmail){  }
      changePassword(changepasswordrequest){
        throw new Error('changePassword method not implemented');
      }
}