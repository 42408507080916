import React from "react";
import { useState, useEffect } from "react";
import useGetUser from "../application/Hooks/useGetUser";
import { useUpdateUser } from "../application/Hooks/useUpdateUser";

const EditUser = ({ companyUserId, onSuccess }) => {
  const { user, setUser, error } = useGetUser(companyUserId);
  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    userEmail: user.userEmail,
    phoneNumber: user.phoneNumber,
    role: user.role,
    id: user.id,
    companyId: user.companyId,
  });

  const [role, setRole] = useState("Select");
  const { updateduser, updateUser, loading } = useUpdateUser();

  const handleRoleSelect = (e) => {
    setRole(e.target.value);
    setUser({ ...user, role: e.target.value });
  };

  const handleSubmit = async (e) => {
    debugger;
    console.log("Updated User", user);
    e.preventDefault();
    //user.companyId = companyId;
    if (
      companyUserId !== 0 &&
      companyUserId !== null &&
      !isNaN(companyUserId) &&
      companyUserId !== undefined
    ) {
      await updateUser(user);
    } else {
      alert("User not selected");
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, phoneNumber: e.target.value });
  };

  // Custom validation message
  const handleInvalid = (e) => {
    e.target.setCustomValidity(
      "Please enter a valid phone number in the format: +[Country Code][10-digit Number]. For example: +919876543210."
    );
  };

  // Reset custom validation message when input changes
  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  //Back Button
  const handleBackButton = (e) => {
    onSuccess();
  };

  useEffect(() => {
    if (updateduser !== null && updateduser.status === 200) {
      alert("User updated successfully");
      onSuccess(); // Notify the parent that the fetch was successful
    }
  }, [updateduser, onSuccess]);
  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="row">
        <div className="col-md-12">
          <div className="pull-left Txt_bold mb-3">Update User</div>
          <div className="pull-right">
            <button
              type="submit"
              disabled={loading}
              className="btn saveBtn mt-3"
            >
              Update User
            </button>
            <button
              disabled={loading}
              className="btn backTopBtn"
              onClick={handleBackButton}
            >
              Back
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <div className="grayBox">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      id=""
                      className="form-control"
                      required={true}
                      minLength={2}
                      maxLength={100}
                      value={user.firstName}
                      onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      required={true}
                      minLength={2}
                      maxLength={100}
                      value={user.lastName}
                      onChange={(e) =>
                        setUser({ ...user, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Email Address</label>
                    <input
                      type="email"
                      required={true}
                      className="form-control"
                      value={user.userEmail}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label> Phone Number</label>
                    <input
                      type="text"
                      onInvalid={handleInvalid} // Set custom message on invalid input
                      onInput={handleInput} // Reset message on valid input
                      className="form-control"
                      required={true}
                      pattern="\+[0-9]{1,3}[0-9]{10}" // Pattern for country code (1-3 digits) followed by a 10-digit number
                      maxLength="15"
                      title="Please enter a valid phone number in the format: +[Country Code][10-digit Number]."
                      value={user.phoneNumber}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          phoneNumber: e.target.value,
                        });
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Role</label>
                    <select
                      className="form-select"
                      onChange={handleRoleSelect}
                      value={user.role}
                      required={true}
                    >
                      <option value="">Select</option>
                      <option value="Admin">Admin</option>
                      <option value="User">User</option>
                      <option value="UserView">User (View only)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditUser;
