// src/application/hooks/useAddRetentionSchedule.js
import { useState } from 'react';
import { ApiRetentionScheduleRepository } from '../../infrastructure/repositories/ApiRetentionScheduleRepository';
import { AddRetentionSchedule } from '../UseCase/AddRetentionSchedule';
import useApiClient from '../../infrastructure/apiClient';

export const useAddRetentionSchedule = () => {
  const[status,setStatus]=useState(false);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const addRetentionSchedule = async (RetentionScheduleRequest) => {

    const retentionScheduleRepository = new ApiRetentionScheduleRepository(apiClient);
    const retentionScheduleUseCase = new AddRetentionSchedule(retentionScheduleRepository);

    try {
      debugger;
      console.log('I am in useAddRetentionSchedule hook '+JSON.stringify(RetentionScheduleRequest));
    
      const result = await retentionScheduleUseCase.execute(RetentionScheduleRequest);
      setStatus(result);
      console.log('addRetentionSchedule response :'+result);
    } 
    catch (err) 
    {
      setError(err.message);
    }
  };

  return {status, addRetentionSchedule,error,setError };
};
export default useAddRetentionSchedule;
