import React, { useEffect, useState } from "react";
import { Table, Form, Pagination, Button } from "react-bootstrap";
import { useGetPendingRequestList } from "../application/Hooks/useGetPendingRequestList";
import { Checkbox } from "@mui/material";
import useApproveRejectRequests from "../application/Hooks/approveRejectRequests";
import CustomAlertV2 from "../components/CustomAlertV2";
import PopupExample from "../components/PopupExample";

const PendingRequest = () => {
  const [popupData, setPopupData] = useState({
    status: "",
    searchBy: "fileName",
    searchValue: "",
  });
  const {
    pendingRequestResponse,
    getPendingRequestList,
    setPendingRequestResponse,
    error1,
    setError,
  } = useGetPendingRequestList(popupData);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [fieldsPerPage, setFieldsPerPage] = useState(10); // Default number of fields per page
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [request, setRequest] = useState([]);
  const { approveRejectRequest, approveRejectRequests, loading } =
    useApproveRejectRequests();
  const [successMessage, setSuccessMessage] = useState(null);

  const isAllSelected =
    pendingRequestResponse.length > 0 &&
    selectedRequests.length === pendingRequestResponse.length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allIds = paginatedData.map((row) => row.pendingRequestId); // Select all row ids
      setSelectedRequests(allIds);
    } else {
      setSelectedRequests([]); // Deselect all
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRequests((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id); // Deselect
      } else {
        return [...prevSelected, id]; // Select
      }
    });
  };

  const handleApproveRejectButton = async (pendingRequestId, action) => {
    let dummyRequest = { selectedRequests, action };
    if (action === "Approve" || action === "Reject") {
      const actionOnDocument = [pendingRequestId];

      dummyRequest = { selectedRequests: actionOnDocument, action };
    } else {
      if (action === "ApproveAll") action = "Approve";
      else action = "Reject";

      dummyRequest = { selectedRequests, action };
    }

    setRequest(dummyRequest);

    setSuccessMessage(
      "Do you really want to " + action + " the pending requests."
    );
  };

  const handleConfirm = async () => {
    await approveRejectRequests(request);
    console.log("ApproveRequest after calling: ", approveRejectRequest);
  };
  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  const paginatedData =
    pendingRequestResponse?.slice(indexOfFirstField, indexOfLastField) || [];

  // Calculate the "Showing x to y of z entries" message
  const start = Math.min(indexOfFirstField + 1, pendingRequestResponse.length);
  const end = Math.min(indexOfLastField, pendingRequestResponse.length);

  useEffect(() => {
    debugger;
    if (approveRejectRequest) {
      getPendingRequestList(popupData);
      setSelectedRequests([]);
    }

    // if (popupData) {
    //   getPendingRequestList(popupData);
    //   setSelectedRequests([]);
    // }
  }, [approveRejectRequest]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="page_title">Pending Request</div>
            <div className="tab_wrp">
              <div className="row">
                <div className="col-md-12">
                  <div className="deletePageAddWrp">
                    <div className="div1">
                      <Form.Group controlId="fieldsPerPageSelect">
                        <Form.Label>Show</Form.Label>
                        <Form.Control
                          as="select"
                          value={fieldsPerPage}
                          onChange={(e) =>
                            setFieldsPerPage(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div className="div2">
                      <button
                        type="button"
                        className="btn approveBtn"
                        onClick={() =>
                          handleApproveRejectButton(
                            selectedRequests,
                            "ApproveAll"
                          )
                        }
                        disabled={
                          loading ||
                          selectedRequests.length === 0 ||
                          popupData.status === "Approved" ||
                          popupData.status === "Rejected"
                        }
                      >
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                        Approve
                      </button>
                      <button
                        type="button"
                        className="btn rejectBtn"
                        onClick={() =>
                          handleApproveRejectButton(
                            selectedRequests,
                            "RejectAll"
                          )
                        }
                        disabled={
                          loading ||
                          selectedRequests.length === 0 ||
                          popupData.status === "Approved" ||
                          popupData.status === "Rejected"
                        }
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Reject
                      </button>


                    </div>
                    <div className="pendingRuqustSerch">
                      <PopupExample
                        popupData={popupData}
                        setPopupData={setPopupData}
                        getPendingRequestList={getPendingRequestList}
                      ></PopupExample></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="pendingReqstTbl">
                    <Table className="table table-bordered blueTbl pendingRqstTbl">
                      <thead>
                        <tr>
                          <th padding="checkbox">
                            <Checkbox
                              indeterminate={
                                selectedRequests.length > 0 &&
                                selectedRequests.length <
                                pendingRequestResponse.length
                              }
                              checked={isAllSelected}
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all rows",
                              }}
                            />
                          </th>
                          <th>File Name</th>
                          <th>Date</th>
                          <th>Action By</th>
                          <th>File Send To</th>
                          <th>Approval For</th>
                          <th>Total Page</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.length > 0 ? (
                          paginatedData.map((field, index) => (
                            <tr key={index}>
                              <td padding="checkbox">
                                <Checkbox
                                  checked={selectedRequests.includes(
                                    field.pendingRequestId
                                  )}
                                  onChange={() =>
                                    handleSelectRow(field.pendingRequestId)
                                  }
                                  inputProps={{
                                    "aria-label": `select row ${field.pendingRequestId}`,
                                  }}
                                />
                              </td>
                              <td>{field.fileName}</td>
                              <td>{field.actionDate}</td>
                              <td>{field.actionBy}</td>
                              <td>{field.fileSendTo}</td>
                              <td>{field.action}</td>
                              <td>{field.pageCount}</td>
                              {/* 
                              
                              <td>
                                <button
                                  className="btn rejectBtn"
                                  onClick={() => {
                                    handleApproveRejectButton(
                                      field.pendingRequestId,
                                      "Reject"
                                    );
                                  }}
                                  disabled={loading}
                                >
                                  Reject
                                </button>
                                <button
                                  className="btn approveBtn"
                                  onClick={() => {
                                    handleApproveRejectButton(
                                      field.pendingRequestId,
                                      "Approve"
                                    );
                                  }}
                                  disabled={loading}
                                >
                                  Approve
                                </button>
                              </td>
                              */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No fields found.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="float-start">
                    Showing <strong>{start}</strong> to <strong>{end}</strong>{" "}
                    of <strong>{pendingRequestResponse.length}</strong> entries
                  </div>
                  <div className="float-end">
                    {/* Pagination Component */}
                    <Pagination>
                      {/* Previous Button */}
                      <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                      />

                      {/* Show only the current page number */}
                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      {/* Next Button */}
                      <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={
                          !pendingRequestResponse ||
                          pendingRequestResponse.length === 0 ||
                          currentPage ===
                          Math.ceil(
                            pendingRequestResponse.length / fieldsPerPage
                          )
                        }
                      />
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {successMessage && (
            <CustomAlertV2
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
              onConfirm={handleConfirm}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default PendingRequest;
