// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiImageProfileRepository } from '../../infrastructure/repositories/ApiImageProfileRepository';
import { CreateImageProfile } from '../UseCase/CreateImageProfile';
import useApiClient from '../../infrastructure/apiClient';

export const useCreateImageProfile = () => {
    const[status,setstatus]=useState(false);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const CreateImageProfiles = async (ImageProfile) => {

    const imageProfileRepository = new ApiImageProfileRepository(apiClient);
    const CreateImageProfilesUseCase = new CreateImageProfile(imageProfileRepository);

    try {
      console.log('I am in CreateImageProfiles hook '+JSON.stringify(ImageProfile));
      const result = await CreateImageProfilesUseCase.execute(ImageProfile);
      setstatus(result);
      console.log('create ImageProfiles response :'+result);
    } catch (err) {
      setError(err.message);
    }
  };

  return {status, CreateImageProfiles,error,setError };
};
export default useCreateImageProfile;
