import React, { useEffect } from "react";
import { useOidcUser } from "@axa-fr/react-oidc";
import workinprogress from "../assets/img/workinprogress.png";
import { useState } from "react";
import useCompanyList from "../application/Hooks/useCompanyList";
import CompanyListDropDown from "../components/CompanyListDropDown";
import CompanyInformation from "../components/CompanyInformation";
import UserList from "../components/UserList";
import AddUser from "../components/AddUser";
import EditUser from "../components/EditUser";
import useGetCompanyStatus from "../application/Hooks/useGetCompanyStatus";
import { useGetDesignIndexFieldList } from "../application/Hooks/useGetDesignIndexFieldList";
import AddRetentionSchedule from "../components/AddRetentionSchedule";
import RetentionScheduleList from "../components/RetentionScheduleList";
import ManageDeletedFiles from "../components/ManageDeletedFiles";

const UserPage = () => {
  // State to track active tab
  const [activeTab, setActiveTab] = useState("tabCompanyInformation");
  const { oidcUser } = useOidcUser();

  const [selectedValue, setSelectedValue] = useState("0");
  const [CompanyStatus, setCompanyStatus] = useState("-");
  const [showComponentB, setShowComponentB] = useState(true);
  const [isDisabled] = useState(true);
  const {
    designIndexFieldList,
    loading: loading1,
    error,
    refetch,
  } = useGetDesignIndexFieldList(selectedValue); // assuming refetch is available from your hook

  //For EditUser
  const [companyUserId, setCompanyUserId] = useState(0);

  const { APICompanyStatus, loading, error1 } =
    useGetCompanyStatus(selectedValue);

  const handleEditUser = (value) => {
    debugger;
    setCompanyUserId(value);
    if (companyUserId !== 0) {
      setActiveTab("tabEditUser");
    }
  };

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };

  const handleCompanyStatus = (value) => {
    setCompanyStatus(value);
  };

  // Function to handle tab switching
  const openTab = (tabId) => {
    setActiveTab(tabId);
  };
  const handleSuccess = () => {
    debugger;
    console.log("ADd user successfully ");
    setShowComponentB(true);
    if (showComponentB === true) {
      setActiveTab("tabUserList");
    }
  };
  const handleRetentionSuccess = () => {
    debugger;
    setShowComponentB(true);
    if (showComponentB === true) {
      setActiveTab("tabRetentionSchedule");
    }
  };

  const handleNext = () => {
    if (activeTab === "tabCompanyInformation") setActiveTab("tabUserList");
    else if (activeTab === "tabUserList") setActiveTab("tabRetentionSchedule");
    else if (activeTab === "tabRetentionSchedule")
      setActiveTab("manageDeletedFiles");
    else {
      setActiveTab("tabCompanyInformation");
    }
  };
  const handleBack = () => {
    if (activeTab === "tabCompanyInformation") {
      setActiveTab("manageDeletedFiles");
    } else if (activeTab === "tabUserList") {
      setActiveTab("tabCompanyInformation");
    } else if (activeTab === "tabRetentionSchedule")
      setActiveTab("tabUserList");
    else {
      setActiveTab("tabRetentionSchedule");
    }
  };
  useEffect(() => {
    const fetchData = () => {
      if (
        designIndexFieldList &&
        designIndexFieldList.indexFields &&
        designIndexFieldList.indexFields.length > 0
      ) {
        setSelectedValue(designIndexFieldList.companyid);
      }
    };
    // Call fetchData inside useEffect
    fetchData();
  }, [designIndexFieldList]);

  console.log("activeTab", activeTab, selectedValue);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="page_title">Manage User</div>
            <div className="tab_wrp">
              <div className="row">
                <div className="col-md-4">
                  <div className="Select_Company">
                    {/* <div className="input-group">
                      <CompanyListDropDown
                        value={selectedValue}
                        onChange={handleDropdownChange}
                      />
                    </div> */}

                    {oidcUser && oidcUser.role === "SuperAdmin" ? (
                      <div className="input-group">
                        <CompanyListDropDown onChange={handleDropdownChange} />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <hr></hr>
              </div>

              {/* Tabs */}
              <div className="Tab_button_wrp">
                <button
                  className={
                    activeTab === "tabCompanyInformation" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("tabCompanyInformation")}
                >
                  Company Information
                </button>
                <button
                  className={
                    activeTab === "tabUserList" ||
                    activeTab === "tabAddUser" ||
                    activeTab === "tabEditUser"
                      ? "tabActiveBtn"
                      : ""
                  }
                  onClick={() => {
                    openTab("tabUserList");
                  }}
                >
                  User List
                </button>
                <button
                  className={
                    activeTab === "tabRetentionSchedule" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("tabRetentionSchedule")}
                >
                  Retention Schedule
                </button>
                <button
                  className={
                    activeTab === "manageDeletedFiles" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("manageDeletedFiles")}
                >
                  Manage Deleted Files
                </button>
              </div>

              {/* Tab Content */}
              <div className="tab_box">
                {activeTab === "tabCompanyInformation" && (
                  <div id="tabCompanyInformation">
                    <CompanyInformation
                      value={parseInt(selectedValue)}
                      CompStatusFunction={handleCompanyStatus}
                      IsDisable={isDisabled}
                    />
                  </div>
                )}

                {activeTab === "tabUserList" && (
                  <div id="tabIndexFields">
                    {showComponentB && (
                      <UserList
                        companyId={parseInt(selectedValue)}
                        openTab={openTab}
                        onSuccess={handleSuccess}
                        handleEditUser={handleEditUser}
                        companyStatus={APICompanyStatus}
                      />
                    )}
                  </div>
                )}

                {activeTab === "tabAddUser" && (
                  <div id="tabAddUser">
                    <AddUser
                      companyId={parseInt(selectedValue)}
                      onSuccess={handleSuccess}
                      companyStatus={APICompanyStatus}
                    />
                  </div>
                )}

                {activeTab === "tabEditUser" && (
                  <div id="tabEditUser">
                    <EditUser
                      companyUserId={companyUserId}
                      onSuccess={handleSuccess}
                      companyStatus={APICompanyStatus}
                    />
                  </div>
                )}

                {activeTab === "tabRetentionSchedule" && (
                  <div id="tabImageProfiles">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="RetentionBox">
                          <AddRetentionSchedule
                            companyId={parseInt(selectedValue)}
                            onSuccess={handleRetentionSuccess}
                            companyStatus={APICompanyStatus}
                          />
                          {/* 
                          <RetentionScheduleList
                            companyId={parseInt(selectedValue)}
                            onSuccess={handleRetentionSuccess}
                            companyStatus={APICompanyStatus}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "manageDeletedFiles" && (
                  <ManageDeletedFiles
                    companyId={parseInt(selectedValue)}
                  ></ManageDeletedFiles>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="footerBtnGroup">
            <button type="button" className="btn backBtn" onClick={handleBack}>
              Back
            </button>
            <button type="button" className="btn nextBtn" onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserPage;
