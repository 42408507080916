// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiCompanyRepository } from '../../infrastructure/repositories/ApiCompanyRepository';
import { UpdateCompany } from '../UseCase/UpdateCompany';
import useApiClient from '../../infrastructure/apiClient';

export const useUpdateCompany = () => {
  const [companyId, setCompanyId] = useState(null);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();
  const updateCompany = async (newUpdateCompany) => {
    const companyRepository = new ApiCompanyRepository(apiClient);
    const updateCompanyUseCase = new UpdateCompany(companyRepository);

    try {
      console.log('I am in usecompany hook');
      const result = await updateCompanyUseCase.execute(newUpdateCompany);
      setCompanyId(result);
    } catch (err) {
      setError(err.message);
    }
  };

  return { companyId, updateCompany, error };
};
export default useUpdateCompany;
