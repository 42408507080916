export class GetFolderList {
    constructor(retentionScheduleRepository) 
    {
      this.retentionScheduleRepository = retentionScheduleRepository;
    }  
    async execute(companyId) {
      debugger;
     console.log('I am in GetFolderList useCase');
      return await this.retentionScheduleRepository.getFolderList(companyId);
    }
  }
