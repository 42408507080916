import { SearchRepository } from "../../domain/repositories/SearchRepository";
export class ApiSearchRepository extends SearchRepository {
    constructor(apiClient)
    {
       super();
       this.apiClient=apiClient;
    }
    async SearchDocument(SearchRequest) {
        try 
        {
            console.log('Calling ApiSearchRepository - SearchDocument - SearchRequest : ' + JSON.stringify(SearchRequest));    
            const response = await this.apiClient.post("/Search/SearchDocument", SearchRequest);
            return response.data;
        }
        catch (error) {    
        console.log('ApiSearchRepository error -', error);
        let errorMessage = "An unknown error occurred"; // Default error message
        if (error.response && error.response.data) {
          // Modify this based on actual structure
          errorMessage = error.response.data.message || error.response.data || errorMessage;
        }    
        // Throw the error message to be caught in the hook or service
        throw new Error(errorMessage);
        }
      }
    }
