import { OidcSecure } from "@axa-fr/react-oidc";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import RoleBasedSecure from "../components/RoleBasedSecure";
import useAddCompany from "../application/Hooks/useAddCompany";
import { CompanyInfo } from "../domain/entities/CompanyInfo";
import CustomAlertV2 from "../components/CustomAlertV2";
const AddCompany = () => {
  const [logo, setLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [unit, setUnit] = useState("GB"); // Default value is GB
  const { companyId, addCompany, error, setError } = useAddCompany();
  const [newCompany, setNewCompany] = useState({
    name: "",
    phonenumber: "",
    storage: 0,
    logoFileData: null,
  });
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    debugger;

    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Only JPEG, JPG, and PNG files are allowed.");
        setLogo(null);
        setNewCompany({ ...newCompany, logoFileData: null }); // Clear logo file data
        setIsEditLogo(false);
      } else {
        setLogo(URL.createObjectURL(file)); // Preview the logo
        setNewCompany({ ...newCompany, logoFileData: file }); // Save file in state
        setIsEditLogo(true);
      }
    }
  };
  const handleSelectChange = (event) => {
    setUnit(event.target.value); // Update state when user selects a new option
  };
  const handleSubmit = async (e) => {
    debugger;
    console.log("I am here");
    e.preventDefault();

    setIsLoading(true); // Show loader and disable button
    console.log("Logo file data before submission: ", newCompany.logoFileData); // Debug log
    //console.log(newCompany.logoFileData.name.split(".").pop());
    // if (
    //   ["JPG", "PNG", "JPEG"].includes(
    //     newCompany.logoFileData.name.split(".").pop().toUpperCase()
    //   )
    // ) {
    const company = new CompanyInfo(
      newCompany.name,
      newCompany.phonenumber,
      newCompany.storage,
      newCompany.logoFileData // This is where the file gets passed
    );
    if (unit === "TB") company.storage = company.storage * 1000;
    //await addCompany(company);
    try {
      await addCompany(company);
    } catch (err) {
      setIsLoading(false); // Hide loader and enable button
      console.error(`Error: ${err.message}`);
      // alert(`Error: ${err.message}`);
    }
    //}

    // else {
    //   alert("Please upload a .jpg, .jpeg or .png file");
    //   setIsLoading(false);
    // }
  };

  const handleChange = (e) => {
    setNewCompany({
      ...newCompany,
      phonenumber: e.target.value,
    });
  };

  // Custom validation message
  const handleInvalid = (e) => {
    e.target.setCustomValidity(
      "Please enter a valid phone number in the format: +[Country Code][10-digit Number]. For example: +919876543210."
    );
  };

  // Reset custom validation message when input changes
  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  const handleConfirm = async () => {
    console.log("Navigating to next page with companyId", companyId);
    navigate(`/manageCompany`, { state: { companyId } });
  };
  useEffect(() => {
    if (companyId) {
      debugger;
      setIsLoading(false); // Hide loader and enable button
      //alert("Company added successfully");
      setSuccessMessage("Company added successfully");
    }
    if (error !== "" && error != null) {
      setIsLoading(false); // Hide loader and enable button
      alert(error);
      console.log("ttttttttttt" + error);
      setError(null);
    }
  }, [companyId, navigate, error, setError]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="page_wrp">
              <div className="page_title">Add Company</div>
              <div className="contant_box_wrp">
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="company_logo_wrp">
                          {logo ? (
                            <img src={logo} alt="Logo" />
                          ) : (
                            <i
                              className="fa fa-file-image-o"
                              aria-hidden="true"
                            ></i>
                          )}

                          <input
                            type="file"
                            id="uploadLogo"
                            className="d-none"
                            onChange={handleLogoUpload}
                            accept=".jpg, .png,.jpeg"
                          />
                        </div>
                        <div className="uploadLogoText">
                          {isEditLogo ? (
                            <label
                              htmlFor="uploadLogo"
                              className="uploadLogoText"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                              Edit
                            </label>
                          ) : (
                            <label htmlFor="uploadLogo">
                              Upload Company Logo
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label>Company Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter company name"
                                value={newCompany.name}
                                required={true}
                                minLength={2}
                                maxLength={100}
                                onChange={(e) =>
                                  setNewCompany({
                                    ...newCompany,
                                    name: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label>Company Phone Number</label>
                              {/* <input
                                type="tel"
                                inputMode="numeric"
                                className="form-control no-arrows"
                                placeholder="Enter phone number"
                                value={newCompany.phonenumber}
                                required={true}
                                minLength={10}
                                maxLength={15}
                                onChange={(e) =>
                                  setNewCompany({
                                    ...newCompany,
                                    phonenumber: e.target.value,
                                  })
                                }
                              /> */}
                              <input
                                type="tel" // Use 'tel' input type for phone numbers
                                value={newCompany.phonenumber}
                                className="form-control"
                                onChange={(e) => {
                                  setNewCompany({
                                    ...newCompany,
                                    phonenumber: e.target.value,
                                  });
                                  handleChange(e);
                                }}
                                onInvalid={handleInvalid} // Set custom validation message
                                onInput={handleInput} // Clear custom message on input
                                placeholder="Enter phone number with country code"
                                pattern="\+[0-9]{1,3}[0-9]{10}" // Pattern for country code (1-3 digits) followed by a 10-digit number
                                maxLength="15" // Maximum length including the country code and '+'
                                required // Make input field mandatory
                                title="Please enter a valid phone number in the format: +[Country Code][10-digit Number]."
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Storage Capacity</label>
                              <div className="d-flex">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Storage Capacity"
                                  value={newCompany.storage}
                                  required
                                  min={1}
                                  onChange={(e) =>
                                    setNewCompany({
                                      ...newCompany,
                                      storage: e.target.value,
                                    })
                                  }
                                />

                                <select
                                  className="form-select w-auto ms-2"
                                  value={unit} // Bind state to select value
                                  onChange={handleSelectChange} // Handle change event
                                >
                                  <option>GB</option>
                                  <option>TB</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn nextBtn"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Next"}
              </button>
              {/* {isLoading && <div className="loader">Processing...</div>} */}
            </div>
          </form>
        </div>
      </div>
      {successMessage && (
        <CustomAlertV2
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
          onConfirm={handleConfirm}
          isCancelButton={false}
        />
      )}
    </div>
  );
};
export default AddCompany;
