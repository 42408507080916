// src/application/hooks/useAddRetentionSchedule.js
import { useState } from 'react';
import { ApiRetentionScheduleRepository } from '../../infrastructure/repositories/ApiRetentionScheduleRepository';
import { UpdateRetentionSchedule } from '../UseCase/UpdateRetentionSchedule';
import useApiClient from '../../infrastructure/apiClient';

export const useUpdateRetentionSchedule = () => {
  const[status,setStatus]=useState(false);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const updateRetentionSchedule = async (UpdateRetentionScheduleRequest) => {

    const retentionScheduleRepository = new ApiRetentionScheduleRepository(apiClient);
    const retentionScheduleUseCase = new UpdateRetentionSchedule(retentionScheduleRepository);

    try {
      debugger;
      console.log('I am in useUpdateRetentionSchedule hook '+JSON.stringify(UpdateRetentionScheduleRequest));
    
      const result = await retentionScheduleUseCase.execute(UpdateRetentionScheduleRequest);
      setStatus(result);
      console.log('updateRetentionSchedule response :'+result);
    } 
    catch (err) 
    {
      setError(err.message);
    }
  };

  return {status, updateRetentionSchedule,error,setError };
};
export default useUpdateRetentionSchedule;
