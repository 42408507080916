export class RetentionScheduleList {
    constructor(retentionScheduleList) {
        // Initialize an empty array to hold index retentionSchedules objects
        this.retentionSchedules = [];
        console.log('Entities - RetentionScheduleList ', retentionScheduleList);
        debugger;
        // Iterate over the retentionScheduleList array and push each item into retentionSchedules
        retentionScheduleList.forEach(retentionSchedule => {
          this.retentionSchedules.push({
            id: retentionSchedule.id,                         
            folder: retentionSchedule.folder,          
            retentionDate: retentionSchedule.retentionDate
          });
        });        
        console.log('Entities - retentionSchedules ', this.retentionSchedules);
    }
}