import React from "react";
import GLlogo from "../assets/img/GAlogo.png";
import RoleBasedSecure from "./RoleBasedSecure";

import { Link } from "react-router-dom";
import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import { GetUserDetails } from "../application/UseCase/GetUserDetails";
import useGetUserDetails from "../application/Hooks/useGetUserDetails";

const Header = () => {
  const { oidcUser } = useOidcUser();
  const { login, logout, isAuthenticated } = useOidc();

  const {
    userDetail,
    error,
    refetch: getUserDetail,
  } = useGetUserDetails(
    oidcUser !== null && oidcUser !== undefined ? oidcUser.email : ""
  );

  if (oidcUser !== null && oidcUser !== undefined) {
  }
  return (
    <header>
      <nav className="navbar navbar-expand-sm">
        <div className="container">
          <Link className="navbar-brand" to="#">
            <img src={GLlogo} width="" height="" alt="Lockbox" loading="lazy" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <RoleBasedSecure roles={["SuperAdmin", "Admin"]}>
                <li className="nav-item dropdown adminMenu">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Admin
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/addUser">
                        <i className="fa fa-user-o" aria-hidden="true"></i>
                        Manage User
                      </Link>
                    </li>

                    {oidcUser && oidcUser.role === "Admin" ? (
                      <li>
                        <Link className="dropdown-item" to="/pendingRequest">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          Pending Request
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </RoleBasedSecure>
              <RoleBasedSecure roles={["SuperAdmin", "Admin", "User"]}>
                <li className="nav-item">
                  <Link className="nav-link" to="/upload">
                    Upload File
                  </Link>
                </li>
              </RoleBasedSecure>
              <RoleBasedSecure roles={["SuperAdmin", "Admin"]}>
                <li className="nav-item">
                  <Link className="nav-link" to="/auditLogs">
                    Audit Logs
                  </Link>
                </li>
              </RoleBasedSecure>
              <RoleBasedSecure roles={["SuperAdmin"]}>
                <li className="nav-item dropdown companyMenu">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Company
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/addcompany">
                        <i className="fa fa-plus-square" aria-hidden="true"></i>{" "}
                        Add Company
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ManageCompany">
                        <i className="fa fa-cog" aria-hidden="true"></i>Manage
                        Company
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="undercontruction">
                        <i className="fa fa-sliders" aria-hidden="true"></i>{" "}
                        Configuration
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="undercontruction">
                        <i className="fa fa-file-text-o" aria-hidden="true"></i>
                        Reports
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </RoleBasedSecure>

              <li className="nav-item dropdown loginName">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <span>
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span>{oidcUser && oidcUser.given_name}</span>
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    {!isAuthenticated && (
                      <Link
                        className="logoutTxt dropdown-item"
                        onClick={() => login("/dashboard")}
                      >
                        Login
                      </Link>
                    )}
                    {isAuthenticated &&
                      oidcUser !== null &&
                      oidcUser.role !== "SuperAdmin" &&
                      userDetail.companyLogo && (
                        <Link className="navbar-brand companyMailLogo" to="#">
                          <img
                            src={userDetail.companyLogo}
                            width=""
                            height=""
                            alt=""
                            loading="lazy"
                          />
                        </Link>
                      )}
                    {isAuthenticated && (
                      <Link className="nav-link" to="/changePassword">
                        <i class="fa fa-unlock-alt" aria-hidden="true"></i>{" "}
                        Change Password
                      </Link>
                    )}
                    {isAuthenticated && (
                      <Link
                        className="logoutTxt dropdown-item"
                        onClick={() => logout()}
                      >
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        Logout
                      </Link>
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
