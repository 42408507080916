import axios from "axios";

const IDENTITY_API_BASE_URL = process.env.REACT_APP_IDENTITY_BASE_URL;
//console.log("API URL:", IDENTITY_API_BASE_URL);

const apiUserClient = axios.create({
  baseURL: IDENTITY_API_BASE_URL + "/api",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiUserClient;
