// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import { AddUser } from '../UseCase/AddUser';
import userApiClient from '../../infrastructure/userApiClient';
import useApiClient from '../../infrastructure/apiClient';
import { DeleteUsers } from '../UseCase/DeleteUsers';


export const useDeleteUsers = () => {
  const [deletedUsers, setDeleteUsers] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();

  const deleteUsers = async (newUser) => {
    debugger;
    const userRepository = new ApiUserRepository(userApiClient,apiClient);
    const deleteUserUseCase = new DeleteUsers(userRepository);

    try {
      debugger;
      setLoading(true);      
      console.log(loading);
      console.log('I am in usecompany hook');
      const result = await deleteUserUseCase.execute(newUser);
      await setDeleteUsers(result);
    } catch (err) {
      setError(err.message);
    }
    finally{
      debugger;
      setLoading(false);
      console.log(loading);
    }
  };

  return { deletedUsers, deleteUsers, loading };
};
export default useDeleteUsers;
