import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import useCreateIndexfield from "../application/Hooks/useCreateIndexfield";
import { Fields } from "../domain/entities/Fields";
import CustomAlertV2 from "../components/CustomAlertV2";

const AddIndexField = ({ value, onSaveField }) => {
  console.log("addindexfield " + value);
  const { status, CreateIndexfields, error, setError } = useCreateIndexfield();
  const [successMessage, setSuccessMessage] = useState(null);
  const [fields, setFields] = useState([
    {
      fieldName: "",
      dataType: "String",
      stringLength: "",
      defaultValue: "",
      isRequired: false,
    },
  ]);

  // Handle change for individual field input
  const handleInputChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newFields = [...fields];
    newFields[index][name] = type === "checkbox" ? checked : value;
    setFields(newFields);
  };

  // Add new field row
  const handleAddRow = () => {
    setFields([
      ...fields,
      {
        fieldName: "",
        dataType: "String",
        stringLength: "",
        defaultValue: "",
        isRequired: false,
      },
    ]);
  };

  // Remove a field row
  const handleRemoveRow = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("fields " + JSON.stringify(fields));

    const boundFields = fields.map((field) => {
      return new Fields(
        value, // CompanyId
        field.fieldName, // ColumnName
        field.dataType, // ColumnDataType
        field.stringLength, // ColumnLength
        field.isRequired, // IsRequired
        field.defaultValue // DefaultValue
      );
    });

    // Now you can use boundFields, for example, send it to an API or log it
    console.log("Bound Fields:", boundFields);

    CreateIndexfields(boundFields);
  };

  useEffect(() => {
    if (status) {
      onSaveField(); // Send all fields to parent
    }
    if (error !== "" && error != null) {
      setSuccessMessage(error);
      setError(null);
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <div className="pull-left Txt_bold">Add Index Field</div>
          <div className="pull-right"></div>
        </div>
      </div>
      {successMessage && (
        <CustomAlertV2
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
          onConfirm={null}
          isCancelButton={false}
        />
      )}
      <div className="grayBox">
        {fields.map((field, index) => (
          <div className="addIndexFieldPanal mb-3" key={index}>
            <div className="div1">
              <Form.Group controlId={`formFieldName-${index}`}>
                {index === 0 && <Form.Label>Field Name</Form.Label>}
                <Form.Control
                  type="text"
                  placeholder="Enter field name"
                  name="fieldName"
                  value={field.fieldName}
                  required={true}
                  minLength={2}
                  maxLength={20}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Form.Group>
            </div>

            <div className="div2">
              <Form.Group controlId={`formDataType-${index}`}>
                {index === 0 && <Form.Label>Data Type</Form.Label>}
                <Form.Select
                  name="dataType"
                  value={field.dataType}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option>String</option>
                  <option>Number</option>
                  <option>Date</option>
                  <option>Boolean</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="div3">
              <Form.Group controlId={`formStringLength-${index}`}>
                {index === 0 && <Form.Label>Field Length</Form.Label>}
                <Form.Control
                  type="number"
                  name="stringLength"
                  value={field.stringLength}
                  required
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Form.Group>
            </div>

            <div className="div4">
              <Form.Group controlId={`formDefaultValue-${index}`}>
                {index === 0 && <Form.Label>Default Value</Form.Label>}
                <Form.Control
                  type="text"
                  placeholder="Enter default value"
                  name="defaultValue"
                  value={field.defaultValue}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Form.Group>
            </div>

            {index === 0 ? (
              <div className="div5 ">
                <Form.Check
                  type="checkbox"
                  label="Required"
                  name="isRequired"
                  checked={field.isRequired}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
            ) : (
              <div className="div5 Required">
                <Form.Check
                  type="checkbox"
                  label="Required"
                  name="isRequired"
                  checked={field.isRequired}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
            )}

            {index === 0 ? (
              <div className="div6">
                <Button variant="success BuleClor" onClick={handleAddRow}>
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </Button>{" "}
              </div>
            ) : (
              <div className="div6 removemarginTop">
                <Button
                  variant="danger BuleClor"
                  onClick={() => handleRemoveRow(index)}
                >
                  <i
                    className="fa fa-minus-circle blueColor"
                    aria-hidden="true"
                  ></i>
                </Button>
              </div>
            )}

            <div className="clearfix"></div>
          </div>
        ))}
      </div>
      <Button variant="" type="submit" className="saveBtn mt-3">
        Save Field
      </Button>
    </Form>
  );
};

export default AddIndexField;
