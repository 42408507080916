import React, { useEffect, useState } from "react";
import { Table, Form, Pagination, Button } from "react-bootstrap";
import { useGetPendingRequestList } from "../application/Hooks/useGetPendingRequestList";
import { Checkbox } from "@mui/material";
import useApproveRejectRequests from "../application/Hooks/approveRejectRequests";
import CustomAlertV2 from "./CustomAlertV2";
import PopupExample from "./PopupExample";
import useGetDeletedFiles from "../application/Hooks/useGetDeletedFiles";

const ManageDeletedFiles = ({ companyId }) => {
  const { getDeletedFiles, deletedFilesList, error } =
    useGetDeletedFiles(companyId);

  //

  // const [popupData, setPopupData] = useState({
  //   status: "",
  //   searchBy: "fileName",
  //   searchValue: "",
  // });
  // const {
  //   pendingRequestResponse,
  //   getPendingRequestList,
  //   setPendingRequestResponse,
  //   error1,
  //   setError,
  // } = useGetPendingRequestList(popupData);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [fieldsPerPage, setFieldsPerPage] = useState(10); // Default number of fields per page
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [request, setRequest] = useState([]);
  const { approveRejectRequest, approveRejectRequests, loading } =
    useApproveRejectRequests();
  const [successMessage, setSuccessMessage] = useState(null);

  const isAllSelected =
    deletedFilesList.length > 0 &&
    selectedRequests.length === deletedFilesList.length;

  const handleSelectAllClick = (event) => {
    debugger;
    if (event.target.checked) {
      const allIds = paginatedData.map((row) => row.documentId); // Select all row ids
      setSelectedRequests(allIds);
    } else {
      setSelectedRequests([]); // Deselect all
    }
  };

  const handleSelectRow = (id) => {
    debugger;
    setSelectedRequests((prevSelected) => {
      debugger;
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id); // Deselect
      } else {
        return [...prevSelected, id]; // Select
      }
    });
  };

  const handleApproveRejectButton = async (pendingRequestId, action) => {
    let dummyRequest = { selectedRequests, action };
    setRequest(dummyRequest);
    if (action === "PermanentDelete") action = "permanent delete";
    setSuccessMessage("Do you really want to " + action + " the files.");
  };

  const handleConfirm = async () => {
    await approveRejectRequests(request);
    console.log("ApproveRequest after calling: ", approveRejectRequest);
  };

  const handleViewClick = (filePath) => {
    window.open(filePath, "_blank");
  };
  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  const paginatedData =
    deletedFilesList?.slice(indexOfFirstField, indexOfLastField) || [];

  // Calculate the "Showing x to y of z entries" message

  const start = Math.min(indexOfFirstField + 1, deletedFilesList.length);
  const end = Math.min(indexOfLastField, deletedFilesList.length);

  useEffect(() => {
    debugger;
    if (approveRejectRequest) {
      getDeletedFiles(companyId);
      setSelectedRequests([]);
    }

    // if (popupData) {
    //   getPendingRequestList(popupData);
    //   setSelectedRequests([]);
    // }
  }, [approveRejectRequest]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {}, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="tab_wrp">
              <div className="row">
                <div className="col-md-12">
                  <div className="deletePageAddWrp">
                    <div className="div1">
                      <Form.Group controlId="fieldsPerPageSelect">
                        <Form.Label>Show</Form.Label>
                        <Form.Control
                          as="select"
                          value={fieldsPerPage}
                          onChange={(e) =>
                            setFieldsPerPage(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div className="div2">
                      <button
                        type="button"
                        className="btn approveBtn"
                        onClick={() =>
                          handleApproveRejectButton(selectedRequests, "Restore")
                        }
                        disabled={loading || selectedRequests.length === 0}
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                        Restore
                      </button>
                      <button
                        type="button"
                        className="btn rejectBtn"
                        onClick={() =>
                          handleApproveRejectButton(
                            selectedRequests,
                            "PermanentDelete"
                          )
                        }
                        disabled={loading || selectedRequests.length === 0}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </button>

                      {/* <PopupExample
                      popupData={popupData}
                      setPopupData={setPopupData}
                    ></PopupExample> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="pendingReqstTbl">
                    <Table className="table table-bordered blueTbl pendingRqstTbl">
                      <thead>
                        <tr>
                          <th padding="checkbox">
                            <Checkbox
                              indeterminate={
                                selectedRequests.length > 0 &&
                                selectedRequests.length <
                                  deletedFilesList.length
                              }
                              checked={isAllSelected}
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all rows",
                              }}
                            />
                          </th>
                          <th>Folder Name</th>
                          <th>File Name</th>
                          <th>Total Page</th>
                          <th></th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.length > 0 ? (
                          paginatedData.map((field, index) => (
                            <tr key={index}>
                              <td padding="checkbox">
                                <Checkbox
                                  checked={selectedRequests.includes(
                                    field.documentId
                                  )}
                                  onChange={() =>
                                    handleSelectRow(field.documentId)
                                  }
                                  inputProps={{
                                    "aria-label": `select row ${field.documentId}`,
                                  }}
                                />
                              </td>
                              <td>{field.folder ? field.folder : "/"}</td>
                              <td>{field.fileName}</td>
                              <td>{field.totalPages}</td>
                              <td>
                                <button
                                  className="btn editBtn"
                                  onClick={() =>
                                    handleViewClick(field.filePath)
                                  }
                                >
                                  <i
                                    class="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  View
                                </button>
                              </td>
                              {/* 
                              
                              <td>
                                <button
                                  className="btn rejectBtn"
                                  onClick={() => {
                                    handleApproveRejectButton(
                                      field.pendingRequestId,
                                      "Reject"
                                    );
                                  }}
                                  disabled={loading}
                                >
                                  Reject
                                </button>
                                <button
                                  className="btn approveBtn"
                                  onClick={() => {
                                    handleApproveRejectButton(
                                      field.pendingRequestId,
                                      "Approve"
                                    );
                                  }}
                                  disabled={loading}
                                >
                                  Approve
                                </button>
                              </td>
                              */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No fields found.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="float-start">
                    Showing <strong>{start}</strong> to <strong>{end}</strong>{" "}
                    of <strong>{deletedFilesList.length}</strong> entries
                  </div>
                  <div className="float-end">
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      <Pagination.Item active>{currentPage}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={
                          !deletedFilesList ||
                          deletedFilesList.length === 0 ||
                          currentPage ===
                            Math.ceil(deletedFilesList.length / fieldsPerPage)
                        }
                      />
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {successMessage && (
            <CustomAlertV2
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
              onConfirm={handleConfirm}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ManageDeletedFiles;
