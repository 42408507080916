
import { useState,useEffect } from 'react';
import { ApiRetentionScheduleRepository } from "../../infrastructure/repositories/ApiRetentionScheduleRepository";
import { GetFolderList } from '../UseCase/GetFolderList';
import useApiClient from '../../infrastructure/apiClient';
import { get } from 'jquery';

export const useFolderList = (companyId) => {
  const [folderList, setFolderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();


  const getFolderList = async (companyId) => {
    debugger;
    const retentionScheduleRepository = new ApiRetentionScheduleRepository(apiClient);
    const folderListUseCase = new GetFolderList(retentionScheduleRepository);
    try 
    {
      const result = await folderListUseCase.execute(companyId);        
      setFolderList(result);
    } 
    catch (err) {
      setError(err.message);
    } 
    finally {
      setLoading(false);
    }
  };

  useEffect(() =>{
    if (companyId === undefined || isNaN(companyId)|| companyId===0) {
      console.log("CompanyId not defined, No API Call here.");
    } else {
      getFolderList(companyId);
    }
  },[companyId])
  
  return { getFolderList ,folderList, loading, error };
};
export default useFolderList;


 