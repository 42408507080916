import React from "react";
import pagedevelopemnt from "../assets/img/pagedevelopemnt.jpg";
const UnderContruction = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-12 underdevelopment">
          <img
            src={pagedevelopemnt}
            width=""
            height=""
            alt="Lockbox"
            loading="lazy"
          />
          <h1 className="underdevelopment">Page under development</h1>
        </div>
      </div>

    </div>
  );
};

export default UnderContruction;
