import React, { useState, useEffect } from "react";
import useChangePassword from "../application/Hooks/useChangePassword";
import CustomAlertV2 from "../components/CustomAlertV2";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  const { response, changePassword, error } = useChangePassword();
  const [successMessage, setSuccessMessage] = useState(null);
  const handleConfirm = async () => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    debugger;
    event.preventDefault();
    if (validate()) {
      console.log("Form data:", formData);
      // Final transformed data
      const finalChangePwdReq = {
        OldPassword: formData.oldpassword,
        NewPassword: formData.newpassword,
        ConfirmPassword: formData.confirmpassword,
      };
      changePassword(finalChangePwdReq);
    }
  };
  useEffect(() => {
    debugger;
    if (response.status === 200) {
      setSuccessMessage("Password has been changed successfully!");
    } else if (response.status === "400") {
      console.log(response.message);
      setSuccessMessage(response.message);
    }
  }, [response]);

  const validate = () => {
    // const newErrors = {};

    // [Required] and [StringLength] validation for NewPassword
    if (formData.newpassword.length < 8 || formData.newpassword.length > 20) {
      setSuccessMessage(
        "The password must be between 8 and 20 characters long"
      );
      return 0;
    }

    // [Compare] validation for ConfirmPassword
    if (formData.confirmpassword !== formData.newpassword) {
      setSuccessMessage(
        "The new password and confirmation password do not match"
      );
      return 0;
    }
    return 1;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="page_title">Change Password</div>
            <form onSubmit={handleSubmit}>
              <div className="contant_box_wrp">
                <div className="manageCompanyWrp ">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            New passwords are required to be between minimum of
                            8 characters and a maximum of 20 in length, and must
                            contain one number and one capital letter.
                          </p>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label>Old Password</label>
                            <input
                              type="password"
                              name="oldpassword"
                              id="oldpassword"
                              className="form-control"
                              value={formData.oldpassword}
                              onChange={handleChange}
                              minLength={8}
                              maxLength={20}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label>New Password</label>
                            <input
                              type="password"
                              name="newpassword"
                              id="newpassword"
                              className="form-control"
                              value={formData.newpassword}
                              onChange={handleChange}
                              minLength={8}
                              maxLength={20}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label>Confirm New Password</label>
                            <input
                              type="password"
                              name="confirmpassword"
                              id="confirmpassword"
                              className="form-control"
                              value={formData.confirmpassword}
                              onChange={handleChange}
                              minLength={8}
                              maxLength={20}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn nextBtn">
                Save
              </button>
            </form>
          </div>
        </div>
        {successMessage && (
          <CustomAlertV2
            message={successMessage}
            onClose={() => setSuccessMessage(null)}
            onConfirm={handleConfirm}
            isCancelButton={false}
          />
        )}
      </div>
    </div>
  );
};
export default ChangePassword;
