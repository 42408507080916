import React, { useState, useEffect } from "react";
import pagedevelopemnt from "../assets/img/notfound.jpg";
import { useLocation } from "react-router-dom";
import { Button, Form, Pagination, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomAlertV2 from "../components/CustomAlertV2";
import useUpdateDocument from "../application/Hooks/useUpdateDocument";
import useCreatePendingRequests from "../application/Hooks/useCreatePendingRequests";
import useSearchDocument from "../application/Hooks/useSearchDocument";

const SearchResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filter, setFilter] = useState(""); // Filter input value
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const {
    documentResponse: myDocumentResponse,
    searchDocument: performSearch,
    error1: SearchError,
    setError: updateError,
  } = useSearchDocument();
  const { documentResponse, SearchCodition } = location.state || {};
  // const documentResponse = location.state?.documentResponse || [];
  console.log("documentResponse " + JSON.stringify(documentResponse));
  console.log("SearchCodition " + JSON.stringify(SearchCodition));
  const [errorMessage, setErrorMessage] = useState(null);
  const [DeleteMessage, setDeleteMessage] = useState(null);
  const [fieldsPerPage, setFieldsPerPage] = useState(10); // Default number of fields per page
  const [selectedFields, setSelectedFields] = useState([]); // To store selected fields
  const [SuccessMessage, setSuccessMessage] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const {
    updateDocumentRes,
    UpdateDoc,
    error,
    setupdateDocumentRes,
    setError,
  } = useUpdateDocument();
  //For Pagination
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  // const paginatedData = userList.slice(indexOfFirstField, indexOfLastField);
  // Use optional chaining to ensure indexFields is not undefined or null before slicing
  const paginatedData =
    documentResponse?.slice(indexOfFirstField, indexOfLastField) || [];

  const start = Math.min(indexOfFirstField + 1, documentResponse.length);
  const end = Math.min(indexOfLastField, documentResponse.length);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { pendingRequestResponse, EmailRequestList, error1 } =
    useCreatePendingRequests();

  // Columns to be excluded from the display
  const excludedColumns = [
    "FilePath",
    "DocumentId",
    "CreatedDate",
    "FileSize",
    "CompanyId",
  ];
  const nonEditableColumns = ["FileName", "TotalPages"];
  // Check if there is no data
  // if (documentResponse.length === 0) {
  //   return <p>No data found.</p>;
  // }
  // Function to toggle dialog visibility
  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  // Function to handle Apply button logic
  const handleApply = async (e) => {
    e.preventDefault();
    const result = documentResponse.filter((doc) =>
      doc.FileName.toLowerCase().includes(filter.toLowerCase())
    );

    navigate("/SearchResult", {
      state: { documentResponse: result, SearchCodition },
    });
    toggleModal(); // Close the modal after applying the filter
  };

  // Function to handle Reset button logic
  const handleReset = () => {
    setFilter("");
    performSearch(SearchCodition);
  };
  const columns =
    documentResponse.length > 0
      ? Object.keys(documentResponse[0]).filter(
        (column) => !excludedColumns.includes(column)
      )
      : [];
  const HandleBackbutton = () => {
    navigate("/");
  };
  const handleSendFileClick = () => {
    // Filter documentResponse to get the full details of selected rows
    const selectedData = documentResponse
      .filter((field) => selectedFields.includes(field.DocumentId))
      .map((field) => ({
        FileName: field.FileName,
        DocumentId: field.DocumentId,
        CreatedDate: field.CreatedDate,
        CompanyId: field.CompanyId,
        FileSize: field.FileSize,
        TotalPages: field.TotalPages,
      }));

    console.log("Selected Data to Send:", JSON.stringify(selectedData));
    navigate("/sendFile", {
      state: { selectedData, documentResponse, SearchCodition },
    });
  };
  const handleDeleteSelected = () => {
    console.log("deleted field " + selectedFields);
    setDeleteMessage("Are you sure you want to delete the selected records ?");
  };
  const handleConfirm = () => {
    const selectedData = documentResponse
      .filter((field) => selectedFields.includes(field.DocumentId))
      .map((field) => ({
        FileName: field.FileName,
        DocumentId: field.DocumentId,
        CreatedDate: field.CreatedDate,
        CompanyId: field.CompanyId,
        FileSize: field.FileSize,
        TotalPages: field.TotalPages,
      }));
    console.log("Request selectedData:", JSON.stringify(selectedData));
    const companyId =
      selectedData.length > 0 ? selectedData[0].CompanyId : null;

    const pendingRequestsTransport = selectedData.map((file) => ({
      DocumentId: file.DocumentId,
      FileName: file.FileName,
      FileSize: file.FileSize,
      PageCount: file.TotalPages,
    }));

    const requestPayload = {
      Action: "Delete",
      CompanyId: companyId,
      PendingRequestsTransport: pendingRequestsTransport,
      SendingInformationTransport: null,
    };

    console.log("Request Payload:", requestPayload);

    setDeleteMessage(""); // Clear any previous errors
    EmailRequestList(requestPayload);

    //const dataToSend = selectedFields.map((id) => parseInt(id));
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allFieldIds = documentResponse.map((field) => field.DocumentId);
      setSelectedFields(allFieldIds);
      // setSelectedFieldsData(documentResponse);
    } else {
      setSelectedFields([]);
    }
  };
  const handleCheckboxChange = (fieldId) => {
    setSelectedFields((prevSelectedFields) =>
      prevSelectedFields.includes(fieldId)
        ? prevSelectedFields.filter((id) => id !== fieldId)
        : [...prevSelectedFields, fieldId]
    );
  };
  const handleEditClick = (rowIndex) => {
    setEditingRow(rowIndex);
    setUpdatedData(paginatedData[rowIndex]);
  };

  const handleUpdateClick = async (rowIndex) => {
    try {
      console.log("editable data " + JSON.stringify(updatedData));
      setEditingRow(null);
      await UpdateDoc(updatedData);
    } catch (error1) {
      console.error("Failed to update row:", error1);
    }
  };
  const handleFieldChange = (e, column) => {
    setUpdatedData({ ...updatedData, [column]: e.target.value });
  };

  const handleViewClick = (filePath) => {
    window.open(filePath, "_blank");
  };

  // Helper function to render cell values
  const renderCellValue = (value) => {
    debugger;
    if (value === null || value === undefined) return "";
    // Check if the value is the default date
    if (typeof value === "object") return JSON.stringify(value); // Convert object to a string
    return value.toString(); // Convert non-string values to string
  };
  useEffect(() => {
    if (error !== "" && error != null) {
      setErrorMessage(error);
      setError(null);
    }
  }, [error, setErrorMessage]);

  useEffect(() => {
    debugger;
    if (updateDocumentRes === 200) {
      debugger;
      console.log("calling performserach");
      performSearch(SearchCodition);
      setupdateDocumentRes(null); // Reset the state
    }
  }, [updateDocumentRes, setupdateDocumentRes, myDocumentResponse]);
  useEffect(() => {
    if (pendingRequestResponse === 200) {
      setSuccessMessage("Request send successfully");
    }
  }, [pendingRequestResponse, navigate]);
  const handleConfirm1 = async () => {
    navigate(`/`);
  };
  useEffect(() => {
    console.log("myDocumentResponse " + JSON.stringify(myDocumentResponse));
    if (myDocumentResponse) {
      console.log(
        "myDocumentResponse Response  : ",
        JSON.stringify(myDocumentResponse)
      );

      navigate("/SearchResult", {
        state: { documentResponse: myDocumentResponse, SearchCodition },
      });
    }
    if (SearchError !== "" && SearchError != null) {
      console.log("SearchError " + SearchError);
      setErrorMessage(SearchError);
    }
  }, [myDocumentResponse, SearchCodition, SearchError, navigate]);

  return (
    <div className="container mt-4">
      <div className="row">
        {!documentResponse || documentResponse.length === 0 ? (
          <div className="col-md-12 text-center">
            <div className="notFound">
              <img
                src={pagedevelopemnt}
                width=""
                height=""
                alt="Lockbox"
                loading=""
              />
              <h1>No data found.</h1>
              <button onClick={HandleBackbutton} className="btn backTopBtn">
                Back
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="col-md-12">
              {errorMessage && (
                <CustomAlertV2
                  message={errorMessage}
                  onClose={() => setErrorMessage(null)}
                  onConfirm={() => setErrorMessage(null)}
                  isCancelButton={false}
                />
              )}
              {documentResponse && (
                <div className="deletePageAddWrp">
                  <div className="div1">
                    {/* Textbox for fields per page */}
                    <Form.Group controlId="fieldsPerPageSelect">
                      <Form.Label>Show</Form.Label>
                      <Form.Control
                        as="select"
                        value={fieldsPerPage}
                        onChange={(e) =>
                          setFieldsPerPage(Number(e.target.value))
                        }
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div className="div2">
                    <Button
                      variant="danger deleteBtn"
                      onClick={handleDeleteSelected}
                      disabled={selectedFields.length === 0} // Disable if no field is selected
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i> Delete
                    </Button>
                    {DeleteMessage && (
                      <CustomAlertV2
                        message={DeleteMessage}
                        onClose={() => setDeleteMessage(null)}
                        onConfirm={handleConfirm}
                      />
                    )}
                    {SuccessMessage && (
                      <CustomAlertV2
                        message={SuccessMessage}
                        onClose={() => setSuccessMessage(null)}
                        onConfirm={handleConfirm1}
                        isCancelButton={false}
                      />
                    )}
                  </div>
                  <div className="div2">
                    <Button
                      variant="danger deleteBtn sendBtn"
                      disabled={selectedFields.length === 0} // Disable if no field is selected
                      onClick={handleSendFileClick}
                    >
                      <i class="fa fa-paper-plane-o" aria-hidden="true"></i>{" "}
                      Send File
                    </Button>
                  </div>
                  <div className="div3">
                    <Button
                      onClick={toggleModal}
                      className="btn searchBtn mgnTop-0"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i> Search
                    </Button>

                    {/* Dialog Box
                    {isDialogOpen && (
                      <div className="dialog-backdrop">
                        <div className="dialog-box">
                          <div className="dialog-content">
                            <label htmlFor="searchBy">Search by</label>
                            <div className="search-row">
                              <select id="searchBy" className="form-control">
                                <option value="fileName">File Name</option>
                              </select>
                              <input
                                type="text"
                                placeholder="Enter search text"
                                className="form-control"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="dialog-actions">
                            <button
                              onClick={handleApply}
                              className="btn btn-success"
                            >
                              Apply
                            </button>
                            <button
                              onClick={handleReset}
                              className="btn btn-secondary"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {/* Modal for Search */}
                    <Modal show={showModal} onHide={toggleModal} centered>
                      <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: "18px" }}>Search Filter</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="DocumentSerchModal">
                        <label htmlFor="searchBy" className="form-label">
                          Search by
                        </label>
                        <div className="input-group">
                          <select id="searchBy" className="form-select">
                            <option value="fileName">File Name</option>
                          </select>
                          <input
                            type="text"
                            style={{ width: "40%", marginLeft: "10px" }}
                            placeholder="Enter search text"
                            className="form-control"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="DocumentSerchFooter">
                        <div className="mb-3">
                          <Button variant="btn backBtn" onClick={handleApply}>
                            Apply
                          </Button>
                          <Button variant="btn nextBtn" onClick={handleReset}>
                            Reset
                          </Button>
                        </div>

                      </Modal.Footer>
                    </Modal>

                    <button
                      onClick={HandleBackbutton}
                      className="btn backTopBtn mgnTop-0"
                    >
                      Back
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </div>
              )}
            </div>

            <div className="col-md-12">
              <div className="searchResultTbl">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedFields.length > 0 &&
                            selectedFields.length === documentResponse.length
                          }
                        />
                      </th>
                      {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                      <th>Actions</th> {/* Adding extra column for actions */}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedFields.includes(item.DocumentId)}
                            onChange={() =>
                              handleCheckboxChange(item.DocumentId)
                            }
                          />
                        </td>
                        {columns.map((column, colIndex) => (
                          <td key={colIndex}>
                            {editingRow === rowIndex &&
                              !nonEditableColumns.includes(column) ? (
                              <input
                                type="text"
                                value={updatedData[column] || ""}
                                onChange={(e) => handleFieldChange(e, column)}
                              />
                            ) : (
                              renderCellValue(item[column])
                            )}
                          </td>
                        ))}
                        <td>
                          {editingRow === rowIndex ? (
                            <>
                              <button
                                className="btn editBtn"
                                onClick={() => handleUpdateClick(rowIndex)}
                              >
                                Update
                              </button>
                              <button
                                className="btn editBtn"
                                onClick={() => setEditingRow(null)}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn editBtn"
                                onClick={() => handleViewClick(item.FilePath)}
                              >
                                <i
                                  class="fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                                View
                              </button>
                              <button
                                className="btn editBtn"
                                onClick={() => handleEditClick(rowIndex)}
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                                Edit
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12">
              <div className="">
                <div className="float-start">
                  Showing <strong>{start}</strong> to <strong>{end}</strong> of{" "}
                  <strong>{documentResponse.length}</strong> entries
                </div>
                <div className="float-end">
                  {/* Pagination Component */}
                  <Pagination>
                    {/* Previous Button */}
                    <Pagination.Prev
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    />

                    {/* Show only the current page number */}
                    <Pagination.Item active>{currentPage}</Pagination.Item>

                    {/* Next Button */}
                    <Pagination.Next
                      onClick={() => paginate(currentPage + 1)}
                      disabled={
                        !documentResponse ||
                        documentResponse.length === 0 ||
                        currentPage ===
                        Math.ceil(documentResponse.length / fieldsPerPage)
                      }
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
// Mock API call function (replace this with actual API call)
// const updateRowInDatabase = async (data) => {
//   console.log("editable data " + JSON.stringify(data));
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(data), 1000);
//   });
// };
export default SearchResult;
