import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Table, Form, Alert } from "react-bootstrap";
import useCreatePendingRequests from "../application/Hooks/useCreatePendingRequests";
import { useOidcUser } from "@axa-fr/react-oidc";
import CustomAlertV2 from "../components/CustomAlertV2";
import useSearchDocument from "../application/Hooks/useSearchDocument";

const SendFile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { oidcUser } = useOidcUser();
  const {
    selectedData: SendFileData,
    documentResponse,
    SearchCodition,
  } = location.state || {};
  const {
    documentResponse: myDocumentResponse,
    searchDocument: performSearch,
    error1: SearchError,
    setError: updateError,
  } = useSearchDocument();
  //const SendFileData = location.state?.selectedData || [];
  const [errorMessage, setErrorMessage] = useState(null);
  const [SuccessMessage, setSuccessMessage] = useState(null);

  console.log("SendFileData :" + JSON.stringify(SendFileData));

  const [to, setTo] = useState("");
  const [from, setFrom] = useState(oidcUser.email);
  const [message, setMessage] = useState(
    "Please click on the following link to access files"
  );
  const [additionalNote, setAdditionalNote] = useState("");
  const [error, setError] = useState("");
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [showError, setShowError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);

  const { pendingRequestResponse, EmailRequestList, error1 } =
    useCreatePendingRequests();
  const validateEmails = (emailString) => {
    if (!emailString.trim()) {
      setEmptyError(true);
      setInvalidEmails([]);
      return false;
    }

    setEmptyError(false); // Clear empty error if not blank
    const emailArray = emailString.split(",").map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const invalid = emailArray.filter((email) => !emailRegex.test(email));
    setInvalidEmails(invalid);
    setShowError(invalid.length > 0);

    return invalid.length === 0;
  };
  const handleSend = (e) => {
    e.preventDefault();

    if (!validateEmails(to)) {
      return;
    }

    // Get CompanyId from the first row of tableData
    const companyId =
      SendFileData.length > 0 ? SendFileData[0].CompanyId : null;

    const pendingRequestsTransport = SendFileData.map((file) => ({
      DocumentId: file.DocumentId,
      FileName: file.FileName,
      FileSize: file.FileSize,
      PageCount: file.TotalPages,
    }));

    const requestPayload = {
      Action: "SendEmail",
      CompanyId: companyId,
      PendingRequestsTransport: pendingRequestsTransport,
      SendingInformationTransport: {
        To: to,
        From: from,
        Message: message,
        AdditionalNote: additionalNote,
      },
    };

    console.log("Request Payload:", requestPayload);

    setError(""); // Clear any previous errors
    EmailRequestList(requestPayload);
  };

  const handleCancel = () => {
    navigate(`/`);
  };

  useEffect(() => {
    if (pendingRequestResponse === 200) {
      setSuccessMessage("Request sent successfully");
    }
  }, [pendingRequestResponse, navigate]);
  useEffect(() => {
    if (error !== "" && error != null) {
      setErrorMessage(error);
    }
  }, [error, setErrorMessage]);
  const handleConfirm = async () => {
    window.location.reload();
  };
  const handleConfirm1 = async () => {
    performSearch(SearchCodition);
    // navigate(`/`);
  };
  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} bytes`;
    } else if (sizeInBytes < 1048576) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`; // 1 KB = 1024 bytes
    } else {
      return `${(sizeInBytes / 1048576).toFixed(2)} MB`; // 1 MB = 1024 * 1024 bytes
    }
  };
  useEffect(() => {
    console.log("myDocumentResponse " + JSON.stringify(myDocumentResponse));
    if (myDocumentResponse) {
      console.log(
        "myDocumentResponse Response  : ",
        JSON.stringify(myDocumentResponse)
      );

      navigate("/SearchResult", {
        state: { documentResponse: myDocumentResponse, SearchCodition },
      });
    }
    if (SearchError !== "" && SearchError != null) {
      console.log("SearchError " + SearchError);
      setErrorMessage(SearchError);
    }
  }, [myDocumentResponse, SearchCodition, SearchError, navigate]);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-12">
          <div className="page_title">Send Files</div>
          <div className="contant_box_wrp">
            <div className="row">
              <div className="col-md-7">
                <div className="padd10 shadow">
                  <div className="sendFileTbl ">
                    <Table>
                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th>File Size</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SendFileData.map((file, index) => {
                          const formattedDate = (() => {
                            const [day, month, year] =
                              file.CreatedDate.split("-");
                            return new Intl.DateTimeFormat("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(new Date(`${year}-${month}-${day}`));
                          })();

                          return (
                            <tr key={index}>
                              <td>{file.FileName}</td>
                              <td>{formatFileSize(file.FileSize)}</td>{" "}
                              {/* Format file size here */}
                              <td>{formattedDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="padd10 shadow email_Form">
                  <Form onSubmit={handleSend}>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <Form.Group className="mb-3" controlId="formTo">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={to}
                        onChange={(e) => {
                          setTo(e.target.value);
                          setShowError(false); // Clear error message on user input
                        }}
                        placeholder="Enter comma-separated email addresses"
                        isInvalid={showError || emptyError}
                      />
                      {emptyError && (
                        <Form.Text className="text-danger">
                          The 'To' field cannot be empty.
                        </Form.Text>
                      )}
                      {!emptyError && showError && (
                        <Form.Text className="text-danger">
                          Invalid email(s): {invalidEmails.join(", ")}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formFrom">
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        type="email"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMessage">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={message}
                        required={true}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formAttachment">
                      <Form.Label>Attachment</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={SendFileData.map(
                          (file) => file.FileName
                        ).join(", ")}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formNote">
                      <Form.Label>Add additional note</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={additionalNote}
                        onChange={(e) => setAdditionalNote(e.target.value)}
                      />
                    </Form.Group>

                    <div className="SendEmailBtnGroup">
                      <Button
                        type="submit"
                        variant="primary"
                        className="nextBtn"
                      >
                        Send
                      </Button>
                      <Button
                        variant="secondary"
                        className="backBtn"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                  <div className="clearfix"></div>
                </div>
                <div>
                  {errorMessage && (
                    <CustomAlertV2
                      message={errorMessage}
                      onClose={() => setErrorMessage(null)}
                      onConfirm={handleConfirm}
                      isCancelButton={false}
                    />
                  )}
                  {SuccessMessage && (
                    <CustomAlertV2
                      message={SuccessMessage}
                      onClose={() => setSuccessMessage(null)}
                      onConfirm={handleConfirm1}
                      isCancelButton={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendFile;
