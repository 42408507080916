// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiIndexFieldRepository } from '../../infrastructure/repositories/ApiIndexFieldRepository';
import { CreateIndexField } from '../UseCase/CreateIndexField';
import useApiClient from '../../infrastructure/apiClient';

export const useCreateIndexfield = () => {
    const[status,setstatus]=useState(false);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const CreateIndexfields = async (Fields) => {

    const companyRepository = new ApiIndexFieldRepository(apiClient);
    const CreateIndexfieldUseCase = new CreateIndexField(companyRepository);

    try {
      console.log('I am in useCreateIndexfield hook '+JSON.stringify(Fields));
      const result = await CreateIndexfieldUseCase.execute(Fields);
      setstatus(result);
      console.log('create index field response :'+result);
    } catch (err) {
      setError(err.message);
    }
  };

  return {status, CreateIndexfields,error,setError };
};
export default useCreateIndexfield;
