import { useOidcUser } from "@axa-fr/react-oidc";
import React from "react";
import { useState } from "react";
import { useCompanyInformation } from "../application/Hooks/useCompanyInformation";
import CompanyListDropDown from "../components/CompanyListDropDown";
import SearchDocument from "../components/SearchDocument";

const Home = () => {
  const { oidcUser } = useOidcUser();

  const [selectedValue, setSelectedValue] = useState(0);
  const { CompanyData, loading, error } = useCompanyInformation(selectedValue);

  console.log(CompanyData);
  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-4 mt-3">
          <div className="Select_Company">
            {oidcUser && oidcUser.role === "SuperAdmin" ? (
              <div className="input-group">
                <CompanyListDropDown onChange={handleDropdownChange} />
              </div>
            ) : (
              <div className="input-group">
                <span className="input-group-text">
                  <b>Search Your Document</b>
                </span>
                <span>
                  {CompanyData !== null && CompanyData !== undefined
                    ? CompanyData.name
                    : ""}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <SearchDocument CompanyId={selectedValue} />
      </div>
    </div>
  );
};

export default Home;
