import { useState, useEffect, useCallback } from "react"; 
import { ApiRetentionScheduleRepository } from "../../infrastructure/repositories/ApiRetentionScheduleRepository";
import { GetRetentionScheduleList } from "../UseCase/GetRetentionScheduleList";
import useApiClient from "../../infrastructure/apiClient";

export const useGetRetentionScheduleList = (companyId) => {
  const [retentionScheduleList, setRetentionScheduleList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  console.log('useGetRetentionScheduleList  Request : ' + companyId);

  // Extract the function to fetch the data, and make it reusable
  const getRetentionScheduleList = useCallback(async () => {
    const retentionScheduleRepository = new ApiRetentionScheduleRepository(apiClient);
    const getRetentionScheduleListUseCase = new GetRetentionScheduleList(retentionScheduleRepository);

    try {
      setLoading(true); // Start loading when the fetch is triggered
      console.log('I am in useGetRetentionScheduleList hook');
      const res = await getRetentionScheduleListUseCase.execute(companyId);
      console.log('useGetRetentionScheduleList  Response : ' + JSON.stringify(res));
      setRetentionScheduleList(res);
      setError(null); // Clear any previous errors if the request is successful
    } catch (err) {
      console.log('useGetRetentionScheduleList  Exception : ' + err);
      setError(err.message);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  }, [companyId,apiClient]);

  // Call the `getRetentionScheduleList` function inside `useEffect` to fetch the data when the component mounts or `companyId` changes
  useEffect(() => {
    if (companyId === undefined || isNaN(companyId) || companyId===0) 
    {
      console.log("CompanyId not defined, No API Call here.");
    } 
    else 
    {
        getRetentionScheduleList();
    }
   
  }, [getRetentionScheduleList,companyId]);

  // Return the state and the refetch function to trigger a manual reload
  return { retentionScheduleList, loading, error, refetch: getRetentionScheduleList };
};

export default useGetRetentionScheduleList;
