export class ImageProfileRepository{
    getImageProfile(companyId) {
        throw new Error('getImageProfile method not implemented');
      }
      CreateImageProfile(ImageProfile) {
        throw new Error('CreateImageProfile method not implemented');
      }
      async DeleteImageProfile(ImageProfileId) {
        throw new Error('DeleteImageProfile method not implemented');
      }
}