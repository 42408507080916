import { useState, useEffect, useCallback } from "react"; 
import { ApiImageProfileRepository } from '../../infrastructure/repositories/ApiImageProfileRepository';
import { GetImageProfile } from "../UseCase/GetImageProfile";
import useApiClient from "../../infrastructure/apiClient";

export const useGetImageProfile = (companyId) => {
  const [ImageProfile, setImageProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  // Extract the function to fetch the data, and make it reusable
  const GetImageProfiles = useCallback(async () => {
    const ImageProfileRepository = new ApiImageProfileRepository(apiClient);
    const getImageProfileUseCase = new GetImageProfile(ImageProfileRepository);

    try {
      setLoading(true); // Start loading when the fetch is triggered
      console.log('I am in useGetImageProfile hook');
      const res = await getImageProfileUseCase.execute(companyId);
      console.log('useGetImageProfile  Response : ' + JSON.stringify(res));
      setImageProfile(res);
      setError(null); // Clear any previous errors if the request is successful
    } catch (err) {
      console.log('useGetImageProfile  Exception : ' + err);
      setError(err.message);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  }, [companyId,apiClient]);

  // Call the `getIndexFieldList` function inside `useEffect` to fetch the data when the component mounts or `companyId` changes
  useEffect(() => {
    if (companyId === undefined || isNaN(companyId) || companyId===0) {
      console.log("CompanyId not defined, No API Call here.");
    } else {
        GetImageProfiles();
    }
   
  }, [GetImageProfiles,companyId]);

  // Return the state and the refetch function to trigger a manual reload
  return { ImageProfile, loading, error, refetch: GetImageProfiles };
};

export default useGetImageProfile;
