import React, { useState } from "react";
import UserLogsList from "./UserLogsList";
import FileUploadLogsList from "./FileUploadLogsList";
import useGetAuditLog from "../application/Hooks/useGetAuditLog";

const UserLogs = ({ companyId, auditLogRequest, setAuditLogRequest }) => {
  const [error1, setError1] = useState("");
  const [activeTab, setActiveTab] = useState("");

  const { getAuditLogs, auditLogs } = useGetAuditLog();

  const validateDates = () => {
    if (!auditLogRequest.fromDate || !auditLogRequest.toDate) {
      setError1(
        `Please enter ${!auditLogRequest.fromDate ? "Start Date" : "End Date"}.`
      );
      return false;
    }
    setError1(""); // Clear error if validation passes
    return true;
  };

  const getUserLogs = async (e) => {
    e.preventDefault();
    if (!validateDates()) return;
    setAuditLogRequest({ ...auditLogRequest, operation: "" });
    await getAuditLogs(auditLogRequest, "", "");
    setActiveTab("userLogsList");
  };

  const getFileUploadLogs = async (e) => {
    e.preventDefault();
    if (!validateDates()) return;
    setAuditLogRequest({ ...auditLogRequest, operation: "Upload" });
    await getAuditLogs(auditLogRequest, "Upload", "");
    setActiveTab("fileUploadLogsList");
  };

  return (
    <div id="userLogs">
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setAuditLogRequest({
                      ...auditLogRequest,
                      fromDate: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setAuditLogRequest({
                      ...auditLogRequest,
                      toDate: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {error1 && (
            <div className="row">
              <div className="col-md-12">
                <p style={{ color: "red" }}>{error1}</p>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <button
                type="submit"
                className="btn saveBtn mt-3 mb-3"
                onClick={getUserLogs}
              >
                User Logs
              </button>
              <button
                type="submit"
                className="btn saveBtn mt-3 mb-3"
                style={{ marginLeft: "10px" }}
                onClick={getFileUploadLogs}
              >
                File Upload Logs
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {activeTab === "userLogsList" && (
            <UserLogsList auditLogs={auditLogs}></UserLogsList>
          )}
          {activeTab === "fileUploadLogsList" && (
            <FileUploadLogsList auditLogs={auditLogs}></FileUploadLogsList>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserLogs;
