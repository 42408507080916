
import { useState } from 'react';
import { ApiImageProfileRepository } from '../../infrastructure/repositories/ApiImageProfileRepository';
import { DeleteImageProfile } from '../UseCase/DeleteImageProfile'; 
import useApiClient from '../../infrastructure/apiClient';

export const useDeleteImageProfile = () => {
    const[status,setstatus]=useState(false);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const DeleteImageProfiles = async (ImageProfileId) => {

    const ImageProfileRepository = new ApiImageProfileRepository(apiClient);
    const DeleteImageProfileUseCase = new DeleteImageProfile(ImageProfileRepository);

    try {
      console.log('I am in useDeleteImageProfile hook '+ImageProfileId);
      const result = await DeleteImageProfileUseCase.execute(ImageProfileId);
      setstatus(result);
      console.log('delete ImageProfile response :'+result);
    } catch (err) {
      setError(err.message);
    }
  };

  return {status, DeleteImageProfiles,error,setstatus };
};
export default useDeleteImageProfile;
