import React, { useEffect, useState } from "react";
import { useGetUserList } from "../application/Hooks/useGetUserList";
import CompanyInfoNotFound from "./CompanyInfoNotFound";
import CustomAlertV2 from "../components/CustomAlertV2";
import useDeleteUsers from "../application/Hooks/useDeleteUsers";
import { Table, Pagination, Form } from "react-bootstrap";
import { Typography, Checkbox } from "@mui/material";

const UserList = ({
  companyId,
  openTab,
  onSuccess,
  handleEditUser,
  companyStatus,
}) => {
  if (companyId === "") {
    companyId = 0;
  }
  const { userList, getUserList, error } = useGetUserList(companyId);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [selected, setSelected] = useState([]); // Track selected rows
  const [successMessage, setSuccessMessage] = useState(null);
  const { deletedUsers, deleteUsers, loading } = useDeleteUsers();

  //For Pagination
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [fieldsPerPage, setFieldsPerPage] = useState(5);

  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  // const paginatedData = userList.slice(indexOfFirstField, indexOfLastField);
  // Use optional chaining to ensure indexFields is not undefined or null before slicing
  const paginatedData =
    userList?.slice(indexOfFirstField, indexOfLastField) || [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleEditUserClick = (companyUserId) => {
    debugger;
    handleEditUser(companyUserId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [IsShowCompanyIfo, setIsShowCompanyIfo] = useState(false);
  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };
  // const paginatedData =
  //   userList !== undefined && userList !== null
  //     ? userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //     : [];
  function formatDate(dateString) {
    // Create a Date object from the input date string
    const date = new Date(dateString);

    // Extract components
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    // Format the date to the desired string
    const formattedDate = date.toUTCString("en-US", options);

    return formattedDate;
  }

  const isAllSelected =
    paginatedData.length > 0 && selected.length === paginatedData.length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allIds = paginatedData.map((row) => row.id); // Select all row ids
      setSelected(allIds);
    } else {
      setSelected([]); // Deselect all
    }
  };

  const handleSelectRow = (id) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id); // Deselect
      } else {
        return [...prevSelected, id]; // Select
      }
    });
  };

  const handleFetchSelectedIds = () => {
    console.log("Selected IDs:", selected);
    // You can now use `selected` IDs for further operations like submitting to a server, etc.
    setSuccessMessage("Do you really want to delete users ?");
  };

  const handleConfirm = async () => {
    debugger;
    await deleteUsers(selected);
  };

  useEffect(() => {
    debugger;
    if (deletedUsers !== null && deletedUsers !== undefined) {
      console.log("Delete Response", deletedUsers);
      if (deletedUsers.status === 200) {
        getUserList(companyId);
      }
    }

    if (
      companyId === undefined ||
      companyId === 0 ||
      companyId === "" ||
      isNaN(companyId)
    ) {
      setIsShowCompanyIfo(false);
    } else {
      setIsShowCompanyIfo(true);
    }
  }, [companyId, deletedUsers]);
  return (
    <div>
      {!IsShowCompanyIfo ? (
        <CompanyInfoNotFound />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="deletePageAddWrp">
                <div className="div1">
                  <Form.Group controlId="fieldsPerPageSelect">
                    <Form.Label>Show</Form.Label>
                    <Form.Control
                      as="select"
                      value={fieldsPerPage}
                      onChange={(e) => setFieldsPerPage(Number(e.target.value))}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="div2">
                  <button
                    type="button"
                    className="btn deleteBtn"
                    onClick={handleFetchSelectedIds}
                    disabled={companyStatus === "Deactivated"}
                  >
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                    Delete
                  </button>
                </div>
                <div className="div3">
                  <button
                    type="button"
                    className="btn addUserBtn"
                    onClick={() => {
                      onSuccess();
                      openTab("tabAddUser");
                    }}
                    disabled={companyStatus === "Deactivated"}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    Add User
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="tableScroll">
                <div className="table-responsive">
                  <Table className="table table-bordered blueTbl">
                    <thead>
                      <tr>
                        <th padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < paginatedData.length
                            }
                            checked={isAllSelected}
                            onChange={handleSelectAllClick}
                            inputProps={{ "aria-label": "select all rows" }}
                          />
                        </th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>User Name</th>
                        <th>Role</th>
                        <th>Created On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* If data is empty, show "No data found" row */}
                      {paginatedData === undefined ||
                      paginatedData.length === 0 ? (
                        <tr>
                          <td
                            colSpan={3}
                            align="center"
                            sx={{ padding: "16px" }}
                          >
                            <Typography variant="h6" color="textSecondary">
                              No data found
                            </Typography>
                          </td>
                        </tr>
                      ) : (
                        paginatedData.map((row) => (
                          <tr key={row.id}>
                            <td padding="checkbox">
                              <Checkbox
                                checked={selected.includes(row.id)}
                                onChange={() => handleSelectRow(row.id)}
                                inputProps={{
                                  "aria-label": `select row ${row.id}`,
                                }}
                              />
                            </td>
                            <td>{row.firstName}</td>
                            <td>{row.lastName}</td>
                            <td>{row.userEmail}</td>
                            <td>{row.role}</td>
                            <td>{formatDate(row.createdDate)}</td>
                            <td>
                              <button
                                type="button"
                                className="btn editBtn"
                                onClick={() => handleEditUserClick(row.id)}
                                disabled={companyStatus === "Deactivated"}
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                                Edit
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="bottom_Pagination_Wrp">
                {/* Pagination Component */}
                <Pagination>
                  {/* Previous Button */}
                  <Pagination.Prev
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  />

                  {/* Show only the current page number */}
                  <Pagination.Item active>{currentPage}</Pagination.Item>

                  {/* Next Button */}
                  <Pagination.Next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={
                      !userList ||
                      userList.length === 0 ||
                      currentPage === Math.ceil(userList.length / fieldsPerPage)
                    }
                  />
                </Pagination>
              </div>
            </div>
            {successMessage && (
              <CustomAlertV2
                message={successMessage}
                onClose={() => setSuccessMessage(null)}
                onConfirm={handleConfirm}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserList;
