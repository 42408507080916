import React from "react";
import workinprogress from "../assets/img/workinprogress.png";
import { useState, useEffect } from "react";
import CompanyInformation from "../components/CompanyInformation";
import CompanyListDropDown from "../components/CompanyListDropDown";
import IndexFieldList from "../components/IndexFieldList";
import ImageProfile from "../components/ImageProfile";
import { useLocation } from "react-router-dom";
import useUpdateCompanyStatus from "../application/Hooks/useUpdateCompanyStatus";
import { UpdateCompanyStatusRequest } from "../domain/entities/UpdateCompanyStatusRequest";
import CustomAlertV2 from "../components/CustomAlertV2";
import useGetCompanyStatus from "../application/Hooks/useGetCompanyStatus";

const ManageCompany = () => {
  // State to track active tab
  const [activeTab, setActiveTab] = useState("tabCompanyInformation");
  const location = useLocation();
  const { companyId } = location.state || {}; // Get companyId from location state
  const [selectedCompanyIdValue, setSelectedCompanyIdValue] =
    useState(companyId);

  const [CompanyStatus, setCompanyStatus] = useState("-");
  const [isDisabled, setisDisabled] = useState(false);
  const { CompId, updateCompanyStatus, error } = useUpdateCompanyStatus();
  const [successMessage, setSuccessMessage] = useState(null);
  const [DropDownCompanyStatus, setDropDownCompanyStatus] = useState(null);
  const { APICompanyStatus, loading, error1 } = useGetCompanyStatus(
    selectedCompanyIdValue
  );
  // Create a local state for APICompanyStatus to allow manual updates
  const [APICompanyStatusResponse, setAPICompanyStatusResponse] =
    useState(APICompanyStatus);

  // Update the local state when the API call response changes
  useEffect(() => {
    if (APICompanyStatus) {
      setAPICompanyStatusResponse(APICompanyStatus); // Sync with the API response
      if (APICompanyStatus === "Deactivated") setisDisabled(true);
      else setisDisabled(false);
    }
  }, [APICompanyStatus]);

  const handleDropdownChange = (value) => {
    console.log("dropdown change value " + value);
    setSelectedCompanyIdValue(value);
  };

  const handleCompanyStatus = (value) => {
    setCompanyStatus(value);
  };

  const ChangeCompanyStatus = (e) => {
    setDropDownCompanyStatus(e.target.value);
    const companyStatus =
      e.target.value === "Deactivated" ? "deactivate" : "activate";
    setSuccessMessage(
      "Are you sure you want to " + companyStatus + " the company?"
    );
  };
  const handleConfirm = () => {
    const Request = new UpdateCompanyStatusRequest(
      selectedCompanyIdValue,
      DropDownCompanyStatus
    );
    updateCompanyStatus(Request);
    setAPICompanyStatusResponse(DropDownCompanyStatus);
    if (DropDownCompanyStatus === "Deactivated") setisDisabled(true);
    else setisDisabled(false);
    //setSelectedCompanyIdValue(selectedCompanyIdValue);

    //refetch();
    //setSelectedCompany(e.target.value);
    //onChange(e.target.value);
  };
  // useEffect(() => {
  //   if (CompId) {
  //     setIsLoading(false); // Hide loader and enable button
  //     //alert("Company updated successfully");
  //     //TabFunction("tabIndexFields");
  //     setIsAlertOpen(true); // Open custom alert after saving
  //   }
  // }, [CompId]);

  // Function to handle tab switching
  const openTab = (tabId) => {
    setActiveTab(tabId);
  };

  const handleNext = () => {
    if (activeTab === "tabCompanyInformation") setActiveTab("tabIndexFields");
    else if (activeTab === "tabIndexFields") setActiveTab("tabImageProfiles");
    else if (activeTab === "tabImageProfiles")
      setActiveTab("tabCompanyInformation");
    else {
      setActiveTab("tabCompanyInformation");
    }
  };
  const handleBack = () => {
    if (activeTab === "tabCompanyInformation") {
      setActiveTab("tabEditOptions");
    } else if (activeTab === "tabIndexFields") {
      setActiveTab("tabCompanyInformation");
    } else if (activeTab === "tabImageProfiles") {
      setActiveTab("tabIndexFields");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="page_title">Manage Company</div>
            <div className="tab_wrp">
              <div className="row">
                <div className="col-md-12 manageCompanyWrp">
                  <div className="Select_Company">
                    <CompanyListDropDown
                      value={companyId}
                      onChange={handleDropdownChange}
                    />
                  </div>
                  <div className="active">
                    <button type="button" className="activatedBtn">
                      {APICompanyStatusResponse}
                    </button>
                  </div>

                  <div className="Act_div">
                    <select
                      className="form-select"
                      value={APICompanyStatusResponse} // Bind the value to the state
                      onChange={ChangeCompanyStatus}
                    >
                      <option>Activated</option>
                      <option>Deactivated</option>
                    </select>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>

              <div>
                <hr></hr>
              </div>

              {successMessage && (
                <CustomAlertV2
                  message={successMessage}
                  onClose={() => setSuccessMessage(null)}
                  onConfirm={handleConfirm}
                />
              )}

              {/* Tabs */}
              <div className="Tab_button_wrp">
                <button
                  className={
                    activeTab === "tabCompanyInformation" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("tabCompanyInformation")}
                >
                  Company Information
                </button>
                <button
                  className={
                    activeTab === "tabIndexFields" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("tabIndexFields")}
                >
                  Index Fields
                </button>
                <button
                  className={
                    activeTab === "tabImageProfiles" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("tabImageProfiles")}
                >
                  Image Profiles
                </button>
              </div>

              {/* Tab Content */}
              <div className="tab_box">
                {activeTab === "tabCompanyInformation" && (
                  <div id="tabCompanyInformation">
                    <CompanyInformation
                      value={selectedCompanyIdValue}
                      TabFunction={openTab} // Pass the function here
                      CompStatusFunction={handleCompanyStatus}
                      IsDisable={isDisabled}
                    />
                  </div>
                )}

                {activeTab === "tabIndexFields" && (
                  <div id="tabIndexFields">
                    <IndexFieldList
                      value={selectedCompanyIdValue}
                      IsDisable={isDisabled}
                    />
                  </div>
                )}

                {activeTab === "tabImageProfiles" && (
                  <div id="tabImageProfiles">
                    <ImageProfile
                      value={selectedCompanyIdValue}
                      IsDisable={isDisabled}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="footerBtnGroup">
            <button type="button" className="btn backBtn" onClick={handleBack}>
              Back
            </button>
            <button type="button" className="btn nextBtn" onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageCompany;
