import { useState } from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import { ChangePassword } from '../UseCase/ChangePassword';
import useApiClient from '../../infrastructure/apiClient';
import userApiClient from '../../infrastructure/userApiClient';

export const useChangePassword = () => {
    debugger;
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const changePassword = async (changepasswordrequest) => {
    const userRepository = new ApiUserRepository(userApiClient,apiClient);
    const changePasswordUseCase = new ChangePassword(userRepository);

    try {
      console.log('I am in changePassword hook');
      const result = await changePasswordUseCase.execute(changepasswordrequest);
      console.log('changePassword hook response : ' + result);
      setResponse(result);
    } catch (err) {
      setError(err.message);
    }
  };

  return { response, changePassword, error };
};
export default useChangePassword;
