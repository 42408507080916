import React from "react";
import notfound from "../assets/img/notfound.jpg";
const CompanyInfoNotFound = () => {
  return (

    <div className="notFoundWrp">
      <div className="notFound">
        <img
          src={notfound}
          width="73"
          height="100"
          alt="Lockbox"
        />

        <h1>Company Information </h1>
        <h2>not found</h2>
        <p>Please select dropdown</p>
      </div>
    </div>
  );
};

export default CompanyInfoNotFound;
