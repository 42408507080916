export class GetDeletedFiles {
    constructor(retentionScheduleRepository) 
    {
      this.retentionScheduleRepository = retentionScheduleRepository;
    }  
    async execute(companyId) {
      debugger;
     console.log('I am in GetDeletedFiles useCase');
      return await this.retentionScheduleRepository.getDeletedFiles(companyId);
    }
  }
