import React, { useState } from "react";

const PopupExample = ({ popupData, setPopupData, getPendingRequestList }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [searchBy, setsearchBy] = useState("fileName");
  const [searchValue, setsearchValue] = useState("");

  // const [popupData, setPopupData] = useState({
  //   status: "All",
  //   searchBy: "fileName",
  //   searchValue: "",
  // });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPopupData({ ...popupData, status: e.target.value });
  };

  const handleSearchByChange = (e) => {
    setsearchBy(e.target.value);
    setPopupData({ ...popupData, searchBy: e.target.value });
  };

  const postFilters = () => {
    debugger;
    setPopupData({
      status: status,
      searchBy: searchBy,
      searchValue: searchValue,
    });
    getPendingRequestList(popupData);
    togglePopup();
  };

  const handleResetButtonClick = () => {
    setStatus("");
    setsearchBy("FileName");
    setsearchValue("");
    setPopupData({ status: "", searchBy: "fileName", searchValue: "" });
  };

  return (
    <div>
      {/* Button to trigger popup */}
      <button className="btn searchBtn" onClick={togglePopup}>
        <i class="fa fa-search" aria-hidden="true"></i> Search
      </button>

      {/* Popup */}
      {isPopupOpen && (
        <div className="SerchPopupWrp">
          <h6>Status</h6>
          <select className="form-select"
            style={{ width: "100%" }}
            onChange={handleStatusChange}
            value={status}
          >
            <option value="">All</option>
            <option value="Rejected">Rejected</option>
            <option value="Approved">Approved</option>
            <option value="PendingApproval">Pending Approval</option>
          </select>
          <h6 className="mt-3">Search by</h6>
          <div style={{ display: "flex", gap: 5 }}>
            <select
              className="form-select"
              style={{ width: "40%" }}
              onChange={handleSearchByChange}
              value={searchBy}
            >
              <option value="fileName">FileName</option>
              <option value="actionBy">Action By</option>
            </select>
            {/* <select
              className="form-select"
              onChange={handleRoleSelect}
              value={role}
              required={true}
            >
              <option value="">Select</option>
              <option value="Admin">Admin</option>
              <option value="User">User</option>
              <option value="User (View only)">User (View only)</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              style={{ width: "60%" }}
              onChange={(e) => {
                setsearchValue(e.target.value);
                setPopupData({ ...popupData, searchValue: e.target.value });
              }}
              value={searchValue}
            />
          </div>
          <button
            className="btn backBtn"
            onClick={handleResetButtonClick}
            style={{ marginTop: "20px" }}
          >
            Reset
          </button>
          <button onClick={postFilters}
            className="btn nextBtn"
            style={{ marginLeft: 10 }}>
            Apply
          </button>
        </div>
      )}

      {/* Background overlay */}
      {isPopupOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 999,
          }}
          onClick={togglePopup}
        ></div>
      )}
    </div>
  );
};

export default PopupExample;
