import {AuditLogRepository} from '../../domain/repositories/AuditLogRepository'

export class ApiAuditLogRepository extends AuditLogRepository {
    constructor(apiClient) {
      super();
      this.apiClient = apiClient;
    }
    async getAuditLogs(getUserLogsRequest) {
        debugger;
        try 
        {            
            if(getUserLogsRequest.companyId === "" || getUserLogsRequest.companyId === undefined || getUserLogsRequest.companyId === null)
            {
                getUserLogsRequest.companyId = 0;
            }
            // console.log('Calling ApiAuditLogRepository - UpdateDocument - Fields : ' + JSON.stringify(getUserLogsRequest) );
            const queryParams = new URLSearchParams(getUserLogsRequest).toString();
            const endpoint = `/AuditLog/GetUserLogs?${queryParams}`;
            const response = await this.apiClient.get(endpoint);
            return response;
        }
        catch (error) {
            console.error('Error creating update Doc:', error);            
        }
    }
}