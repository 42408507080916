
import { useState,useEffect } from 'react';
import { ApiCompanyRepository } from '../../infrastructure/repositories/ApiCompanyRepository';
import { CompanyDataList } from '../UseCase/CompanyDataList';
import useApiClient from '../../infrastructure/apiClient';


export const useCompanyList = () => {
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();
  useEffect(() => {
    const getCompanyList = async () => {
      const companyRepository = new ApiCompanyRepository(apiClient);
      const companyDataListUseCase = new CompanyDataList(companyRepository);
      try {
        const result = await companyDataListUseCase.execute();
        
        setCompanyList(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    getCompanyList();
  },[]);
  
  return { companyList, loading, error };
};
export default useCompanyList;


 