// src/application/hooks/useAddCompany.js
import { useState,useEffect } from 'react';
import { ApiPendingRequestRepository } from '../../infrastructure/repositories/ApiPendingRequestRepository';
import { AddUser } from '../UseCase/AddUser';
import useApiClient from '../../infrastructure/apiClient';
import { ApproveRejectRequests } from '../UseCase/ApproveRejectRequests';


export const useApproveRejectRequests = () => {
  const [approveRejectRequest, setapproveRejectRequest] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();

  const approveRejectRequests = async (requests) => {
    const pendingRequestRepository = new ApiPendingRequestRepository(apiClient);
    const approveRejectRequestUseCase = new ApproveRejectRequests(pendingRequestRepository);

    try {
      setLoading(true);
      const result = await approveRejectRequestUseCase.execute(requests);
      console.log("Action Response", result);
      setapproveRejectRequest(result);
      
    } catch (err) {
      console.log("ApproveRejectError: ", err);
      setError(err.message);
    }
    finally{
      console.log("ApproveReject response set: ",approveRejectRequest);
      setLoading(false);
    }
  };
  return { approveRejectRequest, approveRejectRequests, loading };
};
export default useApproveRejectRequests;
