import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import { OidcProvider } from "@axa-fr/react-oidc";
import configuration from "./utils/oidc-config";
import RouterList from "./RouterList";
import LoadingMessage from "./utils/LoadingMessage";

function App() {
  const today = new Date();
  console.log("App Component Loaded.. at ", today);

  return (
    <OidcProvider
      configuration={configuration}
      authenticatingComponent={() => null}
      loadingComponent={LoadingMessage}
      callbackSuccessComponent={() => null}
    >
      <Router>
        <div>
          <Header />
          <RouterList />
        </div>
      </Router>
    </OidcProvider>
  );
}

export default App;
