import { PendingRequestRepository } from "../../domain/repositories/PendingRequestRepository";
export class ApiPendingRequestRepository extends PendingRequestRepository {
    constructor(apiClient)
    {
       super();
       this.apiClient=apiClient;
    }
    async GetPendingRequestList(popupData) {
        try 
        {
            const response = await this.apiClient.get('/PendingRequest/GetPendingRequestList?status='+popupData.status+'&searchBy='+popupData.searchBy+'&searchValue='+popupData.searchValue); 
            return response.data;
        }
        catch (error) {    
        console.log('ApiPendingRequestRepository error -', error);
        let errorMessage = "An unknown error occurred"; // Default error message
        if (error.response && error.response.data) {
          // Modify this based on actual structure
          errorMessage = error.response.data.message || error.response.data || errorMessage;
        }    
        // Throw the error message to be caught in the hook or service
        throw new Error(errorMessage);
        }
      }

      async approveRejectRequests(requests) {
        debugger;
        try 
        {
          let response;
          if(requests.action === "PermanentDelete" || requests.action === "Restore")
          {
            response = await this.apiClient.post("/Document/UpdateDocumentsStatus", requests);
          }
          else{
            response = await this.apiClient.post("/PendingRequest/ActionOnPendingRequest", requests);
          }

            return response;
        }
        catch (error) {    
        console.log('ApiPendingRequestRepository ApproveRejectRequests error -', error);
        let errorMessage = "An unknown error occurred"; // Default error message
        if (error.response && error.response.data) {
          // Modify this based on actual structure
          errorMessage = error.response.data.message || error.response.data || errorMessage;
        }    
        // Throw the error message to be caught in the hook or service
        throw new Error(errorMessage);
        }
      }
      async CreatePendingRequest(createPendingRequest) {
        try 
        {
            console.log('Calling ApiPendingRequestRepository - CreatePendingRequest : ' + JSON.stringify(createPendingRequest) );
    
        const response = await this.apiClient.post("/PendingRequest/CreatePendingRequest", createPendingRequest);
        return response.status;
        }
         catch (error) {
        console.error('Error creating createPendingRequest:', error);
    
        // Safely extract the error message from the API response
        let errorMessage = "An unknown error occurred"; // Default error message
        if (error.response && error.response.data) {
          // Modify this based on actual structure
          errorMessage = error.response.data.message || error.response.data || errorMessage;
        }
    
        // Throw the error message to be caught in the hook or service
        throw new Error(errorMessage);
        }
      }
    }
