import {UserRespository} from '../../domain/repositories/UserRepository';
export class ApiUserRepository extends UserRespository{
    constructor(userApiClient, apiClient){
        super();
        this.userApiClient = userApiClient;
        this.apiClient = apiClient;
    }
    async addUser(userInfo) {
        console.log('I am in usecompany companyrepo');
        console.log(userInfo);
        userInfo.role = userInfo.role === 'User (View only)' ? 'UserView' : userInfo.role
    
          try{  
                const saveUser = await this.apiClient.post("/User/SaveUser", userInfo);
                console.log(saveUser);
                return saveUser;      
           
          }catch(error){
            debugger;
                console.log('error', error);
          }
      }

    async getUserList(companyId){       
        if(companyId !== null && companyId !== undefined && !isNaN(companyId) && companyId !== 0){ 
        const response = await this.apiClient.get('/User/GetUserListByCompanyId?companyId='+ companyId); // Assuming the API endpoint is /users
        return response.data;
        }
    }
    async saveUser(userData){
        debugger;
        const response = await this.apiClient.post("/User/SaveUser", userData);
        return response.data;
    }

    async deleteUsers(users){
        debugger;
        const response = await this.apiClient.post("/User/DeleteUser", users);
        return response;
    }

    async getUser(userId){
        debugger;
        const response = await this.apiClient.get("/User/GetUserById?CompanyUserId="+ userId);
        return response;
    }

    async updateUser(user){
        debugger;
        const response = await this.apiClient.post("/User/UpdateUser", user);
        return response;
    }
    async GetUserDetails(UserEmail){       
        if(UserEmail !== null && UserEmail !== undefined && UserEmail!== ""){ 
        const response = await this.apiClient.get('/User/GetUserDetails?userEmail='+ UserEmail); // Assuming the API endpoint is /users
        console.log('ttt  response :'+JSON.stringify(response.data));
        return response.data;
        }
    }
    async changePassword(changePasswordrequest) {
        try 
        {
      
        console.log('Calling ApiUserRepository - changePassword : ' + JSON.stringify(changePasswordrequest));
        const response = await this.apiClient.post("/User/ChangePassword", changePasswordrequest);
       
        //console.log('changePassword  response :'+JSON.stringify(response));
       
         return { status: response.status, message: "" };
        }
         catch (error) {
           
            console.log('ApiUserRepository - changePassword Exception : ' + error);

            if (error.response) {
                switch (error.response.status) {
                  case 400:
                    return { status: "400", message:error.response.data}
                  default:
                    return { status: "error", message: error.message };
                }
            }
            else
            {                return { status: "error", message: error.message };
            }
            
        }
      }
}