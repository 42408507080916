export class CompanyRepository {
    getCompanyInfo() {
      throw new Error('getCompanyInfo method not implemented');
    }

    addCompany(CompanyInfo) {
      throw new Error('addUser method not implemented');
    }

    getCompanyList() {
      throw new Error('CompanyList method not implemented');
    }  
    
    getCompanyInformation(CompanyId)
    {
      throw new Error('getCompanyInformation method not implemented');
    }
    updateCompany(CompanyInfo) {
      throw new Error('updateCompany method not implemented');
    }
    updateCompanyStatus(UpdateCompanyStatusRequest){}
    getCompanyStatus(CompanyId) {}
   
    
  }