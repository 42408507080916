import { GetUserDetails } from "../UseCase/GetUserDetails";
import { useEffect, useState ,useCallback} from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import useApiClient from '../../infrastructure/apiClient';
import userApiClient from '../../infrastructure/userApiClient';

export const useGetUserDetails = (UserEmail) => {
    const [userDetail, setuserDetail] = useState([]);
    const [error, setError] = useState(null);
    const apiClient = useApiClient();
    
    const getUserDetail = useCallback(async () => {
        const userRepository = new ApiUserRepository(userApiClient,apiClient);
        const getUserListCase = new GetUserDetails(userRepository);
    
        try {
          console.log('I am in useGetUserList hook');
          const result = await getUserListCase.execute(UserEmail);
          setuserDetail(result);
        } catch (err) {
          setError(err.message);
        }
      }, [UserEmail,apiClient]);

    useEffect(() =>{ 
      if (UserEmail === undefined || UserEmail==="") {
        console.log("UserEmail not defined, No API Call here.");
      } else {
        getUserDetail();
      }
       
    },[getUserDetail,UserEmail])
    return { userDetail,error , refetch: getUserDetail};
  };
  export default useGetUserDetails;