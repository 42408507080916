import React, { useEffect } from "react";
import useUpdateIndexField from "../application/Hooks/useUpdateIndexField";
import { UpdateIndexFieldRequest } from "../domain/entities/UpdateIndexFieldRequest";
const EditIndexField = ({ value, onSaveField }) => {

  const field = value;
  console.log("field: " + JSON.stringify(field));
  const { status, UpdateIndexfield, error } = useUpdateIndexField();

  const [formData, setFormData] = React.useState({
    fieldName: field.fieldName,
    dataType: field.dataType,
    stringLength: field.stringLength,
    defaultValue: field.defaultValue,
    isRequired: field.isRequired,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const UpdateIndex = new UpdateIndexFieldRequest(
      field.fieldId,
      field.CompanyId,
      formData.fieldName,
      formData.dataType,
      formData.stringLength,
      formData.isRequired,
      formData.defaultValue
    );
    // Now you can use boundFields, for example, send it to an API or log it
    console.log("update Fields:", UpdateIndex);

    UpdateIndexfield(UpdateIndex);

    //onSaveField();
    // Save the changes (make an API call if necessary)
    // After saving, navigate back to the index field list page
    //navigate("/index-fields"); // Navigate back
  };
  useEffect(() => {
    if (status) {
      onSaveField(); // Send all fields to parent
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <div className="pull-left Txt_bold">Update Index Field</div>
          <div className="pull-right"></div>
        </div>

        <div className="col-md-12">
          <div className="grayBox mt-3">
            <div className="editIndexFieldPanal">
              <div className="div1">
                <label>Field Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.fieldName}
                   disabled="Disable"
                  //onChange={(e) => setFormData({ ...formData, fieldName: e.target.value })}
                />
              </div>
              <div className="div2">
                <label>Data Type</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.dataType}
                  disabled="Disable"
                 //onChange={(e) => setFormData({ ...formData, dataType: e.target.value })}
                />
              </div>
              <div className="div3">
                <label>Field Length</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData.stringLength}
                  onChange={(e) => setFormData({ ...formData, stringLength: e.target.value })}
                />
              </div>
              <div className="div4">
                <label>Default Value</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.defaultValue}
                  onChange={(e) => setFormData({ ...formData, defaultValue: e.target.value })}
                />
              </div>
              <div className="div5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={formData.isRequired}
                  onChange={(e) => setFormData({ ...formData, isRequired: e.target.checked })}
                />
                <label class="form-check-label">Required</label>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>










      <div className="clearfix"></div>


      <div><button type="submit" className="btn saveBtn mt-3 ">Save Changes</button></div>


    </form >
  );
};

export default EditIndexField;
