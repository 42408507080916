
import { useState,useEffect } from 'react';
import { ApiRetentionScheduleRepository } from "../../infrastructure/repositories/ApiRetentionScheduleRepository";
import { GetDeletedFiles } from '../UseCase/GetDeletedFiles';
import useApiClient from '../../infrastructure/apiClient';
import { get } from 'jquery';

export const useGetDeletedFiles = (companyId) => {
  const [deletedFilesList, setDeletedFilesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();


  const getDeletedFiles = async (companyId) => {    
    const retentionScheduleRepository = new ApiRetentionScheduleRepository(apiClient);
    const getDeletedFilesUseCase = new GetDeletedFiles(retentionScheduleRepository);
    try 
    {
      const result = await getDeletedFilesUseCase.execute(companyId);
      console.log("XYXD",result);
      if(result.status === 200){              
        setDeletedFilesList(result.data);
      }
      else{
        debugger;
        setDeletedFilesList([]);
      }
    } 
    catch (err) {
      setError(err.message);
    } 
    finally {
      setLoading(false);
    }
  };

  useEffect(() =>{
    if (companyId === undefined || isNaN(companyId)|| companyId===0) {
      console.log("CompanyId not defined, No API Call here.");
      setDeletedFilesList([]);
    } else {
      console.log("CompanyId-", companyId);
      getDeletedFiles(companyId);
    }
  },[companyId])
  
  return { getDeletedFiles ,deletedFilesList, loading, error };
};
export default useGetDeletedFiles;


 