export class GetDesignIndexFieldList{
    constructor(indexFieldRepository)
    {
        this.indexFieldRepository = indexFieldRepository;

    }
    async execute(companyId) {
        console.log('GetDesignIndexFieldList - companyId : '+ companyId)
      return await this.indexFieldRepository.getDesignIndexFieldList(companyId);
    }

}