import React from "react";
import { useCompanyList } from "../application/Hooks/useCompanyList";
import { useState } from "react";
import { json } from "react-router-dom";

const CompanyListDropDown = ({ value, onChange }) => {
  const [selectedCompany, setSelectedCompany] = useState(value);
  const { companyList, loading, error } = useCompanyList();
  try {
    const handleSelectedCompanyChange = (e) => {
      setSelectedCompany(e.target.value);
      onChange(e.target.value);
    };
    return (
      <div className="input-group" id="SelectCompantDropdown">
        <label className="input-group-text">Select Company</label>
        <select
          id="company-select"
          className="form-select"
          value={selectedCompany}
          onChange={handleSelectedCompanyChange}
        >
          <option value="0">-- Please select --</option>
          {companyList.companies.map((company) => (
            <option key={company.companyId} value={company.companyId}>
              {company.name}
            </option>
          ))}
        </select>
      </div>
    );
  } catch (err) {
    console.log("Error: ", err);
  }
};

export default CompanyListDropDown;
