export class IndexFieldRepository{
    getIndexFieldList(companyId) {
        throw new Error('addUser method not implemented');
      }
      CreateIndexField(Fields) {
        throw new Error('CreateIndexField method not implemented');
      }
      async UpdateIndexField(UpdateIndexFieldRequest) {
        throw new Error('UpdateIndexField method not implemented');
      }
      async DeleteIndexField(IndexFieldListIds) {
        throw new Error('DeleteIndexField method not implemented');
      }
}