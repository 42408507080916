import React from "react";
import { useState, useEffect } from "react";
import { useCompanyInformation } from "../application/Hooks/useCompanyInformation";
import { CompanyInfo } from "../domain/entities/CompanyInfo";
import useUpdateCompany from "../application/Hooks/useUpdateCompany";
import CompanyInfoNotFound from "./CompanyInfoNotFound";
import CustomAlert from "./CustomAlert ";
import { useNavigate } from "react-router-dom";

const CompanyInformation = ({
  value: CompId,
  TabFunction,
  CompStatusFunction,
  IsDisable: IsDisablevalue,
}) => {
  console.log("CompanyId", CompId);
  const [isLoading, setIsLoading] = useState(false);
  const [IsShowCompanyIfo, setIsShowCompanyIfo] = useState(false);
  const { CompanyData, loading, error } = useCompanyInformation(CompId);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  console.log("[component]-CompanyInformation :" + JSON.stringify(CompanyData));
  const [logo, setLogo] = useState(null);

  const [unit, setUnit] = useState("GB"); // Default value is GB
  const { companyId, updateCompany, error1 } = useUpdateCompany();

  const [newUpdateCompany, setNewUpdateCompany] = useState({
    companyId: 0,
    name: "",
    phonenumber: "",
    storage: 0,
    logoFileData: null,
  });

  useEffect(() => {
    if (
      CompId === undefined ||
      CompId === 0 ||
      CompId === "" ||
      isNaN(CompId)
    ) {
      setIsShowCompanyIfo(false);
    } else {
      setIsShowCompanyIfo(true);

      if (CompanyData) CompStatusFunction(CompanyData.status);
      else CompStatusFunction("No Status");

      const fetchLogo = async () => {
        if (CompanyData !== undefined && CompanyData != null) {
          console.log("Logo Path: " + CompanyData.logoPath);

          try {
            // Remove no-cors to allow a proper fetch if the server has CORS enabled
            setLogo(CompanyData.logoPath);
            const response = await fetch(CompanyData.logoPath, {
              mode: "no-cors",
            });

            const blob = await response.blob();
            const fileName = "logo.jpg"; // You can customize this as needed

            // Convert the blob to a File object
            const file = new File([blob], fileName, {
              type: blob.type,
            });

            // Update the company info
            setNewUpdateCompany({
              companyId: CompanyData.compnayId,
              name: CompanyData.name,
              phonenumber: CompanyData.phoneNumber,
              storage: CompanyData.storage,
              logoFileData: file,
            });
          } catch (error) {
            console.error("Error fetching the logo:", error);
          }
        } else setIsShowCompanyIfo(false);
      };

      fetchLogo(); // Call the async function
    }
  }, [CompanyData, CompId]);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Only JPEG, JPG, and PNG files are allowed.");
        setLogo(null);
        setNewUpdateCompany({ ...newUpdateCompany, logoFileData: null }); // Clear logo file data
      } else {
        setLogo(URL.createObjectURL(file)); // Preview the logo
        setNewUpdateCompany({ ...newUpdateCompany, logoFileData: file }); // Save file in state
      }
    }
  };

  const handleSelectChange = (event) => {
    setUnit(event.target.value); // Update state when user selects a new option
  };

  // Function to handle the save operation
  const handleSubmit = async (e) => {
    console.log("Start update company...");
    e.preventDefault();

    setIsLoading(true);
    try {
      const company = new CompanyInfo(
        newUpdateCompany.name,
        newUpdateCompany.phonenumber,
        newUpdateCompany.storage,
        newUpdateCompany.logoFileData, // This is where the file gets passed
        newUpdateCompany.companyId
      );
      if (unit === "TB") company.storage = company.storage * 1024;
      console.log("I am in update code " + JSON.stringify(company));
      await updateCompany(company);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    if (companyId) {
      setIsLoading(false); // Hide loader and enable button
      //alert("Company updated successfully");
      //TabFunction("tabIndexFields");
      setIsAlertOpen(true); // Open custom alert after saving
    }
  }, [companyId]);

  const handleLater = () => {
    console.log("Later!");
    // Handle Later action (e.g., navigate to another page)
    TabFunction("tabIndexFields");
  };

  const handleYes = () => {
    console.log("Yes!");
    // Handle Yes action
    navigate("/UserPage");
  };
  const navigate = useNavigate();
  return (
    <div>
      {!IsShowCompanyIfo ? (
        <CompanyInfoNotFound />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      <div className="Txt_bold mb-3">Company Details</div>
                    </div>

                    <div className="clearfix"></div>
                    <hr></hr>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="company_logo_wrp">
                      {logo ? (
                        <img
                          src={logo}
                          alt="Company Logo"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <i
                          className="fa fa-file-image-o"
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    <div className="uploadLogoBtn">
                      {!IsDisablevalue && (
                        <label htmlFor="uploadLogo" className="uploadLogoText">
                          <i
                            className="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                          Edit
                        </label>
                      )}

                      <input
                        type="file"
                        id="uploadLogo"
                        className="d-none"
                        onChange={handleLogoUpload}
                        accept=".jpg, .png,.jpeg"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>Company Name</label>
                          <input
                            type="text"
                            value={newUpdateCompany.name}
                            id="lblCompanyName"
                            className="form-control"
                            placeholder="Enter company name"
                            onChange={(e) =>
                              setNewUpdateCompany({
                                ...newUpdateCompany,
                                name: e.target.value,
                              })
                            }
                            disabled="Disable"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>Company Phone Number</label>
                          <input
                            type="text"
                            value={newUpdateCompany.phonenumber}
                            required={true}
                            minLength={10}
                            maxLength={15}
                            id="lblCompanyPhoneNumber"
                            className="form-control"
                            disabled={IsDisablevalue}
                            onChange={(e) =>
                              setNewUpdateCompany({
                                ...newUpdateCompany,
                                phonenumber: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label>Storage Capacity</label>
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            value={newUpdateCompany.storage}
                            min={1}
                            required={true}
                            id="lblStorageCapacity"
                            className="form-control"
                            disabled={IsDisablevalue}
                            onChange={(e) =>
                              setNewUpdateCompany({
                                ...newUpdateCompany,
                                storage: e.target.value,
                              })
                            }
                          />

                          <select
                            className="form-select"
                            style={{ marginLeft: "20px" }}
                            value={unit} // Bind state to select value
                            disabled={IsDisablevalue}
                            onChange={handleSelectChange} // Handle change event
                          >
                            <option>GB</option>
                            <option>TB</option>
                          </select>
                        </div>
                      </div>

                      <div>
                        {!IsDisablevalue && (
                          <button
                            type="submit"
                            className="updateBtn"
                            disabled={isLoading}
                          >
                            {isLoading ? "Submitting..." : "Update"}
                          </button>
                        )}

                        {/* {isLoading && <div className="loader">Processing...</div>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      <CustomAlert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        title="Company profile updated successfully."
        message="Do you want to add users?"
        onLater={handleLater}
        onYes={handleYes}
      />
    </div>
  );
};
export default CompanyInformation;
