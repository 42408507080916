
import { useState } from 'react';
import { ApiIndexFieldRepository } from '../../infrastructure/repositories/ApiIndexFieldRepository';
import { DeleteIndexField } from '../UseCase/DeleteIndexField'; 
import useApiClient from '../../infrastructure/apiClient';

export const useDeleteIndexFields = () => {
    const[status,setstatus]=useState(false);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const DeleteIndexfield = async (IndexFieldListIds) => {

    const IndexfieldRepository = new ApiIndexFieldRepository(apiClient);
    const DeleteIndexfieldUseCase = new DeleteIndexField(IndexfieldRepository);

    try {
      console.log('I am in useDeleteIndexFields hook '+JSON.stringify(IndexFieldListIds));
      const result = await DeleteIndexfieldUseCase.execute(IndexFieldListIds);
      setstatus(result);
      console.log('delete index field response :'+result);
    } catch (err) {
      setError(err.message);
    }
  };

  return {status, DeleteIndexfield,error };
};
export default useDeleteIndexFields;
