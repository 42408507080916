import { useEffect, useState } from 'react';
import { ApiPendingRequestRepository } from '../../infrastructure/repositories/ApiPendingRequestRepository';
import { CreatePendingRequests } from '../UseCase/CreatePendingRequests';
import useApiClient from '../../infrastructure/apiClient';

export const useCreatePendingRequests = () => {
  
  const[pendingRequestResponse, setPendingRequestResponse]=useState([]);
  const [error1, setError] = useState(null);
  const apiClient = useApiClient();

  const EmailRequestList = async (createPendingRequest) => {
    debugger;

    const pendingRequestRepository = new ApiPendingRequestRepository(apiClient);
    const EmailRequestUseCase = new CreatePendingRequests(pendingRequestRepository);

    try {
      
      console.log('I am in useCreatePendingRequests hook ');
      const result = await EmailRequestUseCase.execute(createPendingRequest);
      setPendingRequestResponse(result);
      console.log('useCreatePendingRequests response :'+ JSON.stringify(result));
    } catch (err) {
       console.log('useCreatePendingRequests response err :'+ err.message);
      setError(err.message);
    }
  };
  return {pendingRequestResponse, EmailRequestList, error1,setError };
};
export default useCreatePendingRequests;