import { useOidcUser } from "@axa-fr/react-oidc";
import { Navigate } from "react-router-dom";

const RoleBasedSecure = ({ roles, children }) => {
  const { oidcUser } = useOidcUser();
  const userRoles = oidcUser?.role || [];
  const hasRequiredRole = roles.some((role) => userRoles.includes(role));

  // Only render the child components (tabs) if the user has the required role
  return hasRequiredRole ? <>{children}</> : null;
};

export default RoleBasedSecure;
