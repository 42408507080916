import React from "react";
import { useState, useEffect } from "react";
import CompanyListDropDown from "../components/CompanyListDropDown";
import { useLocation } from "react-router-dom";
import useGetUserDetails from "../application/Hooks/useGetUserDetails";
import useGetCompanyStatus from "../application/Hooks/useGetCompanyStatus";
import UserLogs from "../components/UserLogs";
import UserProductivity from "../components/UserProductivity";
import { useOidcUser } from "@axa-fr/react-oidc";

const AuditLogs = () => {
  const { oidcUser } = useOidcUser();
  // State to track active tab
  const [activeTab, setActiveTab] = useState("userLogs");
  const location = useLocation();
  const { companyId } = location.state || {}; // Get companyId from location state
  const [selectedCompanyIdValue, setSelectedCompanyIdValue] =
    useState(companyId);

  const [isDisabled, setisDisabled] = useState(false);
  const { APICompanyStatus, loading, error1 } = useGetCompanyStatus(
    selectedCompanyIdValue
  );

  const {
    userDetail,
    error,
    refetch: getUserDetail,
  } = useGetUserDetails(
    oidcUser !== null && oidcUser !== undefined ? oidcUser.email : ""
  );

  const [auditLogRequest, setAuditLogRequest] = useState({
    companyId: selectedCompanyIdValue,
    userName: "",
    fromDate: "",
    toDate: "",
    operation: "",
  });

  const handleDropdownChange = (value) => {
    setSelectedCompanyIdValue(value);
    setAuditLogRequest({
      ...auditLogRequest,
      companyId: parseInt(value),
    });
  };

  useEffect(() => {
    if (APICompanyStatus) {
      if (APICompanyStatus === "Deactivated") setisDisabled(true);
      else setisDisabled(false);
    }

    if (
      userDetail !== null &&
      userDetail !== undefined &&
      userDetail.compnayId !== 0 &&
      oidcUser.role === "Admin"
    ) {
      setSelectedCompanyIdValue(userDetail.compnayId);
    }
  }, [userDetail, APICompanyStatus]);

  // Function to handle tab switching
  const openTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="page_title">Audit Logs</div>
            <div className="tab_wrp">
              <div className="col-md-4">
                <div className="Select_Company">
                  {oidcUser && oidcUser.role === "SuperAdmin" ? (
                    <div className="input-group">
                      <CompanyListDropDown onChange={handleDropdownChange} />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 manageCompanyWrp">
                  <div className="clearfix"></div>
                </div>
              </div>

              <div>
                <hr></hr>
              </div>

              {/* Tabs */}
              <div className="Tab_button_wrp">
                <button
                  className={activeTab === "userLogs" ? "tabActiveBtn" : ""}
                  onClick={() => openTab("userLogs")}
                >
                  User Logs
                </button>
                <button
                  className={
                    activeTab === "userProductivity" ? "tabActiveBtn" : ""
                  }
                  onClick={() => openTab("userProductivity")}
                >
                  User Productivity
                </button>
              </div>

              {/* Tab Content */}
              <div className="tab_box">
                {activeTab === "userLogs" && (
                  <UserLogs
                    companyId={selectedCompanyIdValue}
                    setAuditLogRequest={setAuditLogRequest}
                    auditLogRequest={auditLogRequest}
                  ></UserLogs>
                )}

                {activeTab === "userProductivity" && (
                  <UserProductivity
                    companyId={selectedCompanyIdValue}
                    setAuditLogRequest={setAuditLogRequest}
                    auditLogRequest={auditLogRequest}
                  ></UserProductivity>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuditLogs;
