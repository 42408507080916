import React, { useState } from "react";
import useGetUserList from "../application/Hooks/useGetUserList";
import useGetAuditLog from "../application/Hooks/useGetAuditLog";
import { Table, Form, Pagination, Button } from "react-bootstrap";
import { format } from "date-fns";

const UserProductivity = ({
  companyId,
  auditLogRequest,
  setAuditLogRequest,
}) => {
  const { userList, getUserList, error } = useGetUserList(companyId);
  const { getAuditLogs, auditLogs } = useGetAuditLog(auditLogRequest);
  const [userName, setUserName] = useState("0");
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [fieldsPerPage, setFieldsPerPage] = useState(10); // Default number of fields per page

  const handleUserChange = (e) => {
    debugger;
    setAuditLogRequest({ ...auditLogRequest, userName: e.target.value });
    setUserName(e.target.value);
  };
  const getUserLogs = async (e) => {
    debugger;
    console.log("UserLogs", auditLogRequest);
    //setAuditLogRequest({ ...auditLogRequest, userName: userName });
    e.preventDefault();
    await getAuditLogs(auditLogRequest, "", userName);
    //openTab("userLogsList");
  };

  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  const paginatedData =
    auditLogs?.slice(indexOfFirstField, indexOfLastField) || [];

  // Calculate the "Showing x to y of z entries" message

  const start = Math.min(indexOfFirstField + 1, auditLogs.length);
  const end = Math.min(indexOfLastField, auditLogs.length);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div id="userProductivity">
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Select User</label>
                <select
                  id="company-select"
                  class="form-select"
                  value={userName}
                  onChange={handleUserChange}
                >
                  <option value="0">-- Please select --</option>
                  {userList.map((user) => (
                    <option key={user.userEmail} value={user.userEmail}>
                      {user.userEmail}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <button
                type="submit"
                class="btn saveBtn"
                style={{ marginTop: "23px" }}
                onClick={getUserLogs}
                disabled={userName === "0"}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <hr></hr>
        </div>
        <div className="col-md-12">
          <div className="deletePageAddWrp">
            <div className="div1">
              <Form.Group controlId="fieldsPerPageSelect">
                <Form.Label>Show</Form.Label>
                <Form.Control
                  as="select"
                  value={fieldsPerPage}
                  onChange={(e) => setFieldsPerPage(Number(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="tableScroll">
            <Table className="table table-bordered blueTbl">
              <thead>
                <tr>
                  <th>User Name </th>
                  <th>Date Added </th>
                  <th>Document Title</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((auditLog, index) => (
                    <tr key={index}>
                      <td>{auditLog.userName} </td>
                      <td>
                        {auditLog.createdDate
                          ? format(
                              new Date(auditLog.createdDate),
                              "dd-MM-yyyy HH:mm:ss"
                            )
                          : ""}
                      </td>
                      <td>{auditLog.documentTitle}</td>
                      <td>{auditLog.operation}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No fields found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="col-md-12">
          <div className="float-start">
            Showing <strong>{start}</strong> to <strong>{end}</strong> of{" "}
            <strong>{auditLogs.length}</strong> entries
          </div>
          <div className="float-end">
            <Pagination>
              <Pagination.Prev
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  !auditLogs ||
                  auditLogs.length === 0 ||
                  currentPage === Math.ceil(auditLogs.length / fieldsPerPage)
                }
              />
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProductivity;
