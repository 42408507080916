import { GetUserList } from "../UseCase/GetUserList";
import { useEffect, useState } from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import useApiClient from '../../infrastructure/apiClient';
import userApiClient from '../../infrastructure/userApiClient';
import { GetUser } from "../UseCase/GetUser";

export const useGetUser = (companyUserId) => {
    const [user, setUser] = useState({
        companyId:0,
        createdBy:null,createdDate: "",
        firstName:"",
        id:0,
        lastName:"",
        phoneNumber:"",
        role:"",userEmail:""
    });
    const [error, setError] = useState(null);
    const apiClient = useApiClient();
    
    const getUser = async (companyUserId) => {
        const userRepository = new ApiUserRepository(userApiClient,apiClient);
        const getUserCase = new GetUser(userRepository);
    
        try {
          console.log('I am in useGetUser hook');
          const result = await getUserCase.execute(companyUserId);
          setUser(result.data);
        } catch (err) {
          setError(err.message);
        }
      };
    useEffect(() =>{
        debugger;
        if (companyUserId === undefined ||companyUserId==="" ||isNaN(companyUserId)) {
          console.log("companyUserId not defined, No API Call here.");
        } else {
          getUser(companyUserId);
        }
        ;
    },[companyUserId])
    return { user,setUser, error };
  };
  export default useGetUser;