import { CompanyRepository } from '../../domain/repositories/CompanyRepository';
import { CompanyInfo } from '../../domain/entities/CompanyInfo';
import { CompanyList } from '../../domain/entities/CompanyList';
import { CompanyData }  from '../../domain/entities/CompanyData';
import { json } from 'react-router-dom';

export class ApiCompanyRepository extends CompanyRepository {
  constructor(apiClient) {
    super();
    this.apiClient = apiClient;
  }

  async getCompanyInfo() {
    const response = await this.apiClient.get('/CompanyInfo'); 
    return response.data.map(company => new CompanyInfo(company.name, company.phonenumber, company.storage));
  }

  async addCompany(CompanyInfo) {
    try
    {
    console.log('I am in usecompany companyrepo');
    const formData = new FormData();
    formData.append("Name", CompanyInfo.name);
    formData.append("PhoneNumber", CompanyInfo.phonenumber);
    formData.append("Storage", CompanyInfo.storage);
    formData.append("LogoFileData", CompanyInfo.LogoFileData);

    // const response = await this.apiClient.post('/Company/CreateCompany', {
    //   formData
    // });
    for (let [key, value] of formData.entries()) {
      console.log(key, value); // You should see your file as a Blob for LogoFileData
    }

    const response = await this.apiClient.post("/Company/CreateCompany", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // params: {
      //   Name: CompanyInfo.name,
      //   PhoneNumber: CompanyInfo.phonenumber,
      //   Storage:CompanyInfo.storage
      // },
    });
    console.log('company add repo '+JSON.stringify(response));
    const companyId = response.data;
    return companyId;
  }
  catch(error)
  {
    // console.log('company add repo '+JSON.stringify(error));
    // console.error('Error creating company:', error.response?.data || error.message);
    console.error('Error creating company:', error);

    // Safely extract the error message from the API response
    let errorMessage = "An unknown error occurred"; // Default error message
    if (error.response && error.response.data) {
      // Modify this based on actual structure
      errorMessage = error.response.data.message || error.response.data || errorMessage;
    }

    // Throw the error message to be caught in the hook or service
    throw new Error(errorMessage);
  }
  }
  async getCompanyList() {
    try
    {
    const response = await this.apiClient.get('/Company/GetCompanyList'); // Assuming the API endpoint is /users

    return new CompanyList(response.data);
    }
    catch(err){
      console.log(err);
    }
  }

  async getCompanyInformation(CompanyId) {
    try {
      console.log('I am in api ApiCompanyRepository - getCompanyInformation - CompanyID : ', + CompanyId );
   
      const response = await this.apiClient.get('/Company/GetCompanyDetails?companyId='+ CompanyId); // Assuming the API endpoint is /users
      console.log('ApiCompanyRepository - getCompanyInformation'+JSON.stringify(response.data));
      return  new CompanyData(response.data); 
    } 
    catch (error) {
      console.log('ApiCompanyRepository - getCompanyInformation Exception : ' + error);
    }     
    
  }
  async getCompanyStatus(CompanyId) {
    try {
      console.log('I am in api ApiCompanyRepository - getCompanyStatus - CompanyID : ', + CompanyId );
   
      const response = await this.apiClient.get('/Company/GetCompanyStatus?companyId='+ CompanyId); // Assuming the API endpoint is /users
      console.log('ApiCompanyRepository - getCompanyStatus'+JSON.stringify(response.data));
      return  response.data; 
    } 
    catch (error) {
      console.log('ApiCompanyRepository - getCompanyStatus Exception : ' + error);
    }     
    
  }
  async updateCompany(CompanyInfo) {
    console.log('I am in ApiCompanyRepository for updateCompany');
    const formData = new FormData();
    formData.append("CompanyId", CompanyInfo.companyId);
    formData.append("Name", CompanyInfo.name);
    formData.append("PhoneNumber", CompanyInfo.phonenumber);
    formData.append("Storage", CompanyInfo.storage);
    formData.append("LogoFileData", CompanyInfo.LogoFileData);

    for (let [key, value] of formData.entries()) {
      console.log(key, value); // You should see your file as a Blob for LogoFileData
    }

    const response = await this.apiClient.post("/Company/UpdateCompany", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },      
    });

    const companyId = response.data;
    console.log('Company updated successfully');
    return companyId;
  }
  async updateCompanyStatus(UpdateCompanyStatusRequest) {
    try
    {
    console.log('I am in ApiCompanyRepository for updateCompanyStatus');
    const response = await this.apiClient.post("/Company/UpdateCompanyStatus", UpdateCompanyStatusRequest);

    const companyId = response.data;
    console.log('Company Status updated successfully');
    return companyId;
  }
  catch(error)
  {
    console.error('Error update company status:', error);
    let errorMessage = "An unknown error occurred"; // Default error message
    if (error.response && error.response.data) {
      // Modify this based on actual structure
      errorMessage = error.response.data.message || error.response.data || errorMessage;
    }
     // Throw the error message to be caught in the hook or service
    throw new Error(errorMessage);
  }
}

}