import React from 'react';

const Unauthorized = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
      <p>User is <b>Unauthorized</b> to access this Page</p>
    </div>
  );
};

export default Unauthorized;