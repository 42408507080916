// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import { AddUser } from '../UseCase/AddUser';
import userApiClient from '../../infrastructure/userApiClient';
import useApiClient from '../../infrastructure/apiClient';


export const useAddUser = () => {
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();

  const addUser = async (newUser) => {
    const userRepository = new ApiUserRepository(userApiClient,apiClient);
    const addUserUseCase = new AddUser(userRepository);

    try {
      debugger;
      setLoading(true);      
      console.log(loading);
      console.log('I am in usecompany hook');
      const result = await addUserUseCase.execute(newUser);
      setUserId(result);
    } catch (err) {
      setError(err.message);
    }
    finally{
      debugger;
      setLoading(false);
      console.log(loading);
    }
  };

  return { userId, addUser, error, loading };
};
export default useAddUser;
