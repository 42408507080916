import { useState, useEffect, useCallback } from "react"; 
import { ApiIndexFieldRepository } from "../../infrastructure/repositories/ApiIndexFieldRepository";
import { GetIndexFieldList } from "../UseCase/GetIndexFieldList";
import useApiClient from "../../infrastructure/apiClient";

export const useIndexFieldList = (companyId) => {
  const [indexFieldList, setIndexFieldList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  console.log('useIndexFieldList  Request : ' + companyId);

  // Extract the function to fetch the data, and make it reusable
  const getIndexFieldList = useCallback(async () => {
    const indexFieldRepository = new ApiIndexFieldRepository(apiClient);
    const getIndexFieldUseCase = new GetIndexFieldList(indexFieldRepository);

    try {
      setLoading(true); // Start loading when the fetch is triggered
      console.log('I am in useIndexFieldList hook');
      const res = await getIndexFieldUseCase.execute(companyId);
      console.log('useIndexFieldList  Response : ' + JSON.stringify(res));
      setIndexFieldList(res);
      setError(null); // Clear any previous errors if the request is successful
    } catch (err) {
      console.log('useIndexFieldList  Exception : ' + err);
      setError(err.message);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  }, [companyId,apiClient]);

  // Call the `getIndexFieldList` function inside `useEffect` to fetch the data when the component mounts or `companyId` changes
  useEffect(() => {
    if (companyId === undefined || isNaN(companyId) || companyId===0) {
      console.log("CompanyId not defined, No API Call here.");
    } else {
      getIndexFieldList();
    }
   
  }, [getIndexFieldList,companyId]);

  // Return the state and the refetch function to trigger a manual reload
  return { indexFieldList, loading, error, refetch: getIndexFieldList };
};

export default useIndexFieldList;
