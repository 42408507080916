import React from "react";
import { useState } from "react";
import { Table, Form, Pagination, Button } from "react-bootstrap";
import { format } from "date-fns";

const UserLogsList = ({ auditLogs }) => {
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [fieldsPerPage, setFieldsPerPage] = useState(10); // Default number of fields per page

  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  const paginatedData =
    auditLogs?.slice(indexOfFirstField, indexOfLastField) || [];

  // Calculate the "Showing x to y of z entries" message

  const start = Math.min(indexOfFirstField + 1, auditLogs.length);
  const end = Math.min(indexOfLastField, auditLogs.length);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <hr></hr>
      </div>
      <div className="col-md-12">
        <div className="deletePageAddWrp">
          <div className="div1">
            <Form.Group controlId="fieldsPerPageSelect">
              <Form.Label>Show</Form.Label>
              <Form.Control
                as="select"
                value={fieldsPerPage}
                onChange={(e) => setFieldsPerPage(Number(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="pendingReqstTbl">
          <Table className="table pendingRqstTbl">
            <thead>
              <tr>
                <th>First Name </th>
                <th>Last Name</th>
                <th>User Name</th>
                <th>Date Accessed</th>
                <th>Document Title</th>
                <th>Document Location</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData && paginatedData.length > 0 ? (
                paginatedData.map((auditLog, index) => (
                  <tr key={index}>
                    <td>{auditLog.firstName} </td>
                    <td>{auditLog.lastName}</td>
                    <td>{auditLog.userName}</td>
                    <td>
                      {auditLog.createdDate
                        ? format(
                          new Date(auditLog.createdDate),
                          "dd-MM-yyyy HH:mm:ss"
                        )
                        : ""}
                    </td>
                    <td>{auditLog.documentTitle}</td>
                    <td>{auditLog.documentLocation}</td>
                    <td>{auditLog.operation}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No fields found.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="col-md-12">
        <div className="float-start">
          Showing <strong>{start}</strong> to <strong>{end}</strong> of{" "}
          <strong>{auditLogs.length}</strong> entries
        </div>
        <div className="float-end">
          <Pagination>
            <Pagination.Prev
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            />
            <Pagination.Item active>{currentPage}</Pagination.Item>
            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={
                !auditLogs ||
                auditLogs.length === 0 ||
                currentPage === Math.ceil(auditLogs.length / fieldsPerPage)
              }
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default UserLogsList;
