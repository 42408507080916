import { useState, useEffect } from "react";
import { ApiCompanyRepository } from "../../infrastructure/repositories/ApiCompanyRepository";
import { CompanyInformation } from "../UseCase/CompanyInformation";
import useApiClient from "../../infrastructure/apiClient";

export const useCompanyInformation = (companyId) => {
  const [CompanyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();


  console.log("useCompanyInformation  Request : " + companyId);
  useEffect(() => {
    const getCompanyInformation = async () => {
      const companyRepository = new ApiCompanyRepository(apiClient);
      const companyInformationUseCase = new CompanyInformation(
        companyRepository
      );
      try {
        const res = await companyInformationUseCase.execute(companyId);
        setCompanyData(res);
        console.log("useCompanyInformation  Response : " + JSON.stringify(res));
      } catch (err) {
        console.log("useCompanyInformation  Exception : " + err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (companyId === undefined || isNaN(companyId) || companyId===0) {
      console.log("CompanyId not defined, No API Call here.");
    } else {
      getCompanyInformation();
    }
  }, [companyId]);

  return { CompanyData, loading, error };
};

export default useCompanyInformation;
