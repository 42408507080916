import { GetUserList } from "../UseCase/GetUserList";
import { useEffect, useState } from 'react';
import { ApiUserRepository } from '../../infrastructure/repositories/ApiUserRepository';
import useApiClient from '../../infrastructure/apiClient';
import userApiClient from '../../infrastructure/userApiClient';

export const useGetUserList = (companyId) => {
    const [userList, setUserList] = useState([]);
    const [error, setError] = useState(null);
    const apiClient = useApiClient();
    
    const getUserList = async (companyId) => {
        const userRepository = new ApiUserRepository(userApiClient,apiClient);
        const getUserListCase = new GetUserList(userRepository);
    
        try {
          console.log('I am in useGetUserList hook');
          const result = await getUserListCase.execute(companyId);
          setUserList(result);
        } catch (err) {
          setError(err.message);
        }
      };
    useEffect(() =>{
      if (companyId === undefined || isNaN(companyId)|| companyId===0) {
        console.log("CompanyId not defined, No API Call here.");
      } else {
        getUserList(companyId);
      }
    },[companyId])
    return { userList,getUserList, error };
  };
  export default useGetUserList;