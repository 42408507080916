import { useState } from 'react';
import { ApiDocumentRepository } from '../../infrastructure/repositories/ApiDocumentRepository';
import { UploadFile } from '../UseCase/UploadFile';
import useApiClient from "../../infrastructure/apiClient";

export const useUploadFile = () => {
    const [uplaodResponse, setUplaodResponse] = useState(null);
    const [error, setError] = useState(null);
    const apiClient = useApiClient();

    const uploadFile = async (uploadrequest) => {
        const documentRepository = new ApiDocumentRepository(apiClient);
        const uploadFileUseCase = new UploadFile(documentRepository);
    
        try {
          console.log('I am in useuploadFile hook');
          const result = await uploadFileUseCase.execute(uploadrequest);
          setUplaodResponse(result);
          console.log('I am in useuploadFile hook response : ' + result);
        } catch (err) {
          setError(err.message);
        }
      };
    
      return { uplaodResponse, uploadFile, error };

};
export default useUploadFile;