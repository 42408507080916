export class PendingRequestRepository{    
    GetPendingRequestList(popupData) {
        throw new Error('GetPendingRequestList method not implemented');
      }
      approveRejectRequests() {
        throw new Error('ApproveRejectRequests method not implemented');
      }
      async CreatePendingRequest(createPendingRequest) {
        throw new Error('CreatePendingRequest method not implemented');
      }
      
}