import React, { useEffect, useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useGetDesignIndexFieldList } from "../application/Hooks/useGetDesignIndexFieldList";
import { useSearchDocument } from "../application/Hooks/useSearchDocument";
import { useNavigate } from "react-router-dom";
import CustomAlertV2 from "./CustomAlertV2";

const SearchDocument = ({ CompanyId: companyId }) => {
  const navigate = useNavigate();
  const [ErrorMsg, setErrorMsg] = useState(null);
  const { documentResponse, searchDocument, error1, setError } =
    useSearchDocument();
  const [successMessage, setSuccessMessage] = useState(null);
  const [indexFields, setIndexFields] = useState([]);
  const { designIndexFieldList, loading, error, refetch } =
    useGetDesignIndexFieldList(companyId); // assuming refetch is available from your hook

  const [fields, setFields] = useState([
    {
      indexField: "Select",
      operator: "Select",
      searchValue: "",
      booleanValue: "And",
      Datatype: "",
    },
  ]);
  const [orderBy, setOrderBy] = useState("Select");
  const [orderDirection, setOrderDirection] = useState("Ascending");
  const [tableName, setTableName] = useState("");
  const [SearchCodition, setSearchCodition] = useState(null);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    if (name === "indexField") {
      const selectedField = indexFields.find((f) => f.columnName === value);

      updatedFields[index].Datatype = selectedField
        ? selectedField.columnDataType
        : "";
    }

    setFields(updatedFields);
  };

  // Handle changes for "Order By" and "Order Direction"
  const handleOrderByChange = (e) => setOrderBy(e.target.value);
  const handleOrderDirectionChange = (e) => setOrderDirection(e.target.value);

  // Add new field row
  const handleAddRow = () => {
    setFields([
      ...fields,
      {
        indexField: "",
        operator: "",
        searchValue: "",
        booleanValue: "",
      },
    ]);
  };

  // Remove a field row
  const handleRemoveRow = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  // Handle form submit
  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();
    if (tableName === "") {
      setErrorMsg("Please select the Company");
      return;
    }
    // Validation check
    // console.log("fields" + JSON.stringify(fields));
    const hasError = fields.some(
      (field) =>
        field.indexField === "Select" ||
        field.indexField === "" ||
        field.booleanValue === "Select" ||
        field.searchValue === ""
    );

    if (hasError) {
      setErrorMsg(
        "All Index Field, Operator and Search Values are mandatory. Please ensure no fields are left or empty."
      );
      return; // Exit function if validation fails
    } else {
      setErrorMsg(null); // Clear any previous errors if validation passes
    }

    // Transform the fields into WhereConditions
    const whereConditions = fields.map((field) => ({
      ColumnName: field.indexField,
      ColumnDataType: field.Datatype,
      ConditionalOperator: field.booleanValue,
      Value: field.searchValue,
      AssignmentOperator: field.operator,
    }));

    // Prepare the OrderByComponent
    const orderByComponent =
      orderBy === "Select"
        ? null
        : {
          ColumnName: orderBy,
          IsDesending: orderDirection === "true" ? true : false,
        };

    // Final transformed data
    const finalSearchReq = {
      CompanyTableName: tableName,
      WhereConditions: whereConditions,
      OrderByComponent: orderByComponent,
    };
    setSearchCodition(finalSearchReq);
    // Log the final data (or handle it as needed)
    console.log("Submitted Data: ", JSON.stringify(finalSearchReq));
    searchDocument(finalSearchReq);
  };

  useEffect(() => {
    if (documentResponse) {
      console.log("document Response  : ", JSON.stringify(documentResponse));
      navigate("/SearchResult", {
        state: { documentResponse, SearchCodition },
      });
    }
    if (error1 !== "" && error1 != null) {
      console.log("errrrr " + error1);
      setErrorMsg(error1);
    }
  }, [setErrorMsg, documentResponse, error1, navigate, SearchCodition]);

  useEffect(() => {
    const fetchData = () => {
      debugger;
      if (
        designIndexFieldList &&
        designIndexFieldList.indexFields &&
        designIndexFieldList.indexFields.length > 0
      ) {
        setTableName(designIndexFieldList.companytablename);
        const formattedFields = designIndexFieldList.indexFields.map(
          (field) => ({
            columnName: field.columnName,
            columnDataType: field.columnDataType,
          })
        );
        console.log("Search Index : " + JSON.stringify(formattedFields));
        setIndexFields(formattedFields);
      }
    };
    // Call fetchData inside useEffect
    fetchData();
  }, [designIndexFieldList]);
  const handleDisplayAll = () => {
    if (tableName === "") {
      setErrorMsg("Please select the Company");
      return;
    }
    const finalSearchReq = {
      CompanyTableName: tableName,
      WhereConditions: null,
      OrderByComponent: null,
    };
    setSearchCodition(finalSearchReq);
    // Log the final data (or handle it as needed)
    console.log(
      "Submitted Data for display all: ",
      JSON.stringify(finalSearchReq)
    );
    searchDocument(finalSearchReq);
  };
  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Button
            type="submit"
            onClick={handleDisplayAll}
            className="btn displayAllBtn pull-right"
          >
            Display All
          </Button>
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        {ErrorMsg && (
          <CustomAlertV2
            message={ErrorMsg}
            onClose={() => setErrorMsg(null)}
            onConfirm={() => setErrorMsg(null)}
            isCancelButton={false}
          />
        )}
        <div className="serachDocWrp">
          {fields.map((field, index) => (
            <div className="addIndexFieldPanal mb-3" key={index}>
              <div className="div1">
                <Form.Group as={Col} controlId={`indexField-${index}`}>
                  {index === 0 && <Form.Label>Index Field</Form.Label>}
                  <Form.Select
                    as="select"
                    name="indexField"
                    value={field.indexField}
                    onChange={(e) => handleInputChange(index, e)}
                  >
                    <option value="Select">Select Index Field</option>
                    {indexFields.map((field, index) => (
                      <option key={index} value={field.columnName}>
                        {field.columnName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="div2">
                <Form.Group as={Col} controlId={`operator-${index}`}>
                  {index === 0 && <Form.Label>Operator</Form.Label>}
                  <Form.Select
                    as="select"
                    name="operator"
                    value={field.operator}
                    onChange={(e) => handleInputChange(index, e)}
                  >
                    <option value="Select">Select Operator</option>
                    <option value="Starts With">Starts With</option>
                    <option value="Equals">Equals</option>
                    <option value="Contains">Contains</option>
                    <option value="Greater than or equal to">
                      Greater than or equal to
                    </option>
                    <option value="Less than or equal to">
                      Less than or equal to
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="div3">
                <Form.Group as={Col} controlId={`searchValue-${index}`}>
                  {index === 0 && <Form.Label>Search Value</Form.Label>}
                  <Form.Control
                    type="text"
                    name="searchValue"
                    value={field.searchValue}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Form.Group>
              </div>

              <div className="div4">
                <Form.Group as={Col} controlId={`booleanValue-${index}`}>
                  {index === 0 && <Form.Label>Boolean Value</Form.Label>}
                  <Form.Select
                    as="select"
                    name="booleanValue"
                    value={field.booleanValue}
                    onChange={(e) => handleInputChange(index, e)}
                  >
                    <option value="And">And</option>
                    <option value="Or">Or</option>
                  </Form.Select>
                </Form.Group>
              </div>

              {index === 0 ? (
                <div className="div6">
                  <Button variant="success BuleClor" onClick={handleAddRow}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </Button>{" "}
                </div>
              ) : (
                <div className="div6 removemarginTop">
                  <Button
                    variant="BuleClor"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <i className="fa fa-minus-circle" aria-hidden="true"></i>
                  </Button>
                </div>
              )}

              <div className="clearfix"></div>
            </div>
          ))}
          <hr></hr>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <label className="input-group">Order By</label>
                  <select
                    id="orderby"
                    className="form-select"
                    value={orderBy}
                    onChange={handleOrderByChange}
                  >
                    <option value="Select">Select</option>
                    {indexFields.map((field, index) => (
                      <option key={index} value={field.columnName}>
                        {field.columnName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="input-group">Order Direction</label>
                  <select
                    id="orderdirection"
                    className="form-select"
                    value={orderDirection}
                    onChange={handleOrderDirectionChange}
                  >
                    <option value="false">Ascending</option>
                    <option value="true">Descending</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <Button type="submit" className="btn yellow_serachBtn">
                    <i className="fa fa-search" aria-hidden="true"></i>Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SearchDocument;
