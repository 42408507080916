import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CompanyListDropDown from "../components/CompanyListDropDown";
import { useGetDesignIndexFieldList } from "../application/Hooks/useGetDesignIndexFieldList";
import { UploadInfo } from "../domain/entities/UploadInfo";
import { useUploadFile } from "../application/Hooks/useUploadFile";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CustomAlertV2 from "../components/CustomAlertV2";
import useGetCompanyStatus from "../application/Hooks/useGetCompanyStatus";
import { useOidcUser } from "@axa-fr/react-oidc";

const Upload = () => {
  const { oidcUser } = useOidcUser();

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { companyId } = location.state || {}; // Get companyId from location state
  const [selectedCompanyIdValue, setSelectedCompanyIdValue] = useState(0);

  const [uploadData, setUploadData] = useState({
    totalpage: 0,
    documenttype: "",
    filedata: null,
  });
  const [indexFields, setIndexFields] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);

  const { designIndexFieldList, loading, error, refetch } =
    useGetDesignIndexFieldList(selectedCompanyIdValue); // assuming refetch is available from your hook

  const { uplaodResponse, uploadFile, err } = useUploadFile();
  const navigate = useNavigate(); // Initialize useNavigate

  const [isDisabled, setisDisabled] = useState(false);
  const { APICompanyStatus, loading1, error1 } = useGetCompanyStatus(
    selectedCompanyIdValue
  );

  // Create a local state for APICompanyStatus to allow manual updates
  const [APICompanyStatusResponse, setAPICompanyStatusResponse] =
    useState(APICompanyStatus);

  // Update the local state when the API call response changes
  useEffect(() => {
    if (APICompanyStatus) {
      setAPICompanyStatusResponse(APICompanyStatus); // Sync with the API response
      if (APICompanyStatus === "Deactivated") setisDisabled(true);
      else setisDisabled(false);
    }
  }, [APICompanyStatus]);

  console.log("fields " + JSON.stringify(designIndexFieldList));
  useEffect(() => {
    const fetchData = () => {
      if (
        designIndexFieldList &&
        designIndexFieldList.indexFields &&
        designIndexFieldList.indexFields.length > 0
      ) {
        setSelectedCompanyIdValue(designIndexFieldList.companyid);
        const formattedFields = designIndexFieldList.indexFields.map(
          (field) => ({
            columnName: field.columnName,
            columnDataType: field.columnDataType,
            columnLength: field.columnLength,
            defaultValue: field.defaultValue,
            isRequired: field.isRequired,
          })
        );
        setIndexFields(formattedFields);
      }
    };
    // Call fetchData inside useEffect
    fetchData();
  }, [designIndexFieldList]);

  const [inputs, setInputs] = useState({});

  // Handle dynamic input change
  const handleInputChange = (event, index) => {
    const { value } = event.target;
    setInputs({ ...inputs, [index]: value });
  };
  // Handle Upload file change

  const handleUploadFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Only JPEG, JPG, and PNG files are allowed.");

        setUploadData({ ...uploadData, filedata: null }); // Clear file data
      } else {
        setUploadData({ ...uploadData, filedata: file }); // Save file in state
      }
    }
  };

  const handleDropdownChange = (value) => {
    console.log("dropdown change value " + value);
    setSelectedCompanyIdValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("companyid: ", selectedCompanyIdValue);
    console.log("fielddetails: ", inputs);
    console.log("totalpage: ", uploadData.totalpage);
    console.log("documenttype: ", uploadData.documenttype);
    console.log("filedata : ", uploadData.filedata);
    setIsLoading(true);
    try {
      const uploadrequest = new UploadInfo(
        selectedCompanyIdValue,
        JSON.stringify(inputs),
        uploadData.totalpage ?? 0,
        uploadData.documenttype ?? "",
        uploadData.filedata
      );
      console.log("Upload File Request : " + JSON.stringify(uploadrequest));
      await uploadFile(uploadrequest);
    } catch (error) {
      setIsLoading(false);
      console.error("Error saving data:", error);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(false);
    window.location.reload();
  };

  useEffect(() => {
    debugger;
    if (uplaodResponse === 200) {
      setSuccessMessage("File submitted successfully!");
    }
  }, [uplaodResponse]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page_wrp">
            <div className="page_title">Upload File</div>
            <form onSubmit={handleSubmit}>
              <div className="contant_box_wrp">
                <div className="manageCompanyWrp ">
                  <div className="row">
                    <div className="col-md-12">
                      {oidcUser.role === "SuperAdmin" && (
                        <div className="Select_Company">
                          <CompanyListDropDown
                            value={companyId}
                            onChange={handleDropdownChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <hr></hr>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      {selectedCompanyIdValue > 0 &&
                        indexFields.map((field, index) => (
                          <div key={index} className="col-md-4 mb-3">
                            <label htmlFor={field.columnName}>
                              {field.columnName}
                              {field.isRequired && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <input
                              type={
                                field.columnDataType === "Number"
                                  ? "number"
                                  : field.columnDataType === "Date"
                                  ? "date"
                                  : "text"
                              }
                              className="form-control"
                              id={field.columnName}
                              name={field.columnName}
                              value={
                                inputs[field.columnName] || field.defaultValue
                              }
                              maxLength={field.columnLength}
                              onChange={(e) =>
                                handleInputChange(e, field.columnName)
                              }
                              required={field.isRequired}
                              disabled={isDisabled}
                            />
                          </div>
                        ))}
                    </div>
                    {selectedCompanyIdValue > 0 && (
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Total Page</label>
                            <input
                              type="number"
                              className="form-control"
                              value={uploadData.totalpage}
                              onChange={(e) =>
                                setUploadData({
                                  ...uploadData,
                                  totalpage: e.target.value,
                                })
                              }
                              //min={1}
                              required
                              disabled={isDisabled}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Document Type</label>
                            <input
                              type="text"
                              className="form-control"
                              minLength={2}
                              maxLength={100}
                              value={uploadData.documenttype}
                              onChange={(e) =>
                                setUploadData({
                                  ...uploadData,
                                  documenttype: e.target.value,
                                })
                              }
                              disabled={isDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedCompanyIdValue > 0 && (
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group mt-3">
                            <label>Select file</label>
                            <input
                              type="file"
                              className="form-control"
                              id="uploadLogo"
                              onChange={handleUploadFile}
                              accept=".jpg,.png,.jpeg,.pdf"
                              required
                              disabled={isDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!isDisabled && selectedCompanyIdValue > 0 && (
                <button
                  type="submit"
                  className="btn nextBtn"
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              )}
            </form>
          </div>
        </div>
        {successMessage && (
          <CustomAlertV2
            message={successMessage}
            onClose={() => setSuccessMessage(null)}
            onConfirm={handleConfirm}
            isCancelButton={false}
          />
        )}
      </div>
    </div>
  );
};
export default Upload;
