import React, { useEffect, useState } from "react";
import AddIndexField from "./AddIndexField";
import { Button, Table, Pagination, Form } from "react-bootstrap";
import { useIndexFieldList } from "../application/Hooks/useIndexFieldList";
import CompanyInfoNotFound from "./CompanyInfoNotFound";
import EditIndexField from "./EditIndexField";
import CustomAlertV2 from "./CustomAlertV2";
import useDeleteIndexFields from "../application/Hooks/useDeleteIndexFields";

const IndexFieldList = ({ value, IsDisable: IsDisablevalue }) => {
  const [IsShowCompanyIfo, setIsShowCompanyIfo] = useState(false);
  const [indexFields, setIndexFields] = useState([]);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isEditFieldField, setIsEditFieldField] = useState(false);
  const { indexFieldList, loading, error, refetch } = useIndexFieldList(value);
  const [editfield, setEditfield] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]); // To store selected fields
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [fieldsPerPage, setFieldsPerPage] = useState(10); // Default number of fields per page
  const [fieldsPerPageInput, setFieldsPerPageInput] = useState("10"); // Textbox value
  const [DeleteMessage, setDeleteMessage] = useState(null);
  const { status, DeleteIndexfield, error1 } = useDeleteIndexFields();
  const [refetching, setrefetching] = useState(1);

  useEffect(() => {
    const numValue = Number(value);

    if (isNaN(numValue) || numValue === 0) {
      setIsShowCompanyIfo(false);
    } else {
      setIsShowCompanyIfo(true);

      // Fetch the data only when `indexFieldList` has valid data and structure
      const fetchData = () => {
        if (
          indexFieldList &&
          Array.isArray(indexFieldList.indexFields) &&
          indexFieldList.indexFields.length > 0
        ) {
          const formattedFields = indexFieldList.indexFields.map((field) => ({
            fieldId: field.id,
            CompanyId: field.companyId,
            fieldName: field.columnName,
            dataType: field.columnDataType,
            stringLength: field.columnLength,
            defaultValue: field.defaultValue,
            isRequired: field.isRequired,
          }));
          setIndexFields(formattedFields);
        } else {
          setIndexFields([]); // Ensure to clear the state if no data exists
        }
      };

      fetchData();
    }
  }, [value, indexFieldList]);

  // Function to refresh the list of index fields
  const refreshFieldList = () => {
    refetch();
  };

  const handleSaveField = () => {
    refreshFieldList();
    setIsAddingField(false);
  };

  const handleSaveEditField = () => {
    refreshFieldList();
    setIsEditFieldField(false);
  };

  const handleEdit = (field) => {
    setIsEditFieldField(true);
    setEditfield(field);
  };

  // Delete Functionality
  const handleDeleteSelected = () => {
    // const updatedFields = indexFields.filter(
    //   (field) => !selectedFields.includes(field.fieldId)
    // );
    console.log("deleted field " + selectedFields);
    setDeleteMessage("Are you sure you want to delete the selected records ?");
    // setIndexFields(updatedFields);
    // setSelectedFields([]);
    // Optionally, you can call a backend API to delete selected fields from the server.
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (fieldId) => {
    setSelectedFields((prevSelectedFields) =>
      prevSelectedFields.includes(fieldId)
        ? prevSelectedFields.filter((id) => id !== fieldId)
        : [...prevSelectedFields, fieldId]
    );
  };

  // Select all checkboxes
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allFieldIds = indexFields.map((field) => field.fieldId);
      setSelectedFields(allFieldIds);
    } else {
      setSelectedFields([]);
    }
  };
  const handleConfirm = () => {
    const dataToSend = selectedFields.map((id) => parseInt(id));
    DeleteIndexfield(dataToSend);
  };

  useEffect(() => {
    if (status && refetching === 1) {
      refreshFieldList();
      setSelectedFields([]);
      setrefetching(2);
    }
  }, [refetching, refreshFieldList, status]);

  // Calculate the current fields based on the current page and number of fields per page
  const indexOfLastField = currentPage * fieldsPerPage;
  const indexOfFirstField = indexOfLastField - fieldsPerPage;
  const currentFields = indexFields.slice(indexOfFirstField, indexOfLastField);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle changes to the textbox for fields per page
  // const handleFieldsPerPageChange = (e) => {
  //   const value = e.target.value;
  //   // Only accept positive numbers
  //   if (!isNaN(value) && Number(value) > 0) {
  //     setFieldsPerPage(Number(value));
  //     setFieldsPerPageInput(value); // Update the state for textbox
  //   } else {
  //     setFieldsPerPageInput(""); // If invalid, reset the input box
  //   }
  // };

  return (
    <div className="row">
      <div className="col-md-12">
        {!IsShowCompanyIfo ? (
          <CompanyInfoNotFound />
        ) : (
          <>
            {isAddingField ? (
              <AddIndexField value={value} onSaveField={handleSaveField} />
            ) : isEditFieldField ? (
              <EditIndexField
                value={editfield}
                onSaveField={handleSaveEditField}
              />
            ) : (
              <>
                <div className="deletePageAddWrp">
                  <div className="div1">
                    {/* Textbox for fields per page */}

                    <Form.Group controlId="fieldsPerPageSelect">
                      <Form.Label>Show</Form.Label>
                      <Form.Control
                        as="select"
                        disabled={IsDisablevalue}
                        value={fieldsPerPage}
                        onChange={(e) =>
                          setFieldsPerPage(Number(e.target.value))
                        }
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div className="div2">
                    <Button
                      variant="danger deleteBtn"
                      onClick={handleDeleteSelected}
                      disabled={selectedFields.length === 0 || IsDisablevalue} // Disable if no field is selected
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i> Delete
                    </Button>
                    {DeleteMessage && (
                      <CustomAlertV2
                        message={DeleteMessage}
                        onClose={() => setDeleteMessage(null)}
                        onConfirm={handleConfirm}
                      />
                    )}
                  </div>
                  <div className="div3">
                    <Button
                      disabled={IsDisablevalue}
                      className="btn addUserBtn"
                      onClick={() => setIsAddingField(true)}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                      Add Index Field
                    </Button>
                  </div>
                  <div className="clearfix"></div>
                </div>

                <div className="clearfix"></div>
                <div className="tableScroll">
                  <div className="table-responsive">
                    <Table className="table table-bordered blueTbl">
                      <thead>
                        <tr>
                          <th>
                            <input
                              disabled={IsDisablevalue}
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={
                                selectedFields.length > 0 &&
                                selectedFields.length === indexFields.length
                              }
                            />
                          </th>

                          <th>Field Name</th>
                          <th>Data Type</th>
                          <th>Field Length</th>
                          <th>Default Value</th>
                          <th>Required</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentFields.length > 0 ? (
                          currentFields.map((field, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  disabled={IsDisablevalue}
                                  checked={selectedFields.includes(
                                    field.fieldId
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(field.fieldId)
                                  }
                                />
                              </td>

                              <td>{field.fieldName}</td>
                              <td>{field.dataType}</td>
                              <td>{field.stringLength}</td>
                              <td>{field.defaultValue}</td>
                              <td>{field.isRequired ? "Yes" : "No"}</td>
                              <td>
                                <Button
                                  disabled={IsDisablevalue}
                                  variant="btn editBtn"
                                  onClick={() => handleEdit(field)}
                                >
                                  <i
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No fields found.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="bottom_Pagination_Wrp">
                  {/* Pagination Component */}
                  <Pagination>
                    {/* Previous Button */}
                    <Pagination.Prev
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    />

                    {/* Show only the current page number */}
                    <Pagination.Item active>{currentPage}</Pagination.Item>

                    {/* Next Button */}
                    <Pagination.Next
                      onClick={() => paginate(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(indexFields.length / fieldsPerPage)
                      }
                    />
                  </Pagination>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default IndexFieldList;
