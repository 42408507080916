export class UpdateIndexFieldRequest {
    constructor(Id,CompanyId, ColumnName, ColumnDataType, ColumnLength, IsRequired, DefaultValue) {
      this.Id=Id;
      this.CompanyId = CompanyId;
      this.ColumnName = ColumnName;
      this.ColumnDataType = ColumnDataType;
      this.ColumnLength = ColumnLength;
      this.IsRequired = IsRequired;
      this.DefaultValue = DefaultValue;
    }
  }