import { useState } from 'react';
import { ApiSearchRepository } from '../../infrastructure/repositories/ApiSearchRepository';
import { SearchDocument } from '../UseCase/SearchDocument';
import useApiClient from '../../infrastructure/apiClient';

export const useSearchDocument = () => {
  const[documentResponse, setdocumentResponse]=useState(null);
  const [error1, setError] = useState(null);
  const apiClient = useApiClient();

  const searchDocument = async (SearchRequest) => {

    const searchRepository = new ApiSearchRepository(apiClient);
    const searchDocumentUseCase = new SearchDocument(searchRepository);

    try {
      
      console.log('I am in useSearchDocument hook '+JSON.stringify(SearchRequest));
      const result = await searchDocumentUseCase.execute(SearchRequest);
      setdocumentResponse(result);
      console.log(' SearchDocument response11111 :'+ JSON.stringify(result));
    } catch (err) {
       console.log(' SearchDocument response err :'+ err.message);
      setError(err.message);
    }
  };

  return {documentResponse, searchDocument, error1,setError };
};
export default useSearchDocument;