import React, { useEffect, useState } from "react";
import { useGetImageProfile } from "../application/Hooks/useGetImageProfile";
import CompanyInfoNotFound from "./CompanyInfoNotFound";
import CustomAlertV2 from "./CustomAlertV2";
import useDeleteImageProfile from "../application/Hooks/useDeleteImageProfile";
import { useGetDesignIndexFieldList } from "../application/Hooks/useGetDesignIndexFieldList";
import { useCreateImageProfile } from "../application/Hooks/useCreateImageProfile";
const ImageProfile = ({ value, IsDisable: IsDisablevalue }) => {
  console.log("IsDisablevalue :" + IsDisablevalue);
  const { ImageProfile, loading, error, refetch } = useGetImageProfile(value);
  const [IsShowCompanyIfo, setIsShowCompanyIfo] = useState(false);
  const [ImageProfiledataFound, setImageProfiledataFound] = useState(false);
  const [DeleteMessage, setDeleteMessage] = useState(null);
  const [SuccessMessage, setSuccessMessage] = useState(null);
  const [ImageProfileId, setDeleteFieldId] = useState(0);
  const { status, DeleteImageProfiles, error1, setstatus } =
    useDeleteImageProfile();
  const { designIndexFieldList, loading1, error2, refetch1 } =
    useGetDesignIndexFieldList(value); // assuming refetch is available from your hook
  const [indexFields, setIndexFields] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({
    rootFolder: "",
    subFolder1: "",
    subFolder2: "",
    title: "",
    documentType: "",
  });
  const [isDropdownEnabled, setIsDropdownEnabled] = useState({
    subFolder1: false,
    subFolder2: false,
  });
  const {
    status: status1,
    CreateImageProfiles,
    error: errorcreateImageProfile,
    setError,
  } = useCreateImageProfile();
  useEffect(() => {
    const numValue = Number(value);
    if (isNaN(numValue) || numValue === 0) {
      setIsShowCompanyIfo(false);
    } else {
      setIsShowCompanyIfo(true);

      // Fetch the data only when `ImageProfile` has valid data and structure
      const fetchData = () => {
        if (ImageProfile) {
          console.log("Inside ImageProfile " + JSON.stringify(ImageProfile));
          setImageProfiledataFound(true);
        } else if (
          designIndexFieldList &&
          designIndexFieldList.indexFields &&
          designIndexFieldList.indexFields.length > 0
        ) {
          setImageProfiledataFound(false);
          const formattedFields = designIndexFieldList.indexFields.map(
            (field) => ({
              columnName: field.columnName,
              columnDataType: field.columnDataType,
            })
          );
          console.log("Search Index : " + JSON.stringify(formattedFields));
          setIndexFields(formattedFields);
        }
      };

      fetchData();
    }
  }, [value, ImageProfile, designIndexFieldList]);

  const handleDelete = (id) => {
    setDeleteFieldId(id);
    setDeleteMessage("Are you sure you want to delete the records ?");
  };
  const handleConfirm = () => {
    DeleteImageProfiles(ImageProfileId);
  };
  useEffect(() => {
    if (status) {
      refetch();
      setstatus(false);
    }
  }, [status, refetch, setstatus]);

  const handleSelectChange = (field, value) => {
    const updatedSelectedColumns = { ...selectedColumns, [field]: value };
    setSelectedColumns(updatedSelectedColumns);

    // Enable/Disable dropdowns based on selections
    const updatedDropdownStatus = {
      subFolder1:
        !!updatedSelectedColumns.rootFolder &&
        updatedSelectedColumns.rootFolder !== "Select",
      subFolder2:
        !!updatedSelectedColumns.subFolder1 &&
        updatedSelectedColumns.subFolder1 !== "Select",
    };
    setIsDropdownEnabled(updatedDropdownStatus);
  };

  const getFilteredOptions = (currentField) => {
    const selectedValues = Object.values(selectedColumns).filter(
      (value) => value && value !== currentField && value !== "Select"
    );
    return indexFields.filter(
      (field) => !selectedValues.includes(field.columnName)
    );
  };
  const handleSaveProfile = async () => {
    // Validate that Root Folder is selected
    if (
      !selectedColumns.rootFolder ||
      selectedColumns.rootFolder === "Select"
    ) {
      alert("Root Folder is required.");
      return;
    }

    // Prepare API payload
    const payload = {
      CompanyId: value,
      FolderProfile1: selectedColumns.rootFolder,
      FolderProfile2: selectedColumns.subFolder1 || "",
      FolderProfile3: selectedColumns.subFolder2 || "",
      Title: selectedColumns.title || "",
      DocumentType: selectedColumns.documentType || "",
    };
    CreateImageProfiles(payload);
  };
  useEffect(() => {
    if (status1) {
      console.log("insert imageprofile");
      setSuccessMessage("ImageProfile Added Successfully"); // Send all fields to parent
      setError(null);
    }
    if (errorcreateImageProfile !== "" && errorcreateImageProfile != null) {
      setSuccessMessage(error);
      setError(null);
    }
  });

  const handleConfirm1 = () => {
    refetch();
  };
  return (
    <div className="row">
      <div className="col-md-12">
        {!IsShowCompanyIfo ? (
          <CompanyInfoNotFound />
        ) : (
          <>
            {!ImageProfiledataFound ? (
              <div className="row">
                <div className="col-md-12 mb-2">
                  <div class="pull-left Txt_bold">Create Folder Profile</div>
                </div>
                <div className="col-md-12">
                  <div className="ImgProfileGrayBox">
                    <div className="row">
                      {/* Root Folder */}
                      <div className="col-md-2">
                        <label className="form-label">Root Folder</label>
                        <select
                          id="root-folder-select"
                          className="form-select"
                          value={selectedColumns.rootFolder}
                          onChange={(e) =>
                            handleSelectChange("rootFolder", e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          {getFilteredOptions(selectedColumns.rootFolder).map(
                            (field) => (
                              <option
                                key={field.columnName}
                                value={field.columnName}
                              >
                                {field.columnName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {/* Sub Folder 1 */}
                      <div className="col-md-2">
                        <label className="form-label">Sub Folder 1</label>
                        <select
                          id="sub-folder1-select"
                          className="form-select"
                          value={selectedColumns.subFolder1}
                          onChange={(e) =>
                            handleSelectChange("subFolder1", e.target.value)
                          }
                          disabled={!isDropdownEnabled.subFolder1}
                        >
                          <option value="Select">Select</option>
                          {getFilteredOptions(selectedColumns.subFolder1).map(
                            (field) => (
                              <option
                                key={field.columnName}
                                value={field.columnName}
                              >
                                {field.columnName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {/* Sub Folder 2 */}
                      <div className="col-md-2">
                        <label className="form-label">Sub Folder 2</label>
                        <select
                          id="sub-folder2-select"
                          className="form-select"
                          value={selectedColumns.subFolder2}
                          onChange={(e) =>
                            handleSelectChange("subFolder2", e.target.value)
                          }
                          disabled={!isDropdownEnabled.subFolder2}
                        >
                          <option value="Select">Select</option>
                          {getFilteredOptions(selectedColumns.subFolder2).map(
                            (field) => (
                              <option
                                key={field.columnName}
                                value={field.columnName}
                              >
                                {field.columnName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {/* Title */}
                      <div className="col-md-2">
                        <label className="form-label">Title</label>
                        <select
                          id="title-select"
                          className="form-select"
                          value={selectedColumns.title}
                          onChange={(e) =>
                            handleSelectChange("title", e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          {indexFields.map((field) => (
                            <option
                              key={field.columnName}
                              value={field.columnName}
                            >
                              {field.columnName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label className="form-label">Document Type</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedColumns.documentType}
                          onChange={(e) =>
                            handleSelectChange("documentType", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="submit"
                    class="saveBtn mt-3 btn"
                    onClick={handleSaveProfile}
                    disabled={IsDisablevalue}
                  >
                    Save Folder Profile
                  </button>
                  {SuccessMessage && (
                    <CustomAlertV2
                      message={SuccessMessage}
                      onClose={() => setSuccessMessage(null)}
                      onConfirm={handleConfirm1}
                      isCancelButton={false}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                <div class="col-md-12">
                  <div class="tableScroll">
                    <table class="table table-bordered blueTbl">
                      <thead>
                        <tr>
                          <th>Root Folder</th>
                          <th>Sub Folder 1</th>
                          <th>Sub Folder 2</th>
                          <th>Title</th>
                          <th>Document Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{ImageProfile.folderProfile1}</td>
                          <td>{ImageProfile.folderProfile2}</td>
                          <td>{ImageProfile.folderProfile3}</td>
                          <td>{ImageProfile.title}</td>
                          <td>{ImageProfile.documentType}</td>

                          <td>
                            <button
                              type="button"
                              class="btn editBtn"
                              onClick={() => handleDelete(ImageProfile.id)}
                              disabled={IsDisablevalue}
                            >
                              <i class="fa fa fa-trash" aria-hidden="true"></i>
                              Delete
                            </button>
                            {DeleteMessage && (
                              <CustomAlertV2
                                message={DeleteMessage}
                                onClose={() => setDeleteMessage(null)}
                                onConfirm={handleConfirm}
                              />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div></div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ImageProfile;
