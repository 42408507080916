import { ImageProfileRepository } from "../../domain/repositories/ImageProfileRepository";
export class ApiImageProfileRepository extends ImageProfileRepository {
 constructor(apiClient)
 {
    super();
    this.apiClient=apiClient;
 }
 async getImageProfile(companyId) {
    try {

        console.log('Calling ApiImageProfileRepository - getImageProfile - CompanyId : ', + companyId );
        const response = await this.apiClient.get('/FolderProfile/GetFolderProfile?companyId='+ companyId);
        console.log('ApiImageProfileRepository - getImageProfile'+JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        console.log('ApiImageProfileRepository - getImageProfile Exception : ' + error);
    }
 }
 async CreateImageProfile(ImageProfile) {
    try 
    {
        console.log('Calling ApiImageProfileRepository - ImageProfile - ' + JSON.stringify(ImageProfile) );

    const response = await this.apiClient.post("/FolderProfile/InsertFolderProfile", ImageProfile);
    return response.data;
    }
     catch (error) {

    console.error('ApiImageProfileRepository api repo', error);
    let errorMessage = "An unknown error occurred"; // Default error message
    if (error.response && error.response.data) {
      // Modify this based on actual structure
      errorMessage = error.response.data.message || error.response.data || errorMessage;
    }
    throw new Error(errorMessage);
    }
  }
  async DeleteImageProfile(ImageProfileId) {
    try 
    {
        console.log('Calling ApiImageProfileRepository - DeleteImageProfile - ' + ImageProfileId );

        const response = await this.apiClient.delete("/FolderProfile/DeleteImageProfile?ImageProfileId="+ImageProfileId);
    return response.data;
    }
     catch (error) {
        console.log('ApiImageProfileRepository - DeleteImageProfile Exception : ' + error);
    }
  }
}