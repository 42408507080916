import { useState, useEffect } from "react";
import { ApiCompanyRepository } from "../../infrastructure/repositories/ApiCompanyRepository";
import { GetCompanyStatus } from "../UseCase/GetCompanyStatus";
import useApiClient from "../../infrastructure/apiClient";

export const useGetCompanyStatus = (companyId) => {
  const [APICompanyStatus, setCompanyStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();


  console.log("useGetCompanyStatus  Request : " + companyId);
  useEffect(() => {
    const getCompanyStatus = async () => {
      const companyRepository = new ApiCompanyRepository(apiClient);
      const useGetCompanyStatus = new GetCompanyStatus(
        companyRepository);
      try {
        const res = await useGetCompanyStatus.execute(companyId);
        setCompanyStatus(res);
        console.log("useGetCompanyStatus  Response : " + JSON.stringify(res));
      } catch (err) {
        console.log("useGetCompanyStatus  Exception : " + err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (companyId === undefined || isNaN(companyId)||companyId===0 ||companyId==="") {
      console.log("CompanyId not defined, No API Call here.");
    } else {
        getCompanyStatus();
    }
  }, [companyId]);

  return { APICompanyStatus, loading, error };
};

export default useGetCompanyStatus;
