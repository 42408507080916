import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Unauthorized from "./pages/Unauthorized";
import UnderContruction from "./pages/UnderContruction";
import UserPage from "./pages/UserPage";
import AddCompany from "./pages/AddCompany";
import ManageCompany from "./pages/ManageCompany";
import { OidcSecure } from "@axa-fr/react-oidc";
import RoleBasedSecure from "./components/RoleBasedSecure";
import Upload from "./pages/Upload";
import SearchResult from "./pages/SearchResult";
import ChangePassword from "./pages/ChangePassword";
import PendingRequest from "./pages/PendingRequest";
import SendFile from "./pages/SendFile";
import AuditLogs from "./pages/AuditLogs";

const RouterList = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <OidcSecure>
            <Home />
          </OidcSecure>
        }
      />
      <Route
        path="/addcompany"
        element={
          <OidcSecure>
             <RoleBasedSecure roles={["SuperAdmin"]}>
            <AddCompany />
            </RoleBasedSecure>
          </OidcSecure>
        }
      />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/userpage" element={<UserPage />} />   
      <Route path="/unauthorized" element={<Unauthorized />} /> 
      <Route path="/UnderContruction" element={<UnderContruction />} />
      
      <Route path="/upload" element={
          <OidcSecure> 
          <RoleBasedSecure roles={["SuperAdmin","Admin",'User']}>
        <Upload />
        </RoleBasedSecure>
          </OidcSecure>           
        } />
      
      <Route 
        path="/addUser" 
        element={
          <OidcSecure> 
          <RoleBasedSecure roles={["SuperAdmin","Admin"]}>
          <UserPage /> 
          </RoleBasedSecure>
          </OidcSecure>           
        } 
        />
      <Route 
        path="/manageCompany" 
        element={
        <OidcSecure> 
          <RoleBasedSecure roles={["SuperAdmin"]}>
          <ManageCompany />
          </RoleBasedSecure>
        </OidcSecure>        
        } 
        /> 
         <Route path="/SearchResult" element={
          <OidcSecure>     
        <SearchResult />
          </OidcSecure>           
        } />

        <Route path="/changePassword" element={
          <OidcSecure>
            <RoleBasedSecure roles={["SuperAdmin","Admin"]}>
            <ChangePassword />
            </RoleBasedSecure>
          </OidcSecure>
        } />

         <Route path="/pendingRequest" element={
          <OidcSecure>
            <RoleBasedSecure roles={["Admin"]}>
            <PendingRequest />
            </RoleBasedSecure>
          </OidcSecure>
        } />

      <Route path="/sendFile" element={
          <OidcSecure>
            <RoleBasedSecure roles={["Admin",'User']}>
            <SendFile />
            </RoleBasedSecure>
          </OidcSecure>
        } />

      <Route path="/auditLogs" element={
          <OidcSecure>
            <RoleBasedSecure roles={["SuperAdmin","Admin"]}>
            <AuditLogs />
            </RoleBasedSecure>
          </OidcSecure>
        } />
             
    </Routes>
  );
};

export default RouterList;
