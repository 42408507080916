// CustomAlert.js
import React from "react";
import "../CustomAlert.css";

const CustomAlert = ({ isOpen, onClose, title, message, onLater, onYes }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-alert-overlay" onClick={onClose}>

      <div
        className="customAlertContent"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="customAlertText">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>

        <div className="customAlertFooter">
          <button
            className="laterBTn"
            onClick={() => {
              onLater();
              onClose();
            }}
          >
            Later
          </button>
          <button
            className="yesBtn"
            onClick={() => {
              onYes();
              onClose();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
