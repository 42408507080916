export class DesignIndexFieldList {
    constructor(designIndexFieldList) {
    
      // Initialize an empty array to hold index field objects
      this.indexFields = [];
      this.companytablename=designIndexFieldList.companyTableName;
      this.companyid=designIndexFieldList.companyId;
     
     // console.log('Entities - DesignIndexFieldList ', designIndexFieldList.deignIndexField);

      // Iterate over the indexFieldList array and push each item into indexFields
      designIndexFieldList.deignIndexField.forEach(indexField => {
        this.indexFields.push({
          columnName: indexField.columnName,
          columnDataType: indexField.columnDataType,
          columnLength: indexField.columnLength,
          isRequired: indexField.isRequired,
          defaultValue: indexField.defaultValue
        });
      });

      // console.log('Entities  DesignIndexFieldList- indexFields ', this.indexFields);
    }
  }