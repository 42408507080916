import { RetentionScheduleRepository } from "../../domain/repositories/RetentionScheduleRepository"; 
import { RetentionScheduleList } from "../../domain/entities/RetentionScheduleList";
import { FolderList } from "../../domain/entities/FolderList";
export class ApiRetentionScheduleRepository extends RetentionScheduleRepository {
    constructor(apiClient)
    {
       super();
       this.apiClient=apiClient;
    }
    async addRetentionSchedule(RetentionScheduleRequest) {
        debugger;
        try 
        {
            console.log('Calling ApiRetentionScheduleRepository - addRetentionSchedule : ' + JSON.stringify(RetentionScheduleRequest) );
            const response = await this.apiClient.post("/RetentionSchedule/CreateRetentionSchedule", RetentionScheduleRequest);
            return response.status;
        }
        catch (error) 
        {
            console.error('Error creating addRetentionSchedule:', error);
    
            // Safely extract the error message from the API response
            let errorMessage = "An unknown error occurred"; // Default error message
            if (error.response && error.response.data) {
            // Modify this based on actual structure
            errorMessage = error.response.data.message || error.response.data || errorMessage;
        }
    
        // Throw the error message to be caught in the hook or service
        throw new Error(errorMessage);
        } 
    }
    async updateRetentionSchedule(UpdateRetentionScheduleRequest) {
        debugger;
        try 
        {
            console.log('Calling ApiRetentionScheduleRepository - updateRetentionSchedule : ' + JSON.stringify(UpdateRetentionScheduleRequest) );
            const response = await this.apiClient.post("/RetentionSchedule/UpdateRetentionSchedule", UpdateRetentionScheduleRequest);
            return response.status;
        }
        catch (error) 
        {
            console.error('Error creating updateRetentionSchedule:', error);
    
            // Safely extract the error message from the API response
            let errorMessage = "An unknown error occurred"; // Default error message
            if (error.response && error.response.data) {
            // Modify this based on actual structure
            errorMessage = error.response.data.message || error.response.data || errorMessage;
        }
    
        // Throw the error message to be caught in the hook or service
        throw new Error(errorMessage);
        } 
    }
    async getRetentionScheduleList(companyId) 
    {
        try {
            debugger;
            console.log('Calling ApiRetentionScheduleRepository - getRetentionScheduleList - CompanyId : ', + companyId );
            const response = await this.apiClient.get('/RetentionSchedule/GetRetentionSchedule?companyId='+ companyId);
            console.log('ApiRetentionScheduleRepository - getRetentionScheduleList'+JSON.stringify(response.data));
            return new RetentionScheduleList(response.data);
        } catch (error) {
            console.log('ApiRetentionScheduleRepository - getRetentionScheduleList Exception : ' + error);
        }

    }
    async getFolderList(companyId) 
    {
        debugger;
        try
        {
            const response = await this.apiClient.get('/RetentionSchedule/GetFolderList?CompanyId='+companyId);     
            return response.data;
        }
        catch(err)
        {
          console.log(err);
        }
    }

    async getDeletedFiles(companyId) 
    {
        debugger;
        try
        {
            
            const response = await this.apiClient.get('/RetentionSchedule/GetDeletedFiles?CompanyId='+companyId);
            console.log("deletedFiles", response);     
            return response;
        }
        catch(err)
        {
            debugger;
          console.log(err);
        }
    }
}