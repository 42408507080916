// src/application/hooks/useAddCompany.js
import { useState } from 'react';
import { ApiCompanyRepository } from '../../infrastructure/repositories/ApiCompanyRepository';
import { AddCompany } from '../UseCase/AddCompany';
import useApiClient from '../../infrastructure/apiClient';

export const useAddCompany = () => {
  const [companyId, setCompanyId] = useState(null);
  const [error, setError] = useState(null);
  const apiClient = useApiClient();

  const addCompany = async (newCompany) => {
    const companyRepository = new ApiCompanyRepository(apiClient);
    const addCompanyUseCase = new AddCompany(companyRepository);

    try {
      console.log('I am in usecompany hook');
      const result = await addCompanyUseCase.execute(newCompany);
      setCompanyId(result);
    } catch (err) {
      console.log('add company hook error '+err);
      setError(err.message);
    }
  };

  return { companyId, addCompany, error,setError  };
};
export default useAddCompany;
