import { DocumentRepository } from '../../domain/repositories/DocumentRepository';
import {UploadInfo}  from '../../domain/entities/UploadInfo';

export class ApiDocumentRepository extends DocumentRepository {
  constructor(apiClient) {
    super();
    this.apiClient = apiClient;
  }
  async uploadFile(UploadInfo) {
   
    console.log('I am in ApiDocumentRepository for UplaodFile');
    const formData = new FormData();
    formData.append("CompanyId", UploadInfo.companyid);
    formData.append("FieldDetails", UploadInfo.fielddetails);
    formData.append("DocumentType", UploadInfo.documenttype);
    formData.append("Filedata", UploadInfo.filedata);
    formData.append("TotalPages", UploadInfo.totalpage);

    console.log("api fielddetails --" + UploadInfo.fielddetails);

    for (let [key, value] of formData.entries()) {
      console.log(key, value); // You should see your file as a Blob for LogoFileData
    }

    const response = await this.apiClient.post("/Document/Upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },      
    });
    debugger
    const uplaodResponse = response.status;
    console.log('Upload Data successfully');
    return uplaodResponse;
  }
  async UpdateDocument(UpdateDocRequest) {
    try 
    {
        console.log('Calling ApiDocumentRepository - UpdateDocument - Fields : ' + JSON.stringify(UpdateDocRequest) );

    const response = await this.apiClient.post("/Document/UpdateDocument", UpdateDocRequest);
    return response.status;
    }
     catch (error) {
    console.error('Error creating update Doc:', error);

    // Safely extract the error message from the API response
    let errorMessage = "An unknown error occurred"; // Default error message
    if (error.response && error.response.data) {
      // Modify this based on actual structure
      errorMessage = error.response.data.message || error.response.data || errorMessage;
    }

    // Throw the error message to be caught in the hook or service
    throw new Error(errorMessage);
    }
  }

}