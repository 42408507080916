import { IndexFieldRepository } from "../../domain/repositories/IndexFieldRepository";
import { IndexFieldList } from '../../domain/entities/IndexFieldList';
import { Fields } from "../../domain/entities/Fields";
import { DesignIndexFieldList } from "../../domain/entities/DesignIndexFieldList";
export class ApiIndexFieldRepository extends IndexFieldRepository {
 constructor(apiClient)
 {
    super();
    this.apiClient=apiClient;
 }
 async getIndexFieldList(companyId) {
    try {

        console.log('Calling ApiIndexFieldRepository - getIndexFieldList - CompanyId : ', + companyId );
        const response = await this.apiClient.get('/IndexField/GetIndexFieldList?companyId='+ companyId);
        console.log('ApiIndexFieldRepository - getIndexFieldList'+JSON.stringify(response.data));
        return new IndexFieldList(response.data);
    } catch (error) {
        console.log('ApiIndexFieldRepository - getIndexFieldList Exception : ' + error);
    }
 }
 async CreateIndexField(Fields) {
    try 
    {
        console.log('Calling ApiIndexFieldRepository - CreateIndexField - Fields : ' + JSON.stringify(Fields) );

    const response = await this.apiClient.post("/IndexField/CreateIndexField", Fields);
    return response.data;
    }
     catch (error) {

    console.error('CreateIndexField api repo', error);
    let errorMessage = "An unknown error occurred"; // Default error message
    if (error.response && error.response.data) {
      // Modify this based on actual structure
      errorMessage = error.response.data.message || error.response.data || errorMessage;
    }

    // Throw the error message to be caught in the hook or service
    throw new Error(errorMessage);
    }
  }
  async UpdateIndexField(UpdateIndexFieldRequest) {
    try 
    {
        console.log('Calling ApiIndexFieldRepository - UpdateIndexField - Fields : ' + JSON.stringify(UpdateIndexFieldRequest) );

    const response = await this.apiClient.post("/IndexField/UpdateIndexFields", UpdateIndexFieldRequest);
    return response.data;
    }
     catch (error) {
        console.log('ApiIndexFieldRepository - UpdateIndexField Exception : ' + error);
    }
  }
  async DeleteIndexField(IndexFieldListIds) {
    try 
    {
        console.log('Calling ApiIndexFieldRepository - DeleteIndexField - Fields : ' + JSON.stringify(IndexFieldListIds) );

        const response = await this.apiClient.delete("/IndexField/DeleteIndexFields", {
            data: IndexFieldListIds, // Send the payload here
        });
    return response.data;
    }
     catch (error) {
        console.log('ApiIndexFieldRepository - DeleteIndexField Exception : ' + error);
    }
  }
  async getDesignIndexFieldList(companyId) {
    try {

        console.log('Calling ApiIndexFieldRepository - getDesignIndexFieldList - CompanyId : ', + companyId );
        const response = await this.apiClient.get('/IndexField/GetDesignIndexFieldModel?companyId='+ companyId);
        console.log('ApiIndexFieldRepository - getDesignIndexFieldList'+JSON.stringify(response.data));
        return new DesignIndexFieldList(response.data);
    } catch (error) {
        console.log('ApiIndexFieldRepository - getDesignIndexFieldList Exception : ' + error);
    }
 }

}
